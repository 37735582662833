import { HttpErrorResponse } from '@angular/common/http';
import { BaseCustomError } from './base-custom.error';
import { CustomErrorType, HttpErrorDetails } from './models';

export class HttpError extends BaseCustomError {
  public customErrorType: CustomErrorType.http;
  public timestamp: number;
  public details: HttpErrorDetails;

  constructor(
    private readonly httpError: HttpErrorResponse,
    private readonly method: string,
    userMessage: string = 'An HTTP error occurred.'
  ) {
    const timestamp = Date.now();
    super(httpError.message, timestamp);

    this.customErrorType = CustomErrorType.http;
    this.timestamp = timestamp;
    this.details = {
      status: httpError.status,
      url: httpError.url ?? 'unknown',
      method,
      userMessage,
      userAgent: navigator.userAgent,
      payload: httpError.error,
      originalError: httpError,
    };
  }
}
