import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes('https://api.checklistbank.org') &&
      !request.url.includes('/cdn/v1/upload')
    ) {
      const modifiedRequest = request.clone({
        headers: request.headers.set(
          'Accept',
          `application/vnd.article-backoffice-api.v${this.config.articleApiVersion}+json`
        ),
      });
      return next.handle(modifiedRequest);
    }
    return next.handle(request);
  }
}
