import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, shareReplay, takeUntil } from 'rxjs/operators';
import { EditMode } from './models';
import { EditModeActions, EditModeSelectors } from '@app/store/edit-mode';
import { YdocService } from '../ydoc.service';

@Injectable()
export class EditModeService implements OnDestroy {
  readonly mode$: Observable<EditMode>;
  readonly isEditMode$: Observable<boolean>;
  readonly isSuggestMode$: Observable<boolean>;
  readonly isPreviewMode$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly ydocService: YdocService
  ) {
    // Initialize observable streams with proper unsubscribing
    this.mode$ = this.store
      .select(EditModeSelectors.selectCurrentMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    this.isEditMode$ = this.store
      .select(EditModeSelectors.selectIsEditMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    this.isSuggestMode$ = this.store
      .select(EditModeSelectors.selectIsSuggestMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    this.isPreviewMode$ = this.store
      .select(EditModeSelectors.selectIsPreviewMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    // Handle YDoc initialization with proper cleanup
    if (this.ydocService.editorIsBuild) {
      this.initializeFromYDoc();
    } else {
      this.ydocService.ydocStateObservable
        .pipe(
          filter(({ event }) => event === 'docIsBuild'),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.initializeFromYDoc();
        });
    }
  }

  setMode(mode: EditMode | undefined): void {
    if (mode === undefined) {
      this.store.dispatch(EditModeActions.toggleEditMode());
      return;
    }

    this.store.dispatch(EditModeActions.setEditMode({ mode }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeFromYDoc(): void {
    const userSettings = this.ydocService?.currUser?.settings;

    this.store.dispatch(
      EditModeActions.initializeEditMode({
        userSettings,
      })
    );
  }
}
