// src/app/editor/directives/comment-button.directive.ts
import { Directive, HostListener, ElementRef } from '@angular/core';
import { CommentsService } from '../utils/commentsService/comments.service';
import { DetectFocusService } from '../utils/detectFocusPlugin/detect-focus.service';

@Directive({
  selector: '[appCommentButton]',
})
export class CommentButtonDirective {
  constructor(
    private commentsService: CommentsService,
    private detectFocusService: DetectFocusService,
    private el: ElementRef
  ) {}

  @HostListener('click')
  onClick(): void {
    const sectionName = this.el.nativeElement.getAttribute('data-section-name');

    if (!sectionName) {
      console.error('No section name found for comment button');
      return;
    }

    this.commentsService.addCommentSubject.next({
      type: 'commentData',
      sectionName,
      showBox: true,
    });
    this.detectFocusService.setSelectionDecorationOnLastSelectedEditor();
    setTimeout(() => {
      this.commentsService.buildGlobalCommentMap();
    }, 30);
  }
}
