import * as Y from 'yjs';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin, EditorState } from 'prosemirror-state';
import { Awareness } from 'y-protocols/awareness.js';
import {
  absolutePositionToRelativePosition,
  relativePositionToAbsolutePosition,
  setMeta,
} from '../lib.js';
import { yCursorPluginKey, ySyncPluginKey } from './keys.js';
import * as math from 'lib0/math.js';
import { CollaboratorAnonymizationService } from '@app/editor/services/collaborator-anonymization/collaborator-anonymization.service';
import { UserInfo } from '@app/editor/comments-section/comment.models';
import { AwarenessState, CursorData, EditorViewWithDOM, YState } from './models.js';

/**
 * Class-based implementation of the cursor plugin for ProseMirror
 * Handles cursor display and anonymization in collaborative editing
 */
export class YCursorPlugin {
  private readonly awareness: Awareness;
  private readonly anonymizer: CollaboratorAnonymizationService;
  private readonly cursorBuilder: (userInfo: UserInfo | Record<string, unknown>) => HTMLElement;
  private readonly getSelection: (state: EditorState) => { anchor: number; head: number };
  private readonly cursorStateField: string;

  /**
   * Constructor for the YCursorPlugin class
   *
   * @param awareness Awareness instance from Yjs
   * @param collaboratorAnonymizationService Service for anonymizing collaborators
   * @param options Optional configuration for cursor behavior
   */
  constructor(
    awareness: Awareness,
    collaboratorAnonymizationService: CollaboratorAnonymizationService,
    options: {
      cursorBuilder?: (userInfo: UserInfo | Record<string, unknown>) => HTMLElement;
      getSelection?: (state: EditorState) => { anchor: number; head: number };
      cursorStateField?: string;
    } = {}
  ) {
    this.awareness = awareness;
    this.anonymizer = collaboratorAnonymizationService;
    this.cursorBuilder = options.cursorBuilder || this.createCursorElement.bind(this);
    this.getSelection = options.getSelection || ((state) => state.selection);
    this.cursorStateField = options.cursorStateField || 'cursor';
  }

  /**
   * Static factory method to maintain backward compatibility
   *
   * @param awareness Awareness instance from Yjs
   * @param collaboratorAnonymizationService Service for anonymizing collaborators
   * @param options Optional configuration
   * @param cursorStateField Cursor state field name
   * @returns Plugin
   */
  public static create(
    awareness: Awareness,
    collaboratorAnonymizationService: CollaboratorAnonymizationService,
    options: {
      cursorBuilder?: (userInfo: UserInfo | Record<string, unknown>) => HTMLElement;
      getSelection?: (state: EditorState) => { anchor: number; head: number };
    } = {},
    cursorStateField = 'cursor'
  ): Plugin {
    const plugin = new YCursorPlugin(awareness, collaboratorAnonymizationService, {
      cursorBuilder: options.cursorBuilder,
      getSelection: options.getSelection,
      cursorStateField,
    });
    return plugin.getPlugin();
  }

  /**
   * Get the ProseMirror plugin instance
   *
   * @returns Plugin configured for cursor handling
   */
  public getPlugin(): Plugin {
    return new Plugin({
      key: yCursorPluginKey,
      state: {
        init: (_: unknown, state: EditorState): DecorationSet => {
          return this.createDecorations(state);
        },
        apply: (tr, prevState, _oldState, newState): DecorationSet => {
          const ystate = ySyncPluginKey.getState(newState) as YState;
          const yCursorState = tr.getMeta(yCursorPluginKey);

          if (
            (ystate && ystate.isChangeOrigin) ||
            (yCursorState && yCursorState.awarenessUpdated)
          ) {
            return this.createDecorations(newState);
          }

          return prevState.map(tr.mapping, tr.doc);
        },
      },
      props: {
        decorations: (state: EditorState): DecorationSet => {
          return yCursorPluginKey.getState(state);
        },
      },
      view: (view: EditorViewWithDOM) => {
        // Set up event listeners
        const awarenessListener = this.createAwarenessListener(view);
        const updateCursorInfo = this.createCursorUpdateHandler(view);

        this.awareness.on('change', awarenessListener);
        view.dom.addEventListener('focusin', updateCursorInfo);
        view.dom.addEventListener('focusout', updateCursorInfo);

        return {
          update: updateCursorInfo,
          destroy: (): void => {
            // Clean up event listeners
            view.dom.removeEventListener('focusin', updateCursorInfo);
            view.dom.removeEventListener('focusout', updateCursorInfo);
            this.awareness.off('change', awarenessListener);
            this.awareness.setLocalStateField(this.cursorStateField, null);
          },
        };
      },
    });
  }

  /**
   * Creates a cursor element with appropriate styling
   * Handles both UserInfo and generic Record types
   *
   * @param userInfo User information with detailed properties
   * @return HTMLElement representing the cursor
   */
  private createCursorElement(userInfo: UserInfo | Record<string, unknown>): HTMLElement {
    const cursor = document.createElement('span');
    cursor.classList.add('ProseMirror-yjs-cursor');

    // Handle both UserInfo and generic Record types
    if ('userColor' in userInfo) {
      // It's a UserInfo object
      const typedUserInfo = userInfo as UserInfo;

      cursor.setAttribute('style', `border-color: ${typedUserInfo.userColor}`);

      const userDiv = document.createElement('span');
      userDiv.classList.add('ProseMirror-yjs-cursor-inner-div');
      userDiv.setAttribute(
        'style',
        `background-color: ${typedUserInfo.userColor};color:${typedUserInfo.userContrastColor}`
      );

      // Use the name from the anonymized userInfo
      userDiv.insertBefore(document.createTextNode(typedUserInfo.name), null);
      cursor.insertBefore(userDiv, null);
    } else {
      // It's a generic Record
      cursor.setAttribute('style', `border-color: ${userInfo.color}`);

      const userDiv = document.createElement('span');
      userDiv.classList.add('ProseMirror-yjs-cursor-inner-div');
      userDiv.setAttribute('style', `background-color: ${userInfo.color}`);
      userDiv.insertBefore(document.createTextNode(userInfo.name as string), null);
      cursor.insertBefore(userDiv, null);
    }

    return cursor;
  }

  /**
   * Create decorations for cursors based on user awareness states
   * Handles anonymization and cursor positioning
   *
   * @param state Editor state
   * @returns DecorationSet containing all cursor decorations
   */
  private createDecorations(state: EditorState): DecorationSet {
    const ystate = ySyncPluginKey.getState(state) as YState;
    const y = ystate.doc;
    const decorations: Decoration[] = [];

    // Do not render cursors while snapshot is active
    if (ystate.snapshot != null || ystate.prevSnapshot != null || ystate.binding === null) {
      return DecorationSet.create(state.doc, []);
    }

    const states = this.awareness.getStates() as Map<number, AwarenessState>;

    states.forEach((aw, clientId) => {
      // Skip own cursor
      if (clientId === y.clientID) {
        return;
      }

      if (!aw.cursor || !aw.userInfo) {
        return;
      }

      // Process cursor based on anonymization status
      this.processCursorForUser(aw, clientId, y, ystate, state, decorations);
    });

    return DecorationSet.create(state.doc, decorations);
  }

  /**
   * Process cursor for a specific user
   * Handles anonymization and creates appropriate decorations
   *
   * @param awarenessState User's awareness state
   * @param clientId User's client ID
   * @param y Yjs document
   * @param ystate Yjs state
   * @param state Editor state
   * @param decorations Array to add decorations to
   */
  private processCursorForUser(
    awarenessState: AwarenessState,
    clientId: number,
    y: Y.Doc,
    ystate: YState,
    state: EditorState,
    decorations: Decoration[]
  ): void {
    // Check if we should hide this user's cursor completely
    if (this.anonymizer.shouldHideChangePropositions(awarenessState.userInfo!.id)) {
      // Skip creating any decorations for this user
      return;
    }

    // Get cursor positions
    const anchor = relativePositionToAbsolutePosition(
      y,
      ystate.type,
      Y.createRelativePositionFromJSON(awarenessState.cursor!.anchor),
      ystate.binding!.mapping
    );

    const head = relativePositionToAbsolutePosition(
      y,
      ystate.type,
      Y.createRelativePositionFromJSON(awarenessState.cursor!.head),
      ystate.binding!.mapping
    );

    if (anchor === null || head === null) {
      return;
    }

    // Ensure positions are within document bounds
    const maxsize = math.max(state.doc.content.size - 2, 2);
    const boundedAnchor = math.min(anchor, maxsize);
    const boundedHead = math.min(head, maxsize);

    // Get user info (anonymized if needed)
    const userInfo = this.anonymizer.shouldAnonymizeUser(awarenessState.userInfo!.id)
      ? this.anonymizer.getAnonymizedUserInfo(awarenessState.userInfo!)
      : awarenessState.userInfo!;

    // Create cursor widget
    decorations.push(
      Decoration.widget(boundedHead, () => this.cursorBuilder(userInfo), {
        key: clientId + '',
        side: 10,
      })
    );

    // Create selection highlight
    const from = math.min(boundedAnchor, boundedHead);
    const to = math.max(boundedAnchor, boundedHead);

    decorations.push(
      Decoration.inline(
        from,
        to,
        { style: `background-color: ${userInfo.userColor}70` },
        { inclusiveEnd: true, inclusiveStart: false }
      )
    );
  }

  /**
   * Create an awareness change listener
   *
   * @param view Editor view
   * @returns Function that handles awareness changes
   */
  private createAwarenessListener(view: EditorViewWithDOM): () => void {
    return (): void => {
      // Check if view has a document view before updating
      if (view.dom && view.state) {
        setMeta(view, yCursorPluginKey, { awarenessUpdated: true });
      }
    };
  }

  /**
   * Create a cursor update handler
   *
   * @param view Editor view
   * @returns Function that updates cursor information
   */
  private createCursorUpdateHandler(view: EditorViewWithDOM): () => void {
    return (): void => {
      const ystate = ySyncPluginKey.getState(view.state) as YState;
      const current = this.awareness.getLocalState() || {};

      if (view.hasFocus() && ystate.binding !== null) {
        this.updateCursorPosition(view, ystate, current);
      } else if (this.shouldClearCursor(ystate, current)) {
        // Delete cursor information if current cursor information is owned by this editor binding
        this.awareness.setLocalStateField(this.cursorStateField, null);
      }
    };
  }

  /**
   * Update the cursor position in the awareness state
   *
   * @param view Editor view
   * @param ystate Yjs state
   * @param current Current awareness state
   */
  private updateCursorPosition(
    view: EditorViewWithDOM,
    ystate: YState,
    current: Record<string, unknown>
  ): void {
    const selection = this.getSelection(view.state);

    const anchor = absolutePositionToRelativePosition(
      selection.anchor,
      ystate.type,
      ystate.binding!.mapping
    );

    const head = absolutePositionToRelativePosition(
      selection.head,
      ystate.type,
      ystate.binding!.mapping
    );

    const cursorData = current.cursor as CursorData | undefined;

    const cursorChanged =
      !cursorData ||
      !Y.compareRelativePositions(Y.createRelativePositionFromJSON(cursorData.anchor), anchor) ||
      !Y.compareRelativePositions(Y.createRelativePositionFromJSON(cursorData.head), head);

    if (cursorChanged) {
      this.awareness.setLocalStateField(this.cursorStateField, {
        anchor,
        head,
      });
    }
  }

  /**
   * Determine if the cursor should be cleared
   *
   * @param yState Yjs state
   * @param current Current awareness state
   * @returns True if cursor should be cleared
   */
  private shouldClearCursor(yState: YState, current: Record<string, unknown>): boolean {
    const cursorData = current.cursor as CursorData | undefined;

    return (
      yState.binding !== null &&
      cursorData != null &&
      relativePositionToAbsolutePosition(
        yState.doc,
        yState.type,
        Y.createRelativePositionFromJSON(cursorData.anchor),
        yState.binding.mapping
      ) !== null
    );
  }
}

/**
 * Original function for backward compatibility
 */
export const yCursorPlugin = YCursorPlugin.create;
