export interface LogRocketTraits {
  [key: string]: string | number | boolean | null;
}

export type LogRocketEventData = Record<string, unknown>;

export interface LogRocketConfig {
  projectId: string;
  options: {
    dom: { isEnabled: boolean };
    network: { isEnabled: boolean };
  };
}

export const LOGROCKET_CONFIG: LogRocketConfig = {
  projectId: 'i79cbj/article-editor',
  options: {
    dom: { isEnabled: true },
    network: { isEnabled: true },
  },
};
