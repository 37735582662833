import { HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FakeRequestHandler } from '../models';

export class ArticleItemsHandler implements FakeRequestHandler {
  private attemptCount = new Map<string, number>();
  private readonly maxRetries = 3;
  private readonly retryDelay = 1000;

  canHandle(req: HttpRequest<any>, token: string): boolean {
    const matches = req.url.match(/\/articles\/items\/uuid\/([^\/]+)/);
    const validIds = ['error500', 'error404', 'timeout', 'retry'];
    const isValidId = matches && validIds.includes(matches[1]);

    return matches && req.method === 'GET' && isValidId;
  }

  handle(req: HttpRequest<any>): Observable<HttpResponse<any>> {
    const matches = req.url.match(/\/articles\/items\/uuid\/([^\/]+)/);
    return this.handleGetArticleById(matches[1], req);
  }

  private handleGetArticleById(id: string, req: HttpRequest<any>): Observable<HttpResponse<any>> {
    const requestId = `${req.url}-${req.method}`;
    const attempts = this.attemptCount.get(requestId) || 0;
    this.attemptCount.set(requestId, attempts + 1);

    // Simulate different error scenarios based on article ID
    switch (id) {
      case 'error500':
        return throwError(
          () =>
            new HttpErrorResponse({
              error: { message: 'Internal Server Error' },
              status: 500,
              statusText: 'Internal Server Error',
              url: req.url,
            })
        ).pipe(delay(this.retryDelay));

      case 'error404':
        return throwError(
          () =>
            new HttpErrorResponse({
              error: { message: 'Article not found' },
              status: 404,
              statusText: 'Not Found',
              url: req.url,
            })
        ).pipe(delay(this.retryDelay));

      case 'timeout':
        if (attempts < this.maxRetries) {
          return throwError(
            () =>
              new HttpErrorResponse({
                error: { message: 'Network timeout' },
                status: 504,
                statusText: 'Gateway Timeout',
                url: req.url,
              })
          ).pipe(delay(this.retryDelay));
        }
        break;

      case 'retry':
        if (attempts < this.maxRetries - 1) {
          return throwError(
            () =>
              new HttpErrorResponse({
                error: { message: 'Temporary server error' },
                status: 500,
                statusText: 'Internal Server Error',
                url: req.url,
              })
          ).pipe(delay(this.retryDelay));
        }
        break;
    }

    // Default success response
    const mockArticle = {
      id,
      title: `Article ${id}`,
      abstract: 'This is a mock article abstract',
      content: 'This is the mock article content',
      status: 'published',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      metadata: {
        authors: ['John Doe', 'Jane Smith'],
        keywords: ['mock', 'test', 'article'],
      },
    };

    this.attemptCount.delete(requestId);
    return of(new HttpResponse({ status: 200, body: mockArticle }));
  }
}
