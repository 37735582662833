import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ServiceShare } from '../services/service-share.service';
import { Subscription } from 'rxjs';
import { CONSTANTS } from '@app/core/services/constants';
import { CollaboratorAnonymizationService } from '../services/collaborator-anonymization/collaborator-anonymization.service';
import { Version } from '@app/core/models/version.models';
import { UserInfo } from '../comments-section/comment.models';

@Component({
  selector: 'app-versions-section',
  templateUrl: './versions-section.component.html',
  styleUrls: ['./versions-section.component.scss'],
})
export class VersionsSectionComponent implements AfterViewInit, OnDestroy {
  versions = [];

  searchControl = new UntypedFormControl('');

  observeFunc: () => void;

  subscribtion = new Subscription();

  @ViewChild('versionsInput', { read: ElementRef }) versionsInput?: ElementRef;

  constructor(
    private sharedService: ServiceShare,
    private changeDetector: ChangeDetectorRef,
    private anonymizer: CollaboratorAnonymizationService
  ) {
    this.observeFunc = () => {
      if (!this.searchControl.value.trim()) {
        this.getVersions();
        this.changeDetector.detectChanges();
      }
    };

    this.sharedService.YdocService.articleVersions.observe(this.observeFunc);
    this.getVersions();
  }

  ngAfterViewInit(): void {
    this.subscribtion.add(
      this.searchControl.valueChanges.subscribe((value: string) => {
        const num = Number(value);

        if (value && value.trim() && num) {
          this.getVersions(num);
        } else {
          this.getVersions();
        }
        this.changeDetector.detectChanges();
      })
    );
    this.subscribtion.add(
      this.sharedService.YdocService.allowedVersionsStateSubject.subscribe(() => {
        this.getVersions();
        this.changeDetector.detectChanges();
      })
    );
    this.changeDetector.detectChanges();
  }

  getVersions(index?: number): void {
    const versions: Version[] = [];
    const articleVersions = this.sharedService.YdocService.articleVersions.toArray();
    const hasLatest = this.sharedService.YdocService.currUser.allowed_article_versions.includes(
      CONSTANTS.LATEST
    );
    const hasOwn = this.sharedService.YdocService.currUser.allowed_article_versions.includes(
      CONSTANTS.OWN
    );

    const allowedVersions = this.sharedService.YdocService.currUser.allowed_article_versions
      .filter((v: string) => v != CONSTANTS.LATEST && v != CONSTANTS.OWN)
      .map((n: string) => +n);

    const minVersion = allowedVersions.length ? Math.min(...allowedVersions) : undefined;

    const userId =
      this.sharedService.YdocService.currUser.user_id || this.sharedService.YdocService.currUser.id;

    articleVersions.forEach((version, index: number) => {
      if (hasLatest && !allowedVersions.length && !hasOwn) return;
      if (allowedVersions.length && !allowedVersions.includes(index) && !hasOwn) return;
      if (hasOwn && minVersion && minVersion >= index && !allowedVersions.includes(index)) {
        return;
      } else if (
        hasOwn &&
        !version.users.find((u) => u.id == userId) &&
        !allowedVersions.includes(index)
      ) {
        return;
      }

      if (version.restored) {
        const restoredDate = new Date(version.restored).toISOString();
        versions.unshift({
          date: new Date(version.date),
          snapshot: version.snapshot,
          index,
          users: version.users,
          restored: new Date(restoredDate),
        });
      } else {
        versions.unshift({
          date: new Date(version.date),
          snapshot: version.snapshot,
          index,
          users: version.users,
        });
      }
    });

    if (hasLatest || (!allowedVersions.length && !hasOwn)) {
      const name = this.sharedService.ProsemirrorEditorsService.userInfo.data.name;
      const id = this.sharedService.ProsemirrorEditorsService.userInfo.data.id;
      const userColor = this.sharedService.ProsemirrorEditorsService.userInfo.color.userColor;
      const email = this.sharedService.ProsemirrorEditorsService.userInfo.data.email;
      versions.unshift({
        date: new Date(),
        snapshot: undefined,
        index: versions.length || 1,
        users: [
          {
            name,
            id,
            userColor,
            email,
          },
        ],
        currentVersion: true,
      });
    }

    this.sharedService.onlyOldVersions = !!allowedVersions.length && !hasLatest;

    if (!isNaN(index)) {
      this.versions = versions[index] ? [versions.reverse()[index]] : [];
    } else {
      this.versions = versions;
    }
  }

  getAnonymizedUserName(user: UserInfo): string {
    return this.anonymizer.getDisplayName(user.id, user.name);
  }

  ngOnDestroy(): void {
    this.sharedService.YdocService.articleVersions.unobserve(this.observeFunc);
    this.subscribtion.unsubscribe();
  }
}
