import { Injectable, Inject, Optional } from '@angular/core';
import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import {
  RetryConfig,
  DEFAULT_GLOBAL_RETRY_CONFIG,
  GlobalRetryConfig,
  RetryEndpointConfig,
  RetryStatusConfig,
} from './retry.service.config';

export const RETRY_CONFIG = 'RETRY_CONFIG';

@Injectable({ providedIn: 'root' })
export class RetryService {
  private readonly globalConfig: GlobalRetryConfig;
  private endpointConfigs = new Map<string, RetryEndpointConfig>();

  constructor(@Optional() @Inject(RETRY_CONFIG) config?: GlobalRetryConfig) {
    this.globalConfig = config || DEFAULT_GLOBAL_RETRY_CONFIG;
    // Initialize endpoint configurations from global config
    if (this.globalConfig.endpointConfigs) {
      this.globalConfig.endpointConfigs.forEach((endpointConfig) => {
        if (typeof endpointConfig.pattern === 'string') {
          this.endpointConfigs.set(endpointConfig.pattern, endpointConfig);
        } else {
          // For RegExp patterns, we'll use a string representation as the key
          this.endpointConfigs.set(endpointConfig.pattern.toString(), endpointConfig);
        }
      });
    }
  }

  public setEndpointConfig(url: string, config: RetryEndpointConfig): void {
    this.validateEndpointConfig(config);
    this.endpointConfigs.set(url, config);
  }

  public shouldRetry(request: HttpRequest<unknown>, error?: HttpErrorResponse): boolean {
    const config = this.getEffectiveConfig(request, error);

    // Check if method is allowed in the specific config
    if (!config.idempotentMethods.includes(request.method)) {
      return false;
    }

    // For status-specific configs, check if status is included
    if (error && 'statusCodes' in config && Array.isArray(config.statusCodes)) {
      return config.statusCodes.includes(error.status);
    }

    return true;
  }

  public retryWithConfig(
    request: HttpRequest<unknown>
  ): <T>(source: Observable<T>) => Observable<T> {
    return <T>(source: Observable<T>): Observable<T> => {
      let retryAttempt = 0;

      return source.pipe(
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error: HttpErrorResponse | (() => HttpErrorResponse)) => {
              if (typeof error === 'function') {
                error = error();
              }
              retryAttempt++;
              const config = this.getEffectiveConfig(request, error);

              if (retryAttempt > config.maxRetries) {
                return throwError(() => error);
              }

              const delay = config.useExponentialBackoff
                ? config.delayMs * Math.pow(2, retryAttempt - 1)
                : config.delayMs;

              return timer(delay);
            })
          )
        )
      );
    };
  }

  private getEffectiveConfig(
    request: HttpRequest<unknown>,
    error?: HttpErrorResponse
  ): RetryConfig {
    // Try endpoint-specific config first
    const endpointConfig = this.findMatchingEndpointConfig(request.url);
    if (this.isRetryEndpointConfig(endpointConfig)) {
      // Check for status-specific override in endpoint config
      if (error && endpointConfig.statusOverrides) {
        const statusOverride = this.findStatusConfig(endpointConfig.statusOverrides, error.status);
        if (statusOverride) {
          return statusOverride;
        }
      }
      return endpointConfig;
    }

    // Try global status-specific config
    if (error && this.globalConfig.statusConfigs) {
      const statusConfig = this.findStatusConfig(this.globalConfig.statusConfigs, error.status);
      if (statusConfig) {
        return statusConfig;
      }
    }

    // Fall back to default config
    return this.globalConfig.default;
  }

  private findMatchingEndpointConfig(url: string): RetryConfig | undefined {
    // First try exact match
    const exactConfig = this.endpointConfigs.get(url);
    if (exactConfig) {
      return exactConfig;
    }

    // Then try pattern matching
    const patternConfig = Array.from(this.endpointConfigs.values()).find((config) => {
      if (config.pattern instanceof RegExp) {
        return config.pattern.test(url);
      }
      return new RegExp(config.pattern).test(url);
    });

    return patternConfig;
  }

  private findStatusConfig(configs: RetryStatusConfig[], status: number): RetryConfig | undefined {
    return configs.find((config) => config.statusCodes.includes(status));
  }

  private validateEndpointConfig(config: RetryEndpointConfig): void {
    if (!config.pattern) {
      throw new Error('Endpoint config must include a pattern');
    }

    if (config.statusOverrides) {
      config.statusOverrides.forEach((statusConfig) => {
        if (!statusConfig.statusCodes?.length) {
          throw new Error('Status config must include status codes');
        }
      });
    }
  }

  private isRetryEndpointConfig(config: RetryConfig | undefined): config is RetryEndpointConfig {
    return config !== undefined && 'pattern' in config;
  }
}
