import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { JatsCitableElementsService } from '../../jats-citable-elements.service';
import { schema } from '@app/editor/utils/Schema';

@Component({
  selector: 'app-jats-supplementary-files-prosemirror-view',
  templateUrl: './jats-supplementary-files-prosemirror-view.component.html',
  styleUrls: ['./jats-supplementary-files-prosemirror-view.component.scss'],
})
export class JatsSupplementaryFilesProsemirrorViewComponent implements AfterViewInit {
  @ViewChild('ProsemirrorEditor', { read: ElementRef }) ProsemirrorEditor?: ElementRef;

  constructor(
    private prosemirrEditorsService: ProsemirrorEditorsService,
    private jatsCitableElementsService: JatsCitableElementsService
  ) {}

  ngAfterViewInit(): void {
    this.renderEndEditor();
    setTimeout(() => {
      this.jatsCitableElementsService.addSupplementaryFiles();
    }, 1000);
  }

  renderEndEditor(): void {
    this.jatsCitableElementsService.editors['supplementaryFilesView'] =
      this.prosemirrEditorsService.renderPreviewEditor(
        this.ProsemirrorEditor?.nativeElement,
        schema.nodes.doc.create()
      );
  }
}
