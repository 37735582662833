import { ActionReducerMap } from '@ngrx/store';
import { editModeReducer } from './edit-mode/edit-mode.reducer';
import { commentsReducer } from './comments/comments.reducer';
import { EditModeState } from './edit-mode/models';
import { CommentsState } from './comments/models';

export interface AppState {
  editMode: EditModeState;
  comments: CommentsState;
}

export const reducers: ActionReducerMap<AppState> = {
  editMode: editModeReducer,
  comments: commentsReducer,
};
