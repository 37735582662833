import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makeTextBold',
})
export class MakeTextBold implements PipeTransform {
  transform(value: string, regex: string, isRef?: boolean): string {
    const valueLowerCase = isRef ? value : value.toLowerCase();
    const re = new RegExp(`(${regex.toLowerCase()})`, 'gm');
    return valueLowerCase.replace(re, '<b>$1</b>');
  }
}
