import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CONSTANTS } from '../services/constants';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialogComponent } from '@app/editor/dialogs/request-error-dialog/request-error-dialog.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private tokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken();

    if (token && !request.url.includes('/taxons')) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | (() => HttpErrorResponse)) => {
        if (typeof error === 'function') {
          error = error();
        }
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            return this.handle401(request, next);
          }
          if (error.status === 403) {
            if (request.url.endsWith('/me')) {
              this.authService.logout();
            } else {
              this.dialog.closeAll();
              this.dialog.open(RequestErrorDialogComponent, {
                width: '563px',
                data: { message: 'Access to this resource is denied!' },
              });
            }
          }
        }
        return throwError(() => error);
      })
    );
  }

  private addToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    if (request.url.includes('https://api.checklistbank.org')) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(CONSTANTS.AUTH_HEADER, `Bearer ${token}`),
    });
  }

  private handle401(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap(({ token, refreshToken }) => {
          this.isRefreshing = false;
          if (token) {
            this.authService.storeToken(token);
            this.authService.storeToken(refreshToken, 'refreshToken');
            this.tokenSubject.next(token);
            return next.handle(this.addToken(request, token));
          }
          this.authService.logoutAndReload();
          return throwError(() => 'no refresh token found');
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logoutAndReload();
          return throwError(() => error);
        })
      );
    }

    if (this.isRefreshing && request.url.includes('refresh')) {
      this.authService.logoutAndReload();
      return throwError(() => 'No refresh token! Going to login page!');
    }

    return this.tokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addToken(request, token as string)))
    );
  }
}
