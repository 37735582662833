import { Injectable } from '@angular/core';
import { ErrorFactoryService } from '../error-factory/error-factory.service';
import { GlobalErrorHandler } from '../global-error-handler/global-error-handler';
import { CustomError, CustomErrorType } from '../custom-errors/models';
import { NetworkError } from '../custom-errors/network-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorStorageService {
  private static readonly defaultStorageKey = 'awt_stored_errors';
  private initialized = false;

  constructor(
    private errorFactory: ErrorFactoryService,
    private errorHandler: GlobalErrorHandler
  ) {}

  public static getStoredErrors(): CustomError[] {
    try {
      return JSON.parse(localStorage.getItem(this.defaultStorageKey) || '[]');
    } catch {
      return [];
    }
  }

  public static storeError(error: CustomError): void {
    const storedErrors = this.getStoredErrors();
    storedErrors.push(error);
    localStorage.setItem(this.defaultStorageKey, JSON.stringify(storedErrors));
  }

  public init(): void {
    if (this.initialized) {
      return;
    }

    this.processStoredErrors();
    this.initialized = true;
  }

  public processStoredErrors(): void {
    const storedErrors = ErrorStorageService.getStoredErrors();
    if (!storedErrors.length) return;

    storedErrors.forEach((storedError) => {
      this.errorHandler.handleStoredError(this.errorFactory.createError(storedError));
    });

    localStorage.removeItem(ErrorStorageService.defaultStorageKey);
  }
}
