import { ElementRef, Injectable, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { uuidv4 } from 'lib0/random';

import { CommentsService } from '@app/editor/utils/commentsService/comments.service';
import { CommentsStateService } from '../shared-comments/comments-state.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { Comment } from '../../comment.models';
import { isCommentAllowed } from '@app/editor/utils/menu/menuItems';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { Collaborator } from '@app/core/models/article.models';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { Subject } from 'rxjs';
import { EditorView } from 'prosemirror-view';
import { Store } from '@ngrx/store';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { CommentsSelectors } from '@app/store/comments';

@Injectable()
export class CommentsRenderingService implements OnDestroy {
  shouldScrollSelected = false;

  rendered = 0;
  nOfCommThatShouldBeRendered = 0;

  notRendered = true;

  addCommentBoxTop: number;
  addCommentBoxH: number;

  addCommentBoxIsAlreadyMoved: boolean;

  lastFocusedEditor: string;
  addCommentEditorId: string; // id of the editor where the Comment button was clicked in the menu

  lastSorted: Comment[];
  commentAllowedIn: { [key: string]: boolean } = {}; // editor id where comment can be made RN equals ''/undefined if there is no such editor RN
  selectedTextInEditors: { [key: string]: string } = {}; // selected text in every editor
  displayedCommentsPositions: { [key: string]: { displayedTop: number; height: number } } = {};

  lastAddBoxHeight = 0;

  showAddCommentBox = false;
  shouldSetNewRows = false;
  tryMoveItemsUp = false;
  initialRender = false;

  errorMessages: { [key: string]: string } = {}; // error messages for editor selections

  doneRenderingComments$: Subject<string> = new Subject();

  newCommentMarkId = uuidv4();

  editorView: EditorView;

  preventRerenderUntilCommentAdd = { bool: false, id: '' };

  private showResolved$ = this.store.select(CommentsSelectors.selectShowResolved);
  private showResolved: boolean = false;

  private unsubscribe$ = new Subject<void>(); // Subject to manage unsubscriptions

  constructor(
    private serviceShare: ServiceShare,
    private commentsService: CommentsService,
    private ydocService: YdocService,
    private commentsStateService: CommentsStateService,
    private detectFocus: DetectFocusService,
    private editorsService: ProsemirrorEditorsService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.showResolved$
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.showResolved = value;
      });
  }

  setAddedCommentSubscription(commentInput: ElementRef): void {
    this.setContainerHeight();

    this.commentsStateService.subscription$.add(
      this.commentsService.addCommentSubject.subscribe((data) => {
        this.lastFocusedEditor = this.detectFocus.sectionName;
        this.editorView = this.editorsService.editorContainers[this.lastFocusedEditor]?.editorView;
        if (!this.lastFocusedEditor || !this.editorView || !this.editorView.state) return;

        if (data.type == 'commentData') {
          this.addCommentEditorId = data.sectionName;

          if (data.showBox) {
            this.addCommentBoxIsAlreadyMoved = false;
          } else {
            this.addCommentBoxIsAlreadyMoved = true;
          }

          setTimeout(() => {
            this.moveAddCommentBox(commentInput);
          }, 200);
          this.showAddCommentBox = data.showBox;
          if (!this.showAddCommentBox && commentInput && commentInput.nativeElement) {
            commentInput.nativeElement.value = '';
          }
        } else if (data.type == 'commentAllownes' && this.addCommentEditorId == data.sectionId) {
          if (this.showAddCommentBox && data.allow == false) {
            this.cancelBtnHandle(commentInput);
          }
          this.commentAllowedIn[data.sectionId] =
            data.allow && isCommentAllowed(this.editorView.state);
          this.selectedTextInEditors[data.sectionId] = data.text;
          this.errorMessages[data.sectionId] = data.errorMessage;
        } else if (this.lastFocusedEditor != this.addCommentEditorId) {
          this.cancelBtnHandle(commentInput);
        }
      })
    );
  }

  setCommentChangeSubscription(): void {
    this.commentsStateService.subscription$.add(
      this.commentsService.commentsChangeSubject.subscribe(() => {
        const commentsToAdd: Comment[] = [];
        const commentsToRemove: Comment[] = [];
        const allCommentsInEditors: Comment[] = [];
        allCommentsInEditors.push(...Object.values(this.commentsService.commentsObj));
        let editedComments = false;

        const idsThatShouldBeHidden = this.getHiddenCommentIds();

        this.processExistingComments(
          allCommentsInEditors,
          commentsToAdd,
          idsThatShouldBeHidden,
          editedComments
        );
        this.processRemovedComments(allCommentsInEditors, commentsToRemove);

        editedComments = this.handleCommentsChanges(
          commentsToAdd,
          commentsToRemove,
          editedComments
        );

        this.handleRenderingUpdates(editedComments);
      })
    );
  }

  cancelBtnHandle(commentInput: ElementRef): void {
    if (commentInput && commentInput.nativeElement) {
      commentInput.nativeElement.value = '';
    }
    this.commentsService.addCommentSubject!.next({
      type: 'commentData',
      sectionName: this.addCommentEditorId,
      showBox: false,
    });
  }

  moveAddCommentBox(commentInput: ElementRef): void {
    this.shouldSetNewRows = true;
    this.lastAddBoxHeight = 0;
    if (!this.showAddCommentBox && !this.addCommentBoxIsAlreadyMoved) {
      this.doneRendering('hide_comment_box');
      if (commentInput && commentInput.nativeElement) {
        commentInput.nativeElement.value = '';
      }
    } else if (!this.addCommentBoxIsAlreadyMoved) {
      this.addCommentBoxIsAlreadyMoved = true;
      this.newCommentMarkId = uuidv4();
      this.doneRendering('show_comment_box');
    }
  }

  doneRendering(cause?: string): void {
    const comments = (
      Array.from(document.getElementsByClassName('comment-container')) as HTMLDivElement[]
    ).sort((a, b) => {
      if (a.style.top && b.style.top) {
        return parseFloat(a.style.top) - parseFloat(b.style.top);
      }
    });
    (document.getElementsByClassName('end-article-spase')[0] as HTMLDivElement).style.minHeight =
      '500px';
    const container = document.getElementsByClassName(
      'all-comments-container'
    )[0] as HTMLDivElement;
    const allCommentCopy: Comment[] = JSON.parse(
      JSON.stringify(this.commentsStateService.allComments)
    );
    const sortedComments = allCommentCopy.sort((c1, c2) => {
      if (c1.domTop != c2.domTop) {
        return c1.domTop - c2.domTop;
      } else {
        return c1.pmDocStartPos - c2.pmDocStartPos;
      }
    });
    if ((!container || comments.length == 0) && cause != 'show_comment_box') {
      this.lastSorted = JSON.parse(JSON.stringify(sortedComments));
      return;
    }
    const selectedComment = this.commentsService.lastCommentSelected;
    if (this.notRendered) {
      this.positionAndFilterComments(sortedComments, comments);
    } else if (!this.notRendered && sortedComments.length > 0) {
      this.haveCommentsButAreNotRendered(
        selectedComment,
        sortedComments,
        cause,
        comments,
        container
      );
    }
    if (
      this.shouldScrollSelected &&
      selectedComment.commentId &&
      selectedComment.commentMarkId &&
      selectedComment.sectionId
    ) {
      const selectedCommentIndex = sortedComments.findIndex((com) => {
        return (
          com.commentAttrs.id == selectedComment.commentId ||
          com.threadComments.find((c) => c.commentAttrs.id == selectedComment.commentId)
        );
      });
      const selectedCommentSorted = sortedComments[selectedCommentIndex];
      const commentContainer = comments.find((element) => {
        return element.classList.contains(selectedCommentSorted?.commentAttrs.id);
      });
      if (commentContainer) {
        this.commentIsSelected(
          selectedCommentSorted,
          commentContainer,
          comments,
          selectedCommentIndex,
          sortedComments
        );
      }
    }
    if (this.showAddCommentBox) {
      this.showAddCommentBoxFunc(sortedComments, comments);
    }
    for (let i = 0; i < comments?.length; i++) {
      const com = comments[i];
      if (com) {
        const commentData = sortedComments[i];
        if (
          (com.getAttribute('resolved') == 'true' ||
            sortedComments[i].commentAttrs.resolved == 'true') &&
          !this.showResolved &&
          !commentData.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          this.hideComment(com);
        } else {
          this.showComment(com);
        }
      }
    }
    this.lastSorted = JSON.parse(JSON.stringify(sortedComments));

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setInitialCommentsData(): void {
    try {
      this.commentAllowedIn = this.commentsService.commentAllowdIn;
      this.selectedTextInEditors = this.commentsService.selectedTextInEditors;
      this.addCommentEditorId = this.commentsService.addCommentData.sectionName!;
      this.lastFocusedEditor = this.detectFocus.sectionName;
      if (this.lastFocusedEditor) {
        this.editorView = this.editorsService.editorContainers[this.lastFocusedEditor!].editorView;
        this.showAddCommentBox = this.commentsService.addCommentData.showBox;
      }
      this.setLastSelectedCommentSubscription();
      this.setCommentsRenderingSubscription();
    } catch (e) {
      console.error(e);
    }
  }

  hideComment(commentElement: HTMLDivElement): void {
    commentElement.style.opacity = '0';
    // the DOM event propagation being affected by elements with opacity:0,
    // so we need to disable pointer events
    commentElement.style.pointerEvents = 'none';
  }

  showComment(commentElement: HTMLDivElement): void {
    commentElement.style.opacity = '1';
    commentElement.style.pointerEvents = 'auto';
  }

  shouldHideResolvedComment(isResolved: string, comment: Comment): boolean {
    return (
      isResolved === 'true' &&
      !this.showResolved &&
      !comment.threadComments.find((c) => c.commentAttrs.resolved === 'false')
    );
  }

  private getHiddenCommentIds(): string[] {
    const currUser = this.ydocService.currUser;
    const collaborators = this.ydocService.collaborators
      .get('collaborators')
      .collaborators.filter((c: Collaborator) => c.id != currUser.id);

    if (this.serviceShare.hasOwnerCommentsPolicy) {
      return collaborators
        .map((c: Collaborator) => c.id)
        .filter((id: string) => id != currUser?.id);
    }

    return collaborators
      .filter(
        (c: Collaborator) =>
          c.hide_my_comments_from_user?.includes(currUser?.auth_role) ||
          c.hide_my_comments_from_user?.includes(currUser?.id)
      )
      .map((c: Collaborator) => c.id);
  }

  private processExistingComments(
    allCommentsInEditors: Comment[],
    commentsToAdd: Comment[],
    idsThatShouldBeHidden: string[],
    editedComments: boolean
  ): void {
    allCommentsInEditors.forEach((comment) => {
      const displayedCom = this.commentsStateService.allComments.find(
        (com) => com.commentAttrs.id == comment.commentAttrs.id
      );

      if (this.shouldRemoveDisplayedComment(displayedCom, idsThatShouldBeHidden)) {
        this.removeDisplayedComment(displayedCom);
        return;
      }

      if (displayedCom) {
        editedComments = this.updateExistingComment(displayedCom, comment, editedComments);
      } else if (!idsThatShouldBeHidden.includes(comment.commentAttrs.userid)) {
        commentsToAdd.push(comment);
      }
    });
  }

  private shouldRemoveDisplayedComment(
    displayedCom: Comment | undefined,
    idsThatShouldBeHidden: string[]
  ): boolean {
    return displayedCom && idsThatShouldBeHidden.includes(displayedCom.commentAttrs.userid);
  }

  private removeDisplayedComment(displayedCom: Comment): void {
    this.commentsStateService.allComments = this.commentsStateService.allComments.filter(
      (com) => com.commentAttrs.userid != displayedCom.commentAttrs.userid
    );
  }

  private updateExistingComment(
    displayedCom: Comment,
    comment: Comment,
    editedComments: boolean
  ): boolean {
    const fieldsToCheck = [
      { field: 'commentTxt', value: comment.commentTxt },
      { field: 'domTop', value: comment.domTop },
      { field: 'pmDocEndPos', value: comment.pmDocEndPos },
      { field: 'pmDocStartPos', value: comment.pmDocStartPos },
      { field: 'section', value: comment.section },
      { field: 'commentMarkId', value: comment.commentMarkId },
      { field: 'selected', value: comment.selected },
      { field: 'resolved', value: comment.resolved },
    ];

    fieldsToCheck.forEach(({ field, value }) => {
      if (displayedCom[field] !== value) {
        displayedCom[field] = value;
        editedComments = true;
      }
    });

    if (displayedCom.commentAttrs.resolved != comment.commentAttrs.resolved) {
      displayedCom.commentAttrs.resolved = comment.commentAttrs.resolved;
      editedComments = true;
    }

    if (this.serviceShare.compareObjects(displayedCom.threadComments, comment.threadComments)) {
      displayedCom.threadComments = comment.threadComments;
      editedComments = true;
    }

    if (editedComments) {
      displayedCom.commentAttrs = comment.commentAttrs;
    }

    return editedComments;
  }

  private processRemovedComments(
    allCommentsInEditors: Comment[],
    commentsToRemove: Comment[]
  ): void {
    this.commentsStateService.allComments.forEach((comment) => {
      if (!allCommentsInEditors.find((com) => com.commentAttrs.id == comment.commentAttrs.id)) {
        commentsToRemove.push(comment);
      }
    });
  }

  private handleCommentsChanges(
    commentsToAdd: Comment[],
    commentsToRemove: Comment[],
    editedComments: boolean
  ): boolean {
    if (commentsToAdd.length > 0) {
      this.commentsStateService.allComments.push(...commentsToAdd);
      this.rendered = 0;
      this.nOfCommThatShouldBeRendered = commentsToAdd.length;
      editedComments = true;
    }

    if (commentsToRemove.length > 0) {
      while (commentsToRemove.length > 0) {
        const commentToRemove = commentsToRemove.pop();
        const commentIndex = this.findCommentIndexToRemove(commentToRemove);
        this.commentsStateService.allComments.splice(commentIndex, 1);
      }
      editedComments = true;
    }

    return editedComments || this.shouldScrollSelected;
  }

  private findCommentIndexToRemove(commentToRemove: Comment): number {
    this.displayedCommentsPositions[commentToRemove.commentAttrs.id] = undefined;
    return this.commentsStateService.allComments.findIndex(
      (com) =>
        com.commentAttrs.id == commentToRemove.commentAttrs.id &&
        com.section == commentToRemove.section
    );
  }

  private handleRenderingUpdates(editedComments: boolean): void {
    if (editedComments) {
      setTimeout(() => this.doneRendering(), 50);
      this.setContainerHeight();
    } else if (this.initialRender) {
      this.initialRender = false;
      setTimeout(() => this.doneRendering(), 50);
    }

    this.changeDetectorRef.detectChanges();
  }

  private positionAndFilterComments(
    sortedComments: Comment[],
    comContainers: HTMLDivElement[]
  ): void {
    this.notRendered = false;
    let lastElementPosition = 0;

    this.setupUserFiltering(sortedComments);

    sortedComments.forEach((comment, index) => {
      const domElement = comContainers[index];
      lastElementPosition = this.positionComment(comment, domElement, lastElementPosition);
    });
  }

  private setupUserFiltering(comments: Comment[]): void {
    const byCreators = this.commentsStateService.sortingFormGroup.get(
      'byCreators'
    ) as UntypedFormArray;

    comments.forEach((comment) => {
      if (!this.commentsStateService.users.includes(comment.commentAttrs.username)) {
        byCreators.push(new UntypedFormControl(false));
        this.commentsStateService.users.push(comment.commentAttrs.username);
      }
    });
  }

  private positionComment(
    comment: Comment,
    domElement: HTMLDivElement,
    lastElementPosition: number
  ): number {
    const id = comment.commentAttrs.id;
    const isResolved = domElement.getAttribute('resolved');
    const height = domElement.getBoundingClientRect().height;

    const shouldHideResolved = this.shouldHideResolvedComment(isResolved, comment);
    const position = this.calculateCommentPosition(comment.domTop, lastElementPosition);

    this.updateCommentVisibility(domElement, shouldHideResolved);
    this.updateCommentPosition(domElement, position);
    this.updateCommentPositionData(id, position, height);

    return shouldHideResolved ? lastElementPosition : position + height;
  }

  private calculateCommentPosition(commentTop: number, lastPosition: number): number {
    return lastPosition < commentTop ? commentTop : lastPosition;
  }

  private updateCommentVisibility(element: HTMLDivElement, shouldHide: boolean): void {
    if (shouldHide) {
      this.hideComment(element);
    } else {
      this.showComment(element);
    }
  }

  private updateCommentPosition(element: HTMLDivElement, position: number): void {
    element.style.top = `${position}px`;
  }

  private updateCommentPositionData(commentId: string, position: number, height: number): void {
    this.displayedCommentsPositions[commentId] = {
      displayedTop: position,
      height,
    };
  }

  private setContainerHeight(): void {
    const container = document.getElementsByClassName(
      'all-comments-container'
    )[0] as HTMLDivElement;
    const articleElement = document.getElementById('app-article-element') as HTMLDivElement;
    if (!container || !articleElement) {
      return;
    }
    const articleElementRectangle = articleElement.getBoundingClientRect();
    if (container.getBoundingClientRect().height < articleElementRectangle.height) {
      container.style.height = articleElementRectangle.height + 'px';
    }
  }

  private setLastSelectedCommentSubscription(): void {
    let timeout: NodeJS.Timeout;
    this.commentsStateService.subscription$.add(
      this.store
        .select(CommentsSelectors.selectLastSelectedComment)
        .pipe(
          takeUntil(this.unsubscribe$),
          filter((comment) => !!comment.commentId),
          distinctUntilChanged((prev, curr) => prev.commentId === curr.commentId)
        )
        .subscribe((data) => {
          if (data.commentId && data.commentMarkId && data.sectionId) {
            this.shouldScrollSelected = true;
          } else {
            setTimeout(() => {
              this.doneRendering();
            }, 20);
          }
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.commentsService.buildGlobalCommentMap();
          }, 200);
        })
    );
  }

  private setCommentsRenderingSubscription(): void {
    this.commentsStateService.subscription$.add(
      this.doneRenderingComments$.subscribe((data) => {
        if (this.rendered < this.nOfCommThatShouldBeRendered) {
          this.rendered++;
        }
        if (data == 'replay_rerender') {
          this.doneRendering('replay_rerender');
          return;
        }
        if (data == 'change_in_comments_in_ydoc') {
          this.doneRendering('change_in_comments_in_ydoc');
        }
        if (data == 'show_more_less_click') {
          this.doneRendering('show_more_less_click');
        }
        if (this.rendered == this.nOfCommThatShouldBeRendered) {
          this.doneRendering();
        }
      })
    );
  }

  private loopFromTopAndOrderComments(
    sortedComments: Comment[],
    comContainers: HTMLDivElement[]
  ): void {
    let lastElementBottom = 0;
    sortedComments.forEach((com, i) => {
      const id = com.commentAttrs.id;
      const domElement = comContainers[i];
      const isResolved = domElement.getAttribute('resolved');
      const h = domElement.getBoundingClientRect().height;
      if (
        !this.displayedCommentsPositions[id] ||
        this.displayedCommentsPositions[id].height != h ||
        com.domTop <= this.displayedCommentsPositions[id].displayedTop
      ) {
        // old and new comment either don't have the same top or comment's height is changed
        if (lastElementBottom < com.domTop) {
          if (
            isResolved == 'true' &&
            !this.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            this.hideComment(domElement);
            const pos = com.domTop;
            domElement.style.top = pos + 'px';
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
          } else {
            const pos = com.domTop;
            domElement.style.top = pos + 'px';
            this.showComment(domElement);
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastElementBottom = pos + h;
          }
        } else {
          if (
            isResolved == 'true' &&
            !this.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            this.hideComment(domElement);
            const pos = lastElementBottom;
            domElement.style.top = pos + 'px';
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
          } else {
            const pos = lastElementBottom;
            domElement.style.top = pos + 'px';
            this.showComment(domElement);
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastElementBottom = pos + h;
          }
        }
      } else {
        lastElementBottom =
          this.displayedCommentsPositions[id].displayedTop +
          this.displayedCommentsPositions[id].height;
      }
    });
  }

  private loopFromBottomAndOrderComments(
    sortedComments: Comment[],
    comContainers: HTMLDivElement[],
    addComContainer: HTMLDivElement
  ): void {
    let lastCommentTop = addComContainer.getBoundingClientRect().height;
    let i = sortedComments.length - 1;
    while (i >= 0) {
      const com = sortedComments[i];
      const id = com.commentAttrs.id;
      const domElement = comContainers[i];
      const isResolved = domElement.getAttribute('resolved');
      const h = domElement.getBoundingClientRect().height;
      if (
        !this.displayedCommentsPositions[id] ||
        this.displayedCommentsPositions[id].height != h ||
        this.displayedCommentsPositions[id].displayedTop <= com.domTop
      ) {
        // old and new comment either don't have the same top or comment's height is changed
        if (lastCommentTop > com.domTop + h) {
          if (
            isResolved == 'true' &&
            !this.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            this.hideComment(domElement);
          } else {
            const pos = com.domTop;
            domElement.style.top = pos + 'px';
            this.showComment(domElement);
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastCommentTop = pos;
          }
        } else {
          if (
            isResolved == 'true' &&
            !this.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            this.hideComment(domElement);
          } else {
            const pos = lastCommentTop - h;
            domElement.style.top = pos + 'px';
            this.showComment(domElement);
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastCommentTop = pos;
          }
        }
      } else {
        lastCommentTop = this.displayedCommentsPositions[id].displayedTop;
      }
      i--;
    }
  }

  private commentIsSelected(
    selectedCommentSorted: Comment,
    commentContainer: HTMLDivElement,
    comments: HTMLDivElement[],
    selectedCommentIndex: number,
    sortedComments: Comment[]
  ): void {
    if (selectedCommentSorted.domTop < 80) {
      selectedCommentSorted.domTop = 80;
    }

    commentContainer.style.top = selectedCommentSorted.domTop + 'px';
    this.displayedCommentsPositions[selectedCommentSorted.commentAttrs.id] = {
      displayedTop: selectedCommentSorted.domTop,
      height: commentContainer.getBoundingClientRect().height,
    };

    //loop comments up in the group and move them if any
    let lastCommentTop = selectedCommentSorted.domTop;
    let i = selectedCommentIndex - 1;
    const commentsGroupTopEnd = false;
    while (i >= 0 && !commentsGroupTopEnd) {
      const com = sortedComments[i];
      const id = com.commentAttrs.id;
      const domElement = comments.find((element) => {
        return element.classList.contains(id);
      });
      const isResolved = domElement.getAttribute('resolved');
      const h = domElement.getBoundingClientRect().height;
      if (lastCommentTop > com.domTop + h) {
        if (
          isResolved == 'true' &&
          !this.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          this.hideComment(domElement);
          const pos = com.domTop;
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          domElement.style.top = pos + 'px';
        } else {
          const pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastCommentTop = pos;
        }
      } else {
        if (
          isResolved == 'true' &&
          !this.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          this.hideComment(domElement);
          const pos = lastCommentTop - h;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          const pos = lastCommentTop - h;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastCommentTop = pos;
        }
      }
      i--;
    }
    let lastElementBottom =
      selectedCommentSorted.domTop + commentContainer.getBoundingClientRect().height;
    let i1 = selectedCommentIndex + 1;
    const n = sortedComments.length;
    const commentsGroupBottomEnd = false;
    while (i1 < n && !commentsGroupBottomEnd) {
      const com = sortedComments[i1];
      const id = com.commentAttrs.id;
      const domElement = comments.find((element) => {
        return element.classList.contains(id);
      });
      const isResolved = domElement.getAttribute('resolved');
      const h = domElement.getBoundingClientRect().height;
      if (lastElementBottom < com.domTop) {
        if (
          isResolved == 'true' &&
          !this.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          this.hideComment(domElement);
          const pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          const pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastElementBottom = pos + h;
        }
      } else {
        if (
          isResolved == 'true' &&
          !this.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          this.hideComment(domElement);
          const pos = lastElementBottom;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          const pos = lastElementBottom;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastElementBottom = pos + h;
        }
      }
      i1++;
    }
    this.shouldScrollSelected = false;
  }

  private showAddCommentBoxFunc(sortedComments: Comment[], comments: HTMLDivElement[]): void {
    const addCommentBoxEl = document.getElementsByClassName('add-comment-box')[0] as HTMLDivElement;
    const articleElement = document.getElementById('app-article-element') as HTMLDivElement;
    const editorContainer = document.getElementsByClassName(
      'editor-container'
    )[0] as HTMLDivElement;
    const mainEditorContainer = document.getElementsByClassName(
      'main-editor-container'
    )[0] as HTMLDivElement;
    const editorRectangle = editorContainer.getBoundingClientRect();
    const articleElementRectangle = articleElement.getBoundingClientRect();
    const boxH = addCommentBoxEl.getBoundingClientRect().height;
    const newMarkPos = this.editorView.state.selection.from;
    const domCoords = this.editorView.coordsAtPos(newMarkPos);
    let boxTop = domCoords.top - articleElementRectangle.top - boxH / 2;
    if (boxTop < 0) {
      boxTop = 80;
    }
    this.addCommentBoxTop = boxTop;
    this.addCommentBoxH = boxH;
    addCommentBoxEl.style.top = boxTop + 'px';
    addCommentBoxEl.style.opacity = '1';
    const inputElement = document.getElementsByClassName('comment-input')[0] as HTMLInputElement;
    setTimeout(() => {
      inputElement.focus();
    }, 300);
    setTimeout(() => {
      let scroll = 0;
      if (mainEditorContainer.scrollTop >= 0) {
        scroll = 1;
      }
      if (editorRectangle.height - mainEditorContainer.scrollTop < 0) {
        scroll = -1;
      }
      mainEditorContainer.scrollBy({
        top: scroll,
        behavior: 'smooth',
      });
    }, 200);
    const positionsArr: { id: string; displayedTop: number; height: number }[] = [];
    Object.keys(this.displayedCommentsPositions).forEach((key) => {
      const val = this.displayedCommentsPositions[key];
      if (val) {
        positionsArr.push({ id: key, displayedTop: val.displayedTop, height: val.height });
      }
    });
    positionsArr.sort((a, b) => {
      return a.displayedTop - b.displayedTop;
    });
    const commentsInBox: {
      id: string;
      displayedTop: number;
      height: number;
      posArrIndex: number;
      dir: 'up' | 'down';
    }[] = [];
    let idOfComThatShouldBeBeforeAddBox: string;
    let idOfComThatShouldBeAfterAddBox: string;
    sortedComments.forEach((com) => {
      if (com.domTop < boxTop || (com.domTop == boxTop && com.pmDocStartPos < newMarkPos)) {
        if (
          com.resolved == 'true' &&
          !this.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          // idOfComThatShouldBeAfterAddBox = com.commentAttrs.id;
        } else {
          idOfComThatShouldBeBeforeAddBox = com.commentAttrs.id;
        }
      }
      if (
        !idOfComThatShouldBeAfterAddBox &&
        (com.domTop > boxTop || (com.domTop == boxTop && com.pmDocStartPos > newMarkPos))
      ) {
        if (
          com.resolved == 'true' &&
          !this.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          // idOfComThatShouldBeAfterAddBox = com.commentAttrs.id;
        } else {
          idOfComThatShouldBeAfterAddBox = com.commentAttrs.id;
        }
      }
    });
    positionsArr.forEach((pos, index) => {
      if (pos.id == idOfComThatShouldBeBeforeAddBox) {
        commentsInBox[0] = { ...pos, posArrIndex: index, dir: 'up' };
      }
      if (pos.id == idOfComThatShouldBeAfterAddBox) {
        commentsInBox[1] = { ...pos, posArrIndex: index, dir: 'down' };
      }
    });
    const newCommentsPos: { displayedTop: number; height: number; id: string }[] = [];
    commentsInBox.forEach((pos) => {
      if (pos.dir == 'up') {
        let offset = boxTop - (pos.displayedTop + pos.height);
        let index = pos.posArrIndex;
        let comTop: number;
        while (
          index >= 0 &&
          (offset < 0 ||
            pos.displayedTop < sortedComments.find((com) => com.commentAttrs.id == pos.id).domTop)
        ) {
          comTop = positionsArr[index].displayedTop;
          const spaceUntilUpperElement =
            index == 0
              ? 0
              : comTop - (positionsArr[index - 1].displayedTop + positionsArr[index - 1].height);
          const newComTop = comTop + offset;
          newCommentsPos[index] = {
            displayedTop: newComTop,
            id: positionsArr[index].id,
            height: positionsArr[index].height,
          };
          offset += spaceUntilUpperElement;
          index--;
        }
      } else {
        let offset = boxH + boxTop - pos.displayedTop;
        let index = pos.posArrIndex;
        let comTop: number;
        let comBot: number;
        const commN = sortedComments.length;
        while (
          index < commN &&
          (offset > 0 ||
            pos.displayedTop > sortedComments.find((com) => com.commentAttrs.id == pos.id).domTop)
        ) {
          comTop = positionsArr[index].displayedTop;
          comBot = positionsArr[index].displayedTop + positionsArr[index].height;
          const spaceUntilLowerElement =
            index == commN - 1 ? 0 : positionsArr[index + 1].displayedTop - comBot;
          const newComTop = comTop + offset;
          newCommentsPos[index] = {
            displayedTop: newComTop,
            id: positionsArr[index].id,
            height: positionsArr[index].height,
          };
          offset -= spaceUntilLowerElement;
          index++;
        }
      }
    });
    newCommentsPos.forEach((pos, i) => {
      const id = pos.id;
      this.displayedCommentsPositions[id] = {
        displayedTop: pos.displayedTop,
        height: pos.height,
      };
      const domElement = comments[i];
      domElement.style.top = this.displayedCommentsPositions[id].displayedTop + 'px';
      const resolved = domElement.getAttribute('resolved');
      if (resolved == 'false' || (resolved == 'true' && this.showResolved)) {
        this.showComment(domElement);
      }
    });
  }

  private haveCommentsButAreNotRendered(
    selectedComment: {
      commentId?: string;
      pos?: number;
      sectionId?: string;
      commentMarkId?: string;
    },
    sortedComments: Comment[],
    cause: string,
    comments: HTMLDivElement[],
    container: HTMLDivElement
  ): void {
    if (
      this.shouldScrollSelected &&
      (!selectedComment.commentId || !selectedComment.commentMarkId || !selectedComment.sectionId)
    ) {
      this.shouldScrollSelected = false;
    }
    const idsOldOrder: string[] = [];

    const oldPos = this.lastSorted.reduce<{ top: number; id: string }[]>((prev, curr) => {
      idsOldOrder.push(curr.commentAttrs.id);
      return [...prev, { top: curr.domTop, id: curr.commentAttrs.id }];
    }, []);

    const idsNewOrder: string[] = [];
    const newPos = sortedComments.reduce<{ top: number; id: string }[]>((prev, curr) => {
      idsNewOrder.push(curr.commentAttrs.id);
      return [...prev, { top: curr.domTop, id: curr.commentAttrs.id }];
    }, []);

    if (this.preventRerenderUntilCommentAdd.bool) {
      const newComId = this.preventRerenderUntilCommentAdd.id;
      if (!idsNewOrder.includes(newComId)) {
        return;
      } else {
        this.preventRerenderUntilCommentAdd.bool = false;
      }
    }
    // determine what kind of change it is
    if (JSON.stringify(oldPos) != JSON.stringify(newPos) || cause || this.tryMoveItemsUp) {
      if (
        JSON.stringify(idsOldOrder) == JSON.stringify(idsNewOrder) ||
        cause ||
        this.tryMoveItemsUp
      ) {
        // comments are in same order
        if (oldPos.length > 0 && oldPos[oldPos.length - 1].top > newPos[newPos.length - 1].top) {
          // comments have decreased top should loop from top
          this.loopFromTopAndOrderComments(sortedComments, comments);
        } else if (
          oldPos.length > 0 &&
          oldPos[oldPos.length - 1].top < newPos[newPos.length - 1].top
        ) {
          // comments have increased top should loop from bottom
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
        } else if (
          cause == 'hide_comment_box' ||
          cause == 'replay_rerender' ||
          cause == 'change_in_comments_in_ydoc' ||
          cause == 'show_more_less_click'
        ) {
          this.loopFromTopAndOrderComments(sortedComments, comments);
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
        } else if (this.tryMoveItemsUp) {
          this.loopFromTopAndOrderComments(sortedComments, comments);
          this.tryMoveItemsUp = false;
        } else {
          // moved an existing comment
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
          this.loopFromTopAndOrderComments(sortedComments, comments);
        }
      } else {
        // comments are not in the same order
        if (idsOldOrder.length < idsNewOrder.length) {
          // added a comment
          const addedCommentId = idsNewOrder.find((commentId) => !idsOldOrder.includes(commentId));
          const sortedComment = sortedComments.find((com) => com.commentAttrs.id == addedCommentId);
          const commentContainer = comments.find((element) => {
            return element.classList.contains(addedCommentId);
          });
          if (commentContainer) {
            commentContainer.style.top = sortedComment.domTop + 'px';
            const resolved = commentContainer.getAttribute('resolved');
            if (resolved == 'false' || (resolved == 'true' && this.showResolved)) {
              commentContainer.style.opacity = '1';
            }
            this.displayedCommentsPositions[addedCommentId] = {
              displayedTop: sortedComment.domTop,
              height: commentContainer.getBoundingClientRect().height,
            };
            this.loopFromTopAndOrderComments(sortedComments, comments);
          }
        } else if (idsNewOrder.length < idsOldOrder.length) {
          // removed a comment
          this.loopFromTopAndOrderComments(sortedComments, comments);
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
        } else if (idsNewOrder.length == idsOldOrder.length) {
          // comments are reordered
          this.positionAndFilterComments(sortedComments, comments);
        }
      }
    }
  }
}
