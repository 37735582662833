import { Component, OnInit, OnDestroy } from '@angular/core';
import { FeaturebaseService } from '../../services/featurebase.service';
import { AuthService } from '@app/core/services/auth.service';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UiError } from '@app/core/error-handling/custom-errors/ui-error';

@Component({
  selector: 'app-feedback-widget',
  templateUrl: './feedback-widget.component.html',
  styleUrls: ['./feedback-widget.component.scss'],
})
export class FeedbackWidgetComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private featurebaseService: FeaturebaseService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.authService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (!user) return;

      this.featurebaseService
        .initializeWidget()
        .pipe(
          catchError((error) => {
            console.log('initializeWidget', error);
            return throwError(
              () =>
                new UiError(
                  'FeedbackWidgetComponent',
                  'Error initializing feedback widget',
                  'initializeWidget'
                )
            );
          })
        )
        .subscribe(() => {
          this.featurebaseService.identifyUser({
            id: user.id,
            email: user.email,
            name: user.name,
          });
        });
    });
  }
}
