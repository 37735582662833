import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { AuthService } from './services/auth.service';
import { EchoService } from './services/echo/echo.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './error-handling/interceptor/http-error.interceptor';
import { CommentsInterceptor } from './interceptors/comments.interceptor';
import { CasbinInterceptor } from '../casbin/interceptor/casbin.interceptor';
import { FakeBackendInterceptor } from './interceptors/fake-backend/fake-backend.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ApiVersionInterceptor } from './interceptors/api-version.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ExternalRefsInterceptor } from './interceptors/external-refs.interceptor';
import { UserFeedbackModule } from './user-feedback/user-feedback.module';
import { defaultFeatureBaseConfig } from './user-feedback/models/featurebase.models';
import { NetworkInterceptor } from './error-handling/interceptor/network.interceptor';
import { SessionMonitoringModule } from './session-monitoring/session-monitoring.module';

@NgModule({
  imports: [
    ErrorHandlingModule,
    CommonModule,
    UserFeedbackModule.forRoot(defaultFeatureBaseConfig),
    SessionMonitoringModule,
  ],
  exports: [ErrorHandlingModule, CommonModule, UserFeedbackModule],

  providers: [
    AuthService,
    EchoService,
    // 0. Network state (first to prevent unnecessary requests when offline)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    // 1. Loading state (first to show loading indicator)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    // 2. API Version (early to set headers)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true,
    },
    // 3. Authentication (before business logic)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // 4. Access Control (after auth, before business logic)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CasbinInterceptor,
      multi: true,
    },
    // 5. Business Logic Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommentsInterceptor,
      multi: true,
    },
    // 6. External References (response transformation)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExternalRefsInterceptor,
      multi: true,
    },
    // 7. Error Handling (before mock to catch all errors including mocked ones)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    // 8. Mock Backend (last, after error handling)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FakeBackendInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
