import { CustomErrorDetails } from '../models';
import { CustomError, CustomErrorType } from './models';

export abstract class BaseCustomError extends Error implements CustomError {
  timestamp: number;
  details: CustomErrorDetails;
  customErrorType: CustomErrorType;

  constructor(message: string, timestamp: number) {
    super(message);
    this.timestamp = timestamp;
  }
}
