import { CustomErrorType, UiErrorDetails } from './models';
import { BaseCustomError } from './base-custom.error';

export class UiError extends BaseCustomError {
  public customErrorType: CustomErrorType.ui;
  public timestamp: number;
  public details: UiErrorDetails;

  constructor(
    public componentName: string,
    public message: string,
    public otherDetails: string = 'A UI error occurred.'
  ) {
    const timestamp = Date.now();
    super(message, timestamp);
    this.customErrorType = CustomErrorType.ui;
    this.timestamp = timestamp;
    this.details = {
      componentName,
      message,
      otherDetails,
    };
  }
}
