import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { Subject, Subscription } from 'rxjs';

import { Schema, Fragment, Mark, Node, DOMParser } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { getCitationIfAny } from '@app/editor/utils/citableElementsHelpers';
import { CITATION_ELEMENTS } from '@app/editor/utils/menu/menu-dialogs';
import { schema } from '@app/editor/utils/Schema';
import { EditorContainersMap } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { ArticleSection } from '@app/editor/utils/interfaces/articleSection';
import {
  citationElementMap,
  Citations,
  citationsPMNodeNames,
  ElementHtmlEntry,
  elementsContainersPMNodesNames,
  ElementsObjects,
  ElementsTypeToCitationMap,
  EndNote,
  Figure,
  FigureComponent,
  SupplementaryFile,
  Table,
} from '@app/editor/services/citable-elements.models';
import { HelperCitableElementsService } from '@app/editor/services/helper-citable-elements.service';

@Injectable({
  providedIn: 'root',
})
export class JatsCitableElementsService {
  deletedTablesForRerender = {};

  rendered = 0;

  figures: { [key: string]: Figure } = {};
  figuresNumbers: string[] = [];
  figuresTemplates: ElementHtmlEntry = {} as ElementHtmlEntry;

  tables: { [key: string]: Table } = {};
  tablesNumbers: string[] = [];
  tablesTemplates: ElementHtmlEntry = {} as ElementHtmlEntry;

  supplementaryFilesNumbers: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supplementaryFiles: { [key: string]: any } = {};
  supplFileTemplates: ElementHtmlEntry = {} as ElementHtmlEntry;

  endNotesNumbers: string[] = [];
  endNotes: { [key: string]: EndNote } = {};

  editors: EditorContainersMap = {};

  articleSections: ArticleSection[] = [];

  updatingElementsAndElementsCitations = false;

  elementsFromPopupEdit: {
    [key: string]: Table | Figure | SupplementaryFile | EndNote;
  } = {};

  renderedViews = 0;

  subscription: Subscription;

  constructor(
    private serviceShare: ServiceShare,
    private helperCitableElementsService: HelperCitableElementsService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  getElementsTemplates(elementTemplates: string): { [key: string]: { html: string } } {
    return this[elementTemplates];
  }

  getElementsNumbersObjs(): { figure: string[]; table: string[] } {
    return { figure: this.figuresNumbers, table: this.tablesNumbers };
  }

  /**
   * Updates the citation text in each section's editor by scanning for citation marks
   * and replacing them based on whether the cited elements exist.
   */
  public updateCitatsText(citats: Citations): Citations {
    // Retrieve the mapping of element numbers for each citation type.
    const elementsNumbersObjs = this.getElementsNumbersObjs();

    // Iterate through each section's citations.
    Object.keys(citats).forEach((sectionID: string) => {
      const sectionCitations = citats[sectionID];
      if (sectionCitations) {
        Object.keys(sectionCitations).forEach((citatID: string) => {
          // (Redundant check as in original code.)
          if (!citats[sectionID]) {
            citats[sectionID] = undefined;
          } else {
            // Get the editor view for the section.
            const edView = this.editors[sectionID].editorView;
            // Traverse the document nodes in the editor.
            edView.state.doc.nodesBetween(0, edView.state.doc.nodeSize - 2, (node, pos) => {
              const citationMarks = getCitationIfAny(node);
              if (citationMarks.length > 0) {
                const citationMark = citationMarks[0];
                // If the citation mark belongs to this citation, process it.
                if (citationMark.attrs.citateid === citatID) {
                  this.processCitationMarkForUpdate(
                    edView,
                    node,
                    pos,
                    citationMark,
                    elementsNumbersObjs
                  );
                }
              }
            });
          }
        });
      }
    });

    return citats;
  }

  /**
   * Processes a single citation mark by checking whether the cited elements exist.
   * If no expected elements are found, it replaces the node with a "deleted" citation;
   * otherwise, it rebuilds the citation string.
   */
  private processCitationMarkForUpdate(
    edView: EditorView,
    node: Node,
    pos: number,
    citationMark: Mark,
    elementsNumbersObjs: {
      figure: string[];
      table: string[];
    }
  ): void {
    // Clone the cited elements array.
    let citatedElements: string[] = [...citationMark.attrs.citated_elements];

    // Get the mapping configuration for this citation mark.
    const elementMapping: any = citationElementMap[citationMark.type.name];
    console.log(elementMapping.type); // Debug: log the citation type.
    const elementNumbers: string[] | undefined = elementsNumbersObjs[elementMapping.type];

    // Build a list of "clear" element IDs and a mapping of element IDs to component data.
    const { clearComponents, componentsMap } = this.buildCitationComponents(citatedElements);

    // CASE 1: None of the expected elements exist.
    if (
      (clearComponents.length === 1 &&
        (!elementNumbers || elementNumbers.indexOf(clearComponents[0]) === -1)) ||
      (clearComponents.length > 1 &&
        clearComponents.filter((el) => elementNumbers && elementNumbers.indexOf(el) !== -1)
          .length === 0)
    ) {
      // If not already flagged as "nonexisting", replace with deleted text.
      if (citationMark.attrs.nonexistingelement !== 'true') {
        const deletedText: string = elementMapping.deletedElTxt;
        let newNode: Node = edView.state.schema.text(deletedText);
        newNode = newNode.mark([
          edView.state.schema.mark(citationMark.type.name, {
            ...citationMark.attrs,
            nonexistingelement: 'true',
          }),
        ]);
        if (node.nodeSize <= 1) return;
        edView.dispatch(
          edView.state.tr
            .replaceWith(pos, pos + node.nodeSize, newNode)
            .setMeta('citatsTextChange', true)
        );
      }
    } else {
      // CASE 2: Some expected elements exist.
      // Remove cited element strings that are not in elementNumbers.
      clearComponents.forEach((elid: string) => {
        if (!elementNumbers || elementNumbers.indexOf(elid) === -1) {
          citatedElements = citatedElements.filter((elIDS: string) => {
            const data = elIDS.split('|');
            return data[0] !== elid;
          });
        }
      });
      // Keep only clear components that exist.
      const filteredClearComponents: string[] = clearComponents.filter((elId: string) =>
        elementNumbers ? elementNumbers.includes(elId) : false
      );

      // Build the citation string prefix.
      let citatString: string =
        filteredClearComponents.length === 1
          ? elementMapping.singleElTxt
          : elementMapping.multipleElTxt;

      const elsArr: string[] = [];
      if (elementNumbers) {
        elementNumbers.forEach((element: string, i: number) => {
          if (filteredClearComponents.indexOf(element) !== -1) {
            if (componentsMap[element]) {
              componentsMap[element].forEach((elComponent: string, j: number) => {
                if (j === 0) {
                  // For the first component, determine its index among components.
                  const figures = this.serviceShare.ydocVersion[elementMapping.yjsMap].get(
                    elementMapping.elementsObj
                  );
                  let compIndex = 0;
                  Object.keys(figures).forEach((key: string) => {
                    const figure = figures[key].components.find((c: any) => c.id === elComponent);
                    if (figure) {
                      compIndex = (figures[key].components as any[]).findIndex(
                        (c: any) => c.id === elComponent
                      );
                    }
                  });
                  elsArr.push(`${i + 1}${String.fromCharCode(97 + compIndex)}`);
                } else {
                  elsArr.push(String.fromCharCode(97 + +elComponent));
                }
              });
            } else {
              elsArr.push(`${i + 1}`);
            }
          }
        });
      }

      // Build the new citation node.
      let newNode: Node;
      const marks = node.marks.filter((m: any) => !CITATION_ELEMENTS.includes(m.type.name));
      if (elementMapping.getCitationNode) {
        newNode = elementMapping.getCitationNode(
          elsArr,
          citationMark.attrs,
          citatedElements,
          edView
        );
        if (newNode.content[0] && newNode.content[0].marks) {
          newNode.content[0].marks.push(...marks);
        }
      } else {
        citatString += elsArr.join(', ') + ' ';
        newNode = edView.state.schema.text(citatString);
        newNode = newNode.mark([
          edView.state.schema.mark(citationMark.type.name, {
            ...citationMark.attrs,
            citated_elements: citatedElements,
            nonexistingtable: 'false',
          }),
          ...marks,
        ]);
      }
      if (node.nodeSize <= 1) return;
      edView.dispatch(
        edView.state.tr
          .replaceWith(pos, pos + node.nodeSize, newNode)
          .setMeta('citatsTextChange', true)
      );
    }
  }

  /**
   * Splits each cited element string (separated by '|') and builds an object
   * with a list of unique element IDs (clearComponents) and a mapping from each element
   * ID to an array of its component strings.
   */
  private buildCitationComponents(citatedElements: string[]): {
    clearComponents: string[];
    componentsMap: { [key: string]: string[] };
  } {
    const clearComponents: string[] = [];
    const componentsMap: { [key: string]: string[] } = {};
    citatedElements.forEach((el: string) => {
      if (el) {
        const data = el.split('|');
        const elId = data[0];
        if (!componentsMap[elId]) {
          componentsMap[elId] = [];
        }
        if (data[1]) {
          componentsMap[elId].push(data[1]);
        }
        if (clearComponents.indexOf(elId) === -1) {
          clearComponents.push(elId);
        }
      }
    });
    return { clearComponents, componentsMap };
  }

  resetCountedRenderedViews(): void {
    this.rendered = 0;
  }

  getCitationsInElementsObj(elementObj: ElementsObjects): ElementsObjects {
    const elTypes = Object.keys(elementObj);
    const elMaps = {};
    elTypes.forEach((type) => {
      const citationName = ElementsTypeToCitationMap[type];
      elMaps[type] = citationElementMap[citationName];
    });
    const citationsInElements = {} as ElementsObjects;

    elTypes.forEach((elType) => {
      const elementsOfType = elementObj[elType];
      const elementsIds = Object.keys(elementsOfType);
      citationsInElements[elType] = {};
      elementsIds.forEach((elId) => {
        const elData = elementsOfType[elId];
        const HTMLstringInEl = elMaps[elType].getElsStrings(elData);
        const citationsInElement = []; // hold all the citation in the element seperated by types
        elTypes.forEach((elType1) => {
          const elementCitationHTMLTag = elMaps[elType1].htmlTag;
          const searchElementCitationREGEX = new RegExp(
            `<${elementCitationHTMLTag}.+?(?=<\/${elementCitationHTMLTag}>)<\/${elementCitationHTMLTag}>`,
            'gm'
          );
          const searchElementIdREGEX = new RegExp(`citated_elements=".+?"`, 'gm');

          const innerCited = [];
          let match;
          while ((match = searchElementCitationREGEX.exec(HTMLstringInEl)) !== null) {
            if (match[0].length > 0) {
              let citedElements = match[0].match(searchElementIdREGEX);
              if (citedElements.length > 0) {
                citedElements = citedElements[0]
                  .split('"')[1]
                  .split(',')
                  .map((el) => el.split('|')[0]);
              }
              citedElements = citedElements.filter((el) => el.length > 0);
              innerCited.push({
                citat: match[0],
                index: match.index,
                type: elType1,
                citationName: ElementsTypeToCitationMap[elType1],
                citedElements,
              });
            }
          }
          citationsInElement.push(...innerCited);
        });
        citationsInElement.sort((a, b) => {
          return a.index - b.index;
        });

        citationsInElements[elType][elId] = citationsInElement;
      });
    });
    return citationsInElements;
  }

  updateElementsNumbers(
    newElements: { [key: string]: Figure | Table | SupplementaryFile | EndNote },
    elementNumbers: string[],
    elementNumberProp: string
  ): {
    [key: string]: Figure | Table | SupplementaryFile | EndNote;
  } {
    Object.keys(newElements).forEach((elKey) => {
      const elNumber = elementNumbers.indexOf(elKey);
      newElements[elKey][elementNumberProp] = elNumber;
    });
    return newElements;
  }

  getElementsObj(): { figure: { [key: string]: Figure }; table: { [key: string]: Table } } {
    return { figure: this.figures, table: this.tables };
  }

  markElementsCitatsViews = (
    citatsBySection: Citations,
    elsDisplayedInObj: { [key: string]: { [key: string]: string } },
    olderElements?: { [key: string]: { [key: string]: { [key: string]: boolean } } }
  ): Citations => {
    this.resetCountedRenderedViews();
    const elementsNumbersObjs = this.getElementsNumbersObjs();
    const elementsNumbersObjsCopy = {};
    Object.keys(elementsNumbersObjs).forEach((elType) => {
      elementsNumbersObjsCopy[elType] = JSON.parse(JSON.stringify(elementsNumbersObjs[elType]));
    });

    const elementsObjs = this.getElementsObj();
    Object.keys(this.editors).forEach((key) => {
      if (key == 'endNotesEditor' || key == 'supplementaryFilesView') return;
      let containersCount = 0;
      const view = this.editors[key].editorView;
      view.state.doc.descendants((el) => {
        if ([...elementsContainersPMNodesNames, 'example'].includes(el.type.name)) {
          containersCount++;
        }
      });
      let deleted = false;
      let tr1: Transaction;
      const del = () => {
        deleted = false;
        tr1 = view.state.tr;
        view.state.doc.descendants((node, position) => {
          if ([...elementsContainersPMNodesNames, 'example'].includes(node.type.name) && !deleted) {
            deleted = true;
            tr1 = tr1
              .deleteRange(position, position + node.nodeSize)
              .setMeta('citable-elements-rerender', true);
          }
        });
        view.dispatch(tr1);
      };
      for (let index = 0; index < containersCount; index++) {
        del();
      }
      if (key == 'endEditor') {
      }
    });

    const elementsDysplayedViews = {};
    Object.keys(elementsNumbersObjsCopy).forEach((elType) => {
      elementsDysplayedViews[elType] = elementsNumbersObjsCopy[elType].map(() => {
        return false;
      });
    });

    const articleFlatStructure = this.editors;

    const citationsInElementsObj = this.getCitationsInElementsObj(elementsObjs as ElementsObjects);
    Object.keys(articleFlatStructure).forEach((sectionID: string) => {
      const sortable = [];
      for (const citat in citatsBySection[sectionID]) {
        if (citatsBySection[sectionID][citat]) {
          sortable.push([citat, citatsBySection[sectionID][citat]]);
        }
      }

      sortable.sort(function (a, b) {
        return a[1].position - b[1].position;
      });

      sortable.forEach((citatData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const displayedElementViewsHere: any[] = [];
        const citationType = citatData[1].citationType;
        const citedElements = citatData[1].citedElementsIDs.map((el) => el?.split('|')?.[0]);

        this.helperCitableElementsService.getViewsOnCitation(
          citedElements,
          citationType,
          elementsNumbersObjs,
          elementsDysplayedViews,
          citationsInElementsObj,
          displayedElementViewsHere
        );
        citatsBySection[sectionID][citatData[0]].displayedViewsHere = displayedElementViewsHere;
        displayedElementViewsHere.forEach((el) => {
          elsDisplayedInObj[el.type][el.elId] = sectionID;
        });
      });
    });
    const nonDisplayedViewsWithIndexes = {};
    Object.keys(elsDisplayedInObj).forEach((type) => {
      const elementsOfType = elsDisplayedInObj[type];
      nonDisplayedViewsWithIndexes[type] = [];
      Object.keys(elementsOfType).forEach((elId) => {
        if (elementsOfType[elId] == 'endEditor') {
          nonDisplayedViewsWithIndexes[type].push({
            elId,
            elIndex: elementsNumbersObjs[type].indexOf(elId),
          });
        }
      });
    });
    let typesToRenderInEndEdtior = 0;
    let count = 0;
    const countRendered = () => {
      count++;
      if (count == typesToRenderInEndEdtior) {
        this.countRenderedViews();
      }
    };
    const numberOfNonDisplayedView = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.values(nonDisplayedViewsWithIndexes).reduce((prev: number, curr: any[]) => {
      return (prev += curr.length);
    }, 0);
    if (numberOfNonDisplayedView == 0) {
      this.countRenderedViews();
    }
    Object.keys(nonDisplayedViewsWithIndexes).forEach((type) => {
      const nonDisplayedEditors = nonDisplayedViewsWithIndexes[type];
      if (nonDisplayedEditors.length > 0) {
        typesToRenderInEndEdtior++;
      }
      nonDisplayedEditors.sort((a, b) => a.elIndex - b.elIndex);

      this.addElementsInEndEditor(type, nonDisplayedEditors, countRendered, olderElements);
    });
    return citatsBySection;
  };

  addElementsInEndEditor(
    type: string,
    nonDisplayedEditors: {
      elId: string;
      elIndex: number;
    }[],
    countRendered: () => void,
    olderElements: {
      [key: string]: {
        [key: string]: {
          [key: string]: boolean;
        };
      };
    }
  ): void {
    const elType = type;
    const typeMapping = citationElementMap[ElementsTypeToCitationMap[elType]];
    const elementObjs = this.getElementsObj();
    const elementsTemplates = this.getElementsTemplates(
      type == 'figure' ? 'figuresTemplates' : 'tablesTemplates'
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderedViewsMetaData: any = [];
    let renderedViews = 0;
    const view = this.editors['endEditor']?.editorView;
    if (!view) return;
    const DOMPMParser = DOMParser.fromSchema(view.state.schema);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const displayViews = (data: any) => {
      const nodeStart: number = view.state.doc.nodeSize - 2;

      const elementNodes = data.renderedViewsMetaData.map((el) => {
        if (el.elType == 'table') {
          const node1 = view.state.schema.nodes['tables_nodes_container'].create(
            {},
            el.renderedData
          );

          return node1;
        } else {
          return el.renderedData;
        }
      });

      const endEditorPrefixNodes = typeMapping.getEndEditorPrefixNodes
        ? typeMapping.getEndEditorPrefixNodes(data.labelState)
        : [];

      const schema = view.state.schema as Schema;
      const container = schema.nodes[typeMapping.containerNodeName].create({}, [
        ...endEditorPrefixNodes,
        ...elementNodes,
      ]);

      view.dispatch(
        view.state.tr
          .insert(nodeStart, Fragment.from(container))
          .setMeta('citable-elements-rerender', true)
      );
      setTimeout(() => {
        countRendered();
      }, 0);
    };

    nonDisplayedEditors.forEach((el, i) => {
      const elId = el.elId;
      const elementData = elementObjs[elType][elId];
      const elementTemplate = elementsTemplates[elId];
      typeMapping.setSectionData(elementData, 'endEditor', 'endEditor');
      let serializedElementToFormIOsubmission;
      let elementFormGroup;

      if (elType == 'table') {
        serializedElementToFormIOsubmission =
          this.deletedTablesForRerender[elId] ||
          typeMapping.getElFormIOSubmission(elementData, 'endEditor', this.serviceShare);
        serializedElementToFormIOsubmission.tableNumber = elementData.tableNumber;
        serializedElementToFormIOsubmission.viewed_by_citat = 'endEditor';
        elementFormGroup = typeMapping.buildElementFormGroup(serializedElementToFormIOsubmission);
      } else {
        serializedElementToFormIOsubmission = typeMapping.getElFormIOSubmission(
          elementData,
          'endEditor'
        );
        elementFormGroup = typeMapping.buildElementFormGroup(serializedElementToFormIOsubmission);
      }

      if (olderElements) {
        serializedElementToFormIOsubmission[typeMapping.elementNumberProp] = i;
      }
      if (!elementTemplate?.html) return;
      this.serviceShare.ProsemirrorEditorsService.editMode = true;
      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        elementTemplate.html,
        serializedElementToFormIOsubmission,
        elementFormGroup
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ).then((data: any) => {
        let oldVersionAction: string;

        if (elType == 'figure') {
          if (elementData.canvasData?.nOfColumns) {
            data = data.replaceAll(
              'alt="image"',
              `alt="image" numberOfColumns="${elementData.canvasData.nOfColumns}"`
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          elementData.components.forEach((component: any) => {
            if (
              component.url.includes(this.config.CDNService) &&
              component.componentType == 'image'
            ) {
              const imageId = component.url.split('m/')[1];
              data = data.replace(
                'img-placeholder _ng',
                `img-placeholder class="image-placeholder" numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" imageId="${imageId}" imageSrc="${this.config.CDNService}" isFromCDN="true" _ng`
              );
              data = data.replace(
                'src=""',
                `src imageId="${imageId}" class="figure-image" imageSrc="${this.config.CDNService}" isFromCDN="true"`
              );
              data = data.replace(
                'class="figure-zoom"',
                `class="figure-zoom " src="${this.config.CDNService}/${imageId}"`
              );
              data = data.replace(
                'class="figure-download"',
                `class="figure-download " src="${this.config.CDNService}/${imageId}"`
              );
            } else if (
              component.componentType == 'image' &&
              !component.url.includes(this.config.CDNService)
            ) {
              data = data.replace(
                'img-placeholder _ng',
                `img-placeholder numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" class="image-placeholder" imageSrc="${component.url}" isFromCDN="false" _ng`
              );
              data = data.replace(
                'src=""',
                `src="${component.url}" class="figure-image" isFromCDN="false"`
              );
              data = data.replace(
                'class="figure-zoom"',
                `class="figure-zoom " src="${component.url}"`
              );
              data = data.replace(
                'class="figure-download"',
                `class="figure-download " src="${component.url}"`
              );
            }
          });
        }

        const templ = document.createElement('div');
        templ.innerHTML = data;

        const Slice = DOMPMParser.parse(templ.firstChild);

        renderedViews++;

        let labelState = undefined;

        renderedViewsMetaData[i] = {
          renderedData: Slice.content.firstChild,
          elementData,
          elType,
        };
        if (renderedViews == nonDisplayedEditors.length) {
          if (!olderElements) {
            displayViews({
              renderedViewsMetaData: renderedViewsMetaData.sort(
                (a, b) =>
                  a.elementData[typeMapping.elementNumberProp] -
                  b.elementData[typeMapping.elementNumberProp]
              ),
              citationType: type,
            });
          } else {
            displayViews({
              renderedViewsMetaData: renderedViewsMetaData.sort(
                (a, b) =>
                  a.elementData[typeMapping.elementNumberProp] -
                  b.elementData[typeMapping.elementNumberProp]
              ),
              citationType: type,
              labelState,
            });
          }
        }
      });
    });
  }

  updateElementsCitationsV2(): void {
    const citations = this.helperCitableElementsService.getElementsCitations(this.editors, true);
    this.updateCitatsText(citations);
    setTimeout(() => {
      this.serviceShare.YjsHistoryService.endBigOperationCapture();
    }, 10);
  }

  allViewAreRendered(): void {
    setTimeout(() => {
      if (this.updatingElementsAndElementsCitations) {
        this.updateElementsCitationsV2();
      } else {
        this.serviceShare.YjsHistoryService.endBigOperationCapture();
      }
      citationsPMNodeNames.forEach((citType) => {
        if (this.elementsFromPopupEdit[citType]) {
          this.elementsFromPopupEdit[citType] = undefined;
        }
      });
    }, 10);
  }

  countRenderedViews(): void {
    this.renderedViews++;
    if (this.renderedViews == 2) {
      if (this.subscription) {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
      }
      this.renderedViews = 0;
      this.allViewAreRendered();
    }
  }

  displayElements(
    citats: Citations,
    elsDisplayedInObj: { [key: string]: { [key: string]: string } },
    olderVersion?: { [key: string]: { [key: string]: { added?: boolean; removed?: boolean } } }
  ): void {
    if (this.subscription) {
      return;
    }
    const elementObjs = this.getElementsObj();
    let numberOfElsObj: number = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(elementObjs).forEach((type: any) => {
      const elsOfType = elementObjs[type];
      const nOfEls = Object.keys(elsOfType).filter((elId) => {
        return elsDisplayedInObj[type][elId] != 'endEditor';
      }).length;
      numberOfElsObj += nOfEls;
    });
    if (numberOfElsObj == 0 && !olderVersion) {
      this.countRenderedViews();
      return;
    }
    const doneEditing = new Subject<{
      renderedViewsMetaData: {
        renderedData: Node;
        elementData: { tableID: string };
        elType: string;
      }[];
      citationType: string;
      citatID: string;
      edView: EditorView;
    }>();
    Object.keys(citats).forEach((sectionId) => {
      const view = this.editors[sectionId].editorView;
      const citatsInEditor = citats[sectionId];
      Object.keys(citatsInEditor).forEach((citatId) => {
        const citat = citatsInEditor[citatId];
        const citatID = citatId;
        const editFigureContainer = (
          citatID: string,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dispatchSubject: Subject<any>,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          elementsViewsToDisplay: any[],
          edView: EditorView
        ) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const renderedViewsMetaData: any = [];
          let renderedViews = 0;
          elementsViewsToDisplay.forEach((displayedView, i) => {
            console.log(displayedView);

            const elementsTemplates = this.getElementsTemplates(
              displayedView.type == 'figure' ? 'figuresTemplates' : 'tablesTemplates'
            );
            const elType = displayedView.type;
            const elId = displayedView.elId;
            const elementData = elementObjs[elType][elId];
            const elementTemplate = elementsTemplates[elId];
            const typeMapping = citationElementMap[ElementsTypeToCitationMap[elType]];
            typeMapping.setSectionData(elementData, sectionId, citatID);

            let serializedElementToFormIOsubmission;
            let elementFormGroup;
            if (elType == 'table') {
              serializedElementToFormIOsubmission =
                this.deletedTablesForRerender[elId] ||
                typeMapping.getElFormIOSubmission(elementData, citatID, this.serviceShare);
              serializedElementToFormIOsubmission.tableNumber = elementData.tableNumber;
              serializedElementToFormIOsubmission.viewed_by_citat = citatID;
              elementFormGroup = typeMapping.buildElementFormGroup(
                serializedElementToFormIOsubmission
              );
            } else {
              serializedElementToFormIOsubmission = typeMapping.getElFormIOSubmission(
                elementData,
                citatID
              );
              elementFormGroup = typeMapping.buildElementFormGroup(
                serializedElementToFormIOsubmission
              );
            }

            if (!elementTemplate?.html) return;
            this.serviceShare.ProsemirrorEditorsService.editMode = true;
            this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
              elementTemplate.html,
              serializedElementToFormIOsubmission,
              elementFormGroup
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ).then((data: any) => {
              let oldVersionAction: string;

              if (elType == 'figure') {
                if (elementData.canvasData?.nOfColumns) {
                  data = data.replaceAll(
                    'alt="image"',
                    `alt="image" numberOfColumns="${elementData.canvasData.nOfColumns}"`
                  );
                }
                elementData.components.forEach((component: FigureComponent) => {
                  if (
                    component.url.includes(this.config.CDNService) &&
                    component.componentType == 'image'
                  ) {
                    const imageId = component.url.split('m/')[1];
                    data = data.replace(
                      'img-placeholder _ng',
                      `img-placeholder class="image-placeholder" numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" src imageId="${imageId}" imageSrc="${this.config.CDNService}" isFromCDN="true" _ng`
                    );
                    data = data.replace(
                      'src=""',
                      `src imageId="${imageId}" class="figure-image" imageSrc="${this.config.CDNService}" isFromCDN="true"`
                    );
                    data = data.replace(
                      'class="figure-zoom"',
                      `class="figure-zoom " src="${this.config.CDNService}/${imageId}"`
                    );
                    data = data.replace(
                      'class="figure-download"',
                      `class="figure-download " src="${this.config.CDNService}/${imageId}"`
                    );
                  } else if (
                    component.componentType == 'image' &&
                    !component.url.includes(this.config.CDNService)
                  ) {
                    data = data.replace(
                      'img-placeholder _ng',
                      `img-placeholder numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" class="image-placeholder" imageSrc="${component.url}" isFromCDN="false" _ng`
                    );
                    data = data.replace(
                      'src=""',
                      `src="${component.url}" class="figure-image" isFromCDN="false"`
                    );
                    data = data.replace(
                      'class="figure-zoom"',
                      `class="figure-zoom " src="${component.url}"`
                    );
                    data = data.replace(
                      'class="figure-download"',
                      `class="figure-download " src="${component.url}"`
                    );
                  }
                });
              }

              const templ = document.createElement('div');
              templ.innerHTML = data;
              const DOMParserFormCurrSection = DOMParser.fromSchema(view.state.schema);
              const Slice = DOMParserFormCurrSection.parse(templ.firstChild!);

              renderedViews++;

              if (olderVersion) {
                if (olderVersion[elType][elId]?.added) {
                  const userColor = this.serviceShare.ProsemirrorEditorsService.permanentUserData
                    .getUserByClientId(serializedElementToFormIOsubmission.clientID)
                    .split('&')[1];
                  oldVersionAction = `border: 2px solid ${userColor}; padding: 3px; border-radius: 3px; display: block;`;
                  //@ts-expect-error 'attrs' may not have a defined index signature for 'styling'
                  Slice.content.firstChild.attrs['styling'] = oldVersionAction;
                  //@ts-expect-error 'attrs' may not have a defined index signature for 'class'
                  Slice.content.firstChild.attrs['class'] = 'insertion';
                } else if (olderVersion[elType][elId]?.removed) {
                  oldVersionAction = 'citable-element-removed deletion';
                  //@ts-expect-error 'attrs' may not have a defined index signature for 'class'
                  Slice.content.firstChild.attrs['class'] = oldVersionAction;
                }
              }

              renderedViewsMetaData[i] = {
                renderedData: Slice.content.firstChild,
                elementData,
                elType,
              };
              if (renderedViews == elementsViewsToDisplay.length) {
                dispatchSubject.next({
                  renderedViewsMetaData: renderedViewsMetaData.sort(
                    (a, b) =>
                      a.elementData[typeMapping.elementNumberProp] -
                      b.elementData[typeMapping.elementNumberProp]
                  ),
                  citationType: ElementsTypeToCitationMap[citat.citationType],
                  citatID,
                  edView,
                });
              }
            });
          });
        };
        if (citat.displayedViewsHere.length > 0) {
          editFigureContainer(citatID, doneEditing, citat.displayedViewsHere, view);
        }
      });
    });
    let rendered = 0;
    const checkRendered = (nOnRenderedItems: number) => {
      rendered += nOnRenderedItems;

      if (rendered == numberOfElsObj) {
        this.countRenderedViews();
        this.subscription?.unsubscribe();
        this.subscription = undefined;
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.subscription = doneEditing.subscribe((data: any) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let citatNewPosition: any;
        const wrappingNodes = [
          'paragraph',
          'heading',
          'table',
          'code_block',
          'ordered_list',
          'bullet_list',
          'math_inline',
          'math_display',
        ];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let resolvedPositionOfCitat: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let posAtParentBorder: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let resolvedPositionATparentNodeBorder: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let contAfter: any;
        const updateMetaInfo = () => {
          const docSize = data.edView.state.doc.nodeSize;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.edView.state.doc.nodesBetween(0, docSize - 2, (node: any, pos: any) => {
            const marks = node.marks.filter((mark: Mark) => {
              return mark.type.name == data.citationType;
            });
            if (marks.length > 0 && marks[0].attrs.citateid == data.citatID) {
              citatNewPosition = pos;
            }
          });
          if (!citatNewPosition) {
            return;
          }
          resolvedPositionOfCitat = data.edView.state.doc.resolve(citatNewPosition);
          const resolvedCitationPath: Array<Node | number> = resolvedPositionOfCitat.path;
          let offsetOfwrappingParent: number;
          let wrappingParent;

          if (!wrappingParent) {
            for (let i = resolvedCitationPath.length - 1; i > -1; i--) {
              const el = resolvedCitationPath[i];
              if (el instanceof Node) {
                if (wrappingNodes.includes(el.type.name)) {
                  offsetOfwrappingParent = resolvedCitationPath[i - 1] as number;
                  wrappingParent = el;
                }
              }
            }
          }

          posAtParentBorder = offsetOfwrappingParent! + wrappingParent?.nodeSize;
          resolvedPositionATparentNodeBorder = data.edView.state.doc.resolve(posAtParentBorder);
          contAfter = data.edView.state.doc.nodeAt(posAtParentBorder)!;

          contAfter = resolvedPositionATparentNodeBorder.nodeAfter;
          while (
            contAfter &&
            [...elementsContainersPMNodesNames, 'example'].includes(contAfter.type.name)
          ) {
            posAtParentBorder = posAtParentBorder + contAfter.nodeSize;
            resolvedPositionATparentNodeBorder = data.edView.state.doc.resolve(posAtParentBorder);
            contAfter = resolvedPositionATparentNodeBorder.nodeAfter;
          }
        };

        updateMetaInfo();
        if (!resolvedPositionATparentNodeBorder) {
          return;
        }
        const viesNodes: Node[] = [];
        data.renderedViewsMetaData.forEach((renderedViewData) => {
          const type = renderedViewData.elType;
          const node = renderedViewData.renderedData;
          const containerNodeName =
            citationElementMap[ElementsTypeToCitationMap[type]].containerNodeName;
          if (containerNodeName == 'tables_nodes_container') {
            const node1 = data.edView.state.schema.nodes[containerNodeName].create({}, node);
            viesNodes.push(node1);
          } else {
            const containerNode = data.edView.state.schema.nodes[containerNodeName].create(
              {},
              node
            );
            viesNodes.push(containerNode);
          }
        });
        const viesNodesFragment = Fragment.from(viesNodes);
        data.edView.dispatch(
          data.edView.state.tr
            .insert(resolvedPositionATparentNodeBorder.pos, viesNodesFragment)
            .setMeta('citable-elements-rerender', true)
        );
        setTimeout(() => {
          checkRendered(data.renderedViewsMetaData.length);
        }, 0);
      } catch (e) {
        console.error(e);
      }
    });
  }

  updateElementsAndElementsCitations(): void {
    let olderElements = {};
    this.updatingElementsAndElementsCitations = true;
    const citations = this.helperCitableElementsService.getElementsCitations(this.editors);
    this.updateCitatsText(citations);
    const elsDisplayedInObj = {};
    const elementsObj = this.getElementsObj();

    Object.keys(elementsObj).forEach((elType: string) => {
      elsDisplayedInObj[elType] = {};
      Object.keys(elementsObj[elType]).forEach((elId: string) => {
        if (elType !== 'end-note' && elType !== 'supplementary-file') {
          elsDisplayedInObj[elType][elId] = 'endEditor';
        }
      });
    });
    olderElements = undefined;

    const newCitatsObj = this.markElementsCitatsViews(citations, elsDisplayedInObj, olderElements);
    if (
      !olderElements &&
      this.serviceShare.compareObjects(
        this.serviceShare.YdocService.citableElementsMap.get('elementsCitations'),
        newCitatsObj
      )
    ) {
      this.serviceShare.YdocService.citableElementsMap.set('elementsCitations', newCitatsObj);
    }
    this.displayElements(newCitatsObj, elsDisplayedInObj, olderElements);
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 2000);
  }

  addSupplementaryFiles() {
    const supplementaryFilesTemplate = this.supplFileTemplates[
      Object.keys(this.supplementaryFiles)[0]
    ] as ElementHtmlEntry;
    if (supplementaryFilesTemplate) {
      const supplementaryFilesFormGroup =
        citationElementMap.supplementary_file_citation.buildElementFormGroup(
          Object.values(this.supplementaryFiles)
        );

      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        supplementaryFilesTemplate.html,
        Object.values(this.supplementaryFiles).sort(
          (a: SupplementaryFile, b: SupplementaryFile) =>
            a.supplementary_file_number - b.supplementary_file_number
        ),
        supplementaryFilesFormGroup
      ).then((result: string) => {
        const templ = document.createElement('div');
        templ.innerHTML = result;
        const Slice = DOMParser.fromSchema(schema).parse(templ.firstChild);
        this.editors['supplementaryFilesView'].editorView.dispatch(
          this.editors['supplementaryFilesView'].editorView.state.tr.replaceWith(0, 0, Slice)
        );
      });
    }
  }

  addEndNotes() {
    const endNotesTemplate =
      this.serviceShare.YdocService.endNotesMap!.get('endNotesInitialTemplate');

    if (endNotesTemplate) {
      const endNoteFormGroup = citationElementMap.end_note_citation.buildElementFormGroup(
        Object.values(this.endNotes as { [key: string]: EndNote })
      );

      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        endNotesTemplate,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(this.endNotes).sort(
          (a: any, b: any) => a.end_note_number - b.end_note_number
        ),
        endNoteFormGroup
      ).then((result: string) => {
        const templ = document.createElement('div');
        templ.innerHTML = result;
        const Slice = DOMParser.fromSchema(schema).parse(templ.firstChild);
        this.editors['EndNotesView'].editorView.dispatch(
          this.editors['EndNotesView'].editorView.state.tr.replaceWith(0, 0, Slice)
        );
      });
    }
  }
}
