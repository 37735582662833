import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EndNote } from '@app/editor/services/citable-elements.models';

@Component({
  selector: 'app-end-note',
  templateUrl: './end-note.component.html',
  styleUrls: ['./end-note.component.scss'],
})
export class EndNoteComponent implements AfterViewInit {
  @Input() endNote?: EndNote;
  @Output() endNoteChange = new EventEmitter<EndNote>();
  @Input() endNoteIndex?: number;

  urlSafe?: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {}
}
