import { EditMode, EditModePermissions } from '@app/editor/services/edit-mode/models';
import { createAction, props } from '@ngrx/store';

export const initializeEditMode = createAction(
  '[Edit Mode] Initialize',
  props<{
    userSettings?: {
      editMode?: string;
    };
  }>()
);

export const setEditMode = createAction(
  '[Edit Mode] Set Mode',
  props<{
    mode: EditMode;
  }>()
);

export const updatePermissions = createAction(
  '[Edit Mode] Update Permissions',
  props<{
    permissions: EditModePermissions;
    source?: 'user' | 'system';
  }>()
);

export const toggleEditMode = createAction('[Edit Mode] Toggle Mode');
