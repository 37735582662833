import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';

import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { schema } from '@app/editor/utils/Schema';
import { JatsCitableElementsService } from '../../jats-citable-elements.service';
import { Figure } from '@app/editor/services/citable-elements.models';

@Component({
  selector: 'app-jats-figures-prosemirror-view',
  templateUrl: './jats-figures-prosemirror-view.component.html',
  styleUrls: ['./jats-figures-prosemirror-view.component.scss'],
})
export class JatsFiguresProsemirrorViewComponent implements AfterViewInit {
  @ViewChild('ProsemirrorEditor', { read: ElementRef }) ProsemirrorEditor?: ElementRef;
  @Input() figures!: Figure[];

  constructor(
    private prosemirrEditorsService: ProsemirrorEditorsService,
    private jatsCitableElementsService: JatsCitableElementsService
  ) {}

  ngAfterViewInit(): void {
    this.renderEndEditor();
    setTimeout(() => {
      this.jatsCitableElementsService.updateElementsAndElementsCitations();
    }, 1000);
  }

  renderEndEditor = () => {
    this.jatsCitableElementsService.editors['endEditor'] =
      this.prosemirrEditorsService.renderPreviewEditor(
        this.ProsemirrorEditor?.nativeElement,
        schema.nodes.doc.create()
      );
  };
}
