import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorService } from './services/error-service/error.service';
import { ErrorFactoryService } from './error-factory/error-factory.service';
import { ErrorPublishingService } from './services/error-service/error-publishing.service';
import {
  DEFAULT_ERROR_HANDLER_CONFIG,
  ERROR_HANDLER_CONFIG,
} from './global-error-handler/error-handler.config';
import { ERROR_PUBLISHER } from './services/error-service/error-publisher.token';
import { ErrorStorageService } from './services/error-storage.service';
import { RETRY_CONFIG } from './services/retry-service/retry.service';
import { DEFAULT_GLOBAL_RETRY_CONFIG } from './services/retry-service/retry.service.config';

export function initializeErrorStorage(errorStorage: ErrorStorageService) {
  return () => errorStorage.init();
}

@NgModule({
  imports: [CommonModule],
  providers: [
    ErrorService,
    ErrorFactoryService,
    ErrorPublishingService,
    ErrorStorageService,
    {
      provide: ERROR_HANDLER_CONFIG,
      useValue: DEFAULT_ERROR_HANDLER_CONFIG,
    },
    {
      provide: ERROR_PUBLISHER,
      useExisting: ErrorPublishingService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeErrorStorage,
      deps: [ErrorStorageService],
      multi: true,
    },
    {
      provide: RETRY_CONFIG,
      useValue: DEFAULT_GLOBAL_RETRY_CONFIG,
    },
  ],
})
export class ErrorHandlingModule {}
