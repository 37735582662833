import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as PModel from 'prosemirror-model';
import { uuidv4 } from 'lib0/random';
import { Observable, Subscription, combineLatest } from 'rxjs';

import { schema } from '@app/editor/utils/Schema';
import { ArticleSection, basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { generateNewReference } from '@app/layout/pages/library/lib-service/refs-funcs';
import { harvardStyle } from '@app/layout/pages/library/lib-service/csl.service';
import { figuresHtmlTemplate } from '../../figures-dialog/add-figure-dialog-v2/add-figure-dialog-v2.component';
import {
  filterFieldsValues,
  parseSecFormIOJSONMenuAndSchemaDefs,
  parseSecHTMLMenuAndSchemaDefs,
} from '@app/editor/utils/fieldsMenusAndScemasFns';
import { material } from '@app/core/services/custom_sections/material';
import { CiToTypes } from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { getHtmlFromFragment } from '@app/editor/utils/prosemirrorHelpers';
import { DataPaperService } from './data-paper.service';
import { AllUsersService, authorListData, roleMapping } from '@app/core/services/all-users.service';
import { FormBuilderService } from '@app/editor/services/form-builder.service';
import { RefsApiService } from '@app/layout/pages/library/lib-service/refs-api.service';
import {
  ArticleCollaborator,
  ReferenceCitation,
  Section,
  SectionMappings,
} from '@app/core/models/article.models';
import {
  generalSec,
  ImportWizardDialogData,
  JatsSection,
  RedundantTextMap,
  SectionContent,
} from './jats.models';
import { JatsCitableElementsService } from './jats-citable-elements.service';
import {
  citationElementMap,
  ElementHtmlEntry,
  Figure,
  SupplementaryFile,
} from '@app/editor/services/citable-elements.models';
import { renderSectionFunc } from '@app/editor/utils/articleBasicStructure';

@Injectable({
  providedIn: 'root',
})
export class ImportJatsService {
  sections: basicArticleSection[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DOMPMSerializer = PModel.DOMSerializer.fromSchema(schema);

  allJatsSections: JatsSection[] = [];
  sectionsContent: SectionContent[] = [];

  refsObj: { [key: string]: any } = {};
  referenceCitations: { [key: string]: ReferenceCitation } = {};
  referencesThatShouldBeFetched: { obs: Observable<unknown>; doi?: string; refText?: string }[] =
    [];

  titleTags = ['title', 'label'];

  citationLayoutControl = new UntypedFormControl('No brackets');
  selectedLayoutOption = 'No brackets';

  layoutOptions = [];

  sectionMappings: SectionMappings;
  generalSectionName: string;
  generalSection: ArticleSection;

  isChanging = true;
  subscription = new Subscription();

  doc: Document;

  constructor(
    private serviceShare: ServiceShare,
    private refsAPI: RefsApiService,
    private formBuilderService: FormBuilderService,
    private httpClient: HttpClient,
    private dataPaperService: DataPaperService,
    private allUsersService: AllUsersService,
    private jatsCitableElementsService: JatsCitableElementsService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  parseXML(doc: Document): Promise<ImportWizardDialogData> {
    return new Promise<ImportWizardDialogData>((resolve) => {
      this.doc = doc;

      this.jatsCitableElementsService.editors = {};
      this.sectionMappings =
        this.serviceShare.YdocService.articleData.layout.settings.section_mapping;
      this.generalSectionName =
        this.serviceShare.YdocService.articleData.layout.settings.general_section;

      const citationOptions =
        this.serviceShare.YdocService.articleData.layout.settings['allowed_tags']
          ?.cslCitationOptions?.layoutOptions;
      this.subscription.add(
        this.citationLayoutControl.valueChanges.subscribe((value: string) => {
          this.selectedLayoutOption = value;
        })
      );

      if (citationOptions) {
        Object.keys(citationOptions).forEach((key: string) => {
          this.layoutOptions.push({ name: key, layout: citationOptions[key] });
        });
      }

      this.taxonData = {};
      this.materialsData = [];
      this.allJatsSections = [];
      this.sectionsContent = [];
      this.allCollaborators = [];
      this.allAuthors = [];

      this.sections = this.serviceShare.YdocService.articleStructureMap.get(
        'articleSectionsStructure'
      ) as basicArticleSection[];

      this.jatsCitableElementsService.articleSections =
        this.serviceShare.YdocService.articleStructureMap
          .get('articleSectionsStructureFlat')
          .map((secID: string) => this.serviceShare.YdocService.getSectionByID(secID))
          .filter((sec: ArticleSection) => sec?.title?.name != '[AM] Funder');

      const generalSection = this.jatsCitableElementsService.articleSections.find(
        (section) =>
          section?.title?.name == this.generalSectionName ||
          section?.title?.name == '[PS] General Section'
      );

      if (generalSection) {
        this.generalSection = JSON.parse(JSON.stringify(generalSection)) as ArticleSection;
      } else {
        const backendGeneralSection =
          this.serviceShare.YdocService.articleData.layout.template.sections.find(
            (s) => s.name == this.generalSectionName
          );

        if (backendGeneralSection) {
          this.generalSection = JSON.parse(
            JSON.stringify(this.renderGeneralSection(backendGeneralSection))
          ) as ArticleSection;
        } else {
          this.generalSection = JSON.parse(JSON.stringify(generalSec));
        }
      }

      const body = doc.querySelector('body');
      const back = doc.querySelector('back');
      const floatsGroup = doc.querySelector('floats-group');

      const children = floatsGroup ? Array.from(floatsGroup.children) : [];

      const floats = children.filter(
        (child) => child.nodeName === 'fig' || child.tagName === 'fig-group'
      );

      const tables = floatsGroup ? floatsGroup.querySelectorAll('table-wrap') : null; // get tables from floatsGroup, because some sections' content is displayed as table
      const supplementaryFiles = floatsGroup?.querySelectorAll('supplementary-material');

      if (floats.length) {
        this.parseFigures(floats);
      }
      if (tables) {
        this.parseTables(Array.from(tables));
      }
      if (supplementaryFiles) {
        this.parseSupplementaryMaterials(Array.from(supplementaryFiles));
      }

      this.addContentToFigures();
      this.addContentToTables();
      this.addContentToSupplementaryMaterials();

      const parseFunc = (): void => {
        setTimeout(() => {
          this.processMappedSections(doc);

          if (body) {
            this.parseBody(body);
          }

          if (back) {
            this.parseBack(back);
          }
          setTimeout(() => {
            this.getAllContributors();

            resolve({
              sectionsContent: this.sectionsContent,
              allJatsSections: this.allJatsSections,
              allAuthors: this.allAuthors,
              allCollaborators: this.allCollaborators,
            });
          }, 5000);
        }, 100);
      };

      if (back) {
        // this.parseBack(back);

        if (this.referencesThatShouldBeFetched.length > 0) {
          combineLatest(this.referencesThatShouldBeFetched.map((ref) => ref.obs)).subscribe({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            next: (response: any[]) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              response.forEach((r: any, i: number) => {
                if (this.referencesThatShouldBeFetched[i].doi) {
                  const parsedJson = JSON.parse(r);
                  // eslint-disable-next-line @tspellcheck/spell-checker
                  if (parsedJson.mapedReferences.length > 0) {
                    // eslint-disable-next-line @tspellcheck/spell-checker
                    const ref = parsedJson.mapedReferences.find(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (ref: any) => ref.ref['DOI'] == this.referencesThatShouldBeFetched[i].doi
                    );
                    if (ref) {
                      this.parseRefFromRefindid(ref);
                    } else {
                      const text = this.referencesThatShouldBeFetched[i].refText;
                      this.refsAPI.parseReferenceFromPlainText(text).subscribe((res) => {
                        if (res) {
                          this.initializeReferenceWithStyleAndCitation(res.type, res.id, res);
                        } else {
                          // If we don't manage to import the reference by doi or with parse service,
                          // create an empty ref object with the reference text as title
                          // so the user can edit it manually
                          this.initializeReferenceWithStyleAndCitation('article', uuidv4(), {
                            title: text,
                          });
                        }
                      });
                    }
                  }
                } else {
                  // ref object from parse service
                  if (r) {
                    this.initializeReferenceWithStyleAndCitation(r.type, r.id, r);
                  } else {
                    const text = this.referencesThatShouldBeFetched[i].refText;
                    this.initializeReferenceWithStyleAndCitation('article', uuidv4(), {
                      title: text,
                    });
                  }
                }
              });
            },
            error: (err) => {
              console.error(err);
            },
            complete: () => {
              parseFunc();
            },
          });
        } else {
          parseFunc();
        }
      } else {
        parseFunc();
      }
    });
  }

  processMappedSections(xmlDoc: Document) {
    const keys = Object.keys(this.sectionMappings);
    for (const selector of keys) {
      const originalSectionName = this.sectionMappings[selector];
      const nodes = xmlDoc.querySelectorAll(selector);

      nodes.forEach((node) => {
        const articleSection = this.jatsCitableElementsService.articleSections.find(
          (sec) => sec?.title?.name == originalSectionName
        );

        if (originalSectionName == '[AM] Title' && articleSection) {
          this.allJatsSections.push({
            title: node.textContent.replace(/<[^>]*>/g, '').trim(),
            section: node,
            sectionID: articleSection.sectionID,
          });
          this.sectionsContent.push({
            parsedSecTitle: getHtmlFromFragment(
              PModel.Fragment.fromArray(this.parseSectionContent(node)),
              this.serviceShare.DOMPMSerializer
            ),
            level: 1,
            doc: null,
            originalSectionName,
            sectionID: articleSection.sectionID,
            originalSecID: articleSection.sectionID,
            originalSection: articleSection,
          });
        } else if (articleSection) {
          const sectionID = uuidv4();

          this.allJatsSections.push({
            title: node.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
            section: node,
            sectionID,
          });
          this.sectionsContent.push({
            parsedSecTitle: this.titleTags.includes(node.children[0].tagName)
              ? node.children[0].textContent.replace(/<[^>]*>/g, '').trim()
              : articleSection?.title?.label,
            level: 2,
            doc: null,
            originalSectionName,
            sectionID,
            originalSecID: articleSection.sectionID,
            originalSection: articleSection,
          });
        } else if (this.generalSection) {
          this.addGeneralSection(node, 2);
        }
      });
    }
  }

  // parseFront(front: Element): void {
  //   const articleTitle = front.querySelector('title-group > article-title');
  //   const abstract = front.querySelector('abstract');
  //   const keywords = front.querySelector('kwd-group');
  //   const notes = front.querySelector('notes');

  //   const titleSec = this.articleSections.find(
  //     (sec) => sec.title.name == this.sectionMappings?.title || sec.title.name == '[AM] Title'
  //   );
  //   const abstractSec = this.articleSections.find(
  //     (sec) => sec.title.name == this.sectionMappings?.abstract || sec.title.name == '[AM] Abstract'
  //   );
  //   const keywordsSec = this.articleSections.find(
  //     (sec) => sec.title.name == this.sectionMappings?.keywords || sec.title.name == '[AM] Keywords'
  //   );

  //   if (articleTitle && articleTitle.innerHTML && titleSec) {
  //     const originalContent = getHtmlFromFragment(
  //       this.serviceShare.ProsemirrorEditorsService.editorContainers[titleSec.sectionID].editorView
  //         .state.doc.content,
  //       this.serviceShare.DOMPMSerializer
  //     );

  //     this.allJatsSections.push({
  //       title: originalContent,
  //       section: articleTitle,
  //       secID: titleSec.sectionID,
  //     });

  //     this.sectionsContent[titleSec.sectionID] = {
  //       sectionTitle: originalContent,
  //       doc: null,
  //       parsedSecTitle: getHtmlFromFragment(
  //         PModel.Fragment.fromArray(this.parseSectionContent(articleTitle)),
  //         this.serviceShare.DOMPMSerializer
  //       ),
  //       level: 1,
  //       secID: titleSec.sectionID,
  //     };
  //   }

  //   if (abstract && abstractSec) {
  //     this.iterateSections(abstractSec, abstract, 2);
  //   }

  //   if (keywords && keywordsSec) {
  //     this.allJatsSections.push({
  //       title: keywords.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
  //       section: keywords,
  //       secID: keywordsSec.sectionID,
  //     });
  //     this.sectionsContent[keywordsSec.sectionID] = {
  //       sectionTitle: keywordsSec.title.label,
  //       doc: null,
  //       parsedSecTitle: keywords.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
  //       level: 2,
  //       secID: keywordsSec.sectionID,
  //     };
  //   }

  //   if (notes) {
  //     Array.from(notes.children).forEach((child) => {
  //       let section: ArticleSection;
  //       let isChildSec: boolean;
  //       const findSection = (
  //         sections: basicArticleSection[],
  //         searched: string,
  //         isChild: boolean
  //       ): void => {
  //         sections.forEach((sec) => {
  //           if (sec.name.includes(searched) || sec.label.includes(searched)) {
  //             section = this.serviceShare.YdocService.getSectionByID(sec.sectionID);
  //             isChildSec = isChild;
  //           }
  //           if (!section) {
  //             findSection(sec.children, searched, true);
  //           }
  //         });
  //       };
  //       findSection(this.sections, child.children[0].textContent.trim(), false);

  //       if (section) {
  //         const hTag = isChildSec ? 3 : 2;
  //         this.iterateSections(section, child, hTag);
  //       }
  //     });
  //   }
  // }

  parseBody(body: Element): void {
    Array.from(body.children).forEach((child) => {
      const articleSection = this.jatsCitableElementsService.articleSections.find(
        (sec) =>
          sec?.title?.label.includes(child.children[0].textContent.trim()) ||
          sec?.title?.name.includes(child.attributes.getNamedItem('sec-type')?.value?.trim())
      );

      if (articleSection && articleSection?.title.name == '[PS] Data resources') {
        this.parseDataResources(articleSection, child);
      } else if (articleSection && articleSection?.title.name == '[PS] Collection data') {
        this.parseCollectionData(articleSection, child);
      } else if (this.generalSection) {
        this.addGeneralSection(
          child,
          2,
          undefined,
          JSON.parse(JSON.stringify(this.generalSection))
        );
      }
    });
  }

  addGeneralSection(
    el: Element,
    level: number,
    sectionContent?: SectionContent,
    articleSection?: ArticleSection
  ): void {
    let secContent: SectionContent;

    if (sectionContent) {
      const sectionID = uuidv4();
      this.allJatsSections.push({
        title: el.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
        section: el,
        sectionID,
      });

      const parsedSecTitle = this.titleTags.includes(el.children[0].tagName)
        ? el.children[0].textContent.replace(/<[^>]*>/g, '').trim()
        : this.generalSection?.title.label;

      sectionContent.subsections.push({
        doc: null,
        parsedSecTitle,
        level: level,
        originalSectionName: this.generalSectionName,
        sectionID,
        originalSecID: '',
        originalSection: articleSection,
      });
    } else {
      const sectionID = uuidv4();

      this.allJatsSections.push({
        title: el.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
        section: el,
        sectionID,
      });

      const parsedSecTitle = this.titleTags.includes(el.children[0].tagName)
        ? el.children[0].textContent.replace(/<[^>]*>/g, '').trim()
        : this.generalSection?.title.label;

      secContent = {
        doc: null,
        parsedSecTitle,
        level: 1,
        originalSectionName: this.generalSectionName,
        subsections: [],
        sectionID,
        originalSecID: '',
        originalSection: articleSection,
      };

      this.sectionsContent.push(secContent);
    }

    Array.from(el.children).forEach((ch) => {
      if (ch.nodeName == 'sec') {
        this.addGeneralSection(ch, level + 1, secContent);
      } else if (ch.nodeName == 'tp:taxon-treatment') {
        const taxonSection = this.generalSection.compatibility_extended.find(
          (s) => s.name == 'Taxon'
        );

        if (taxonSection) {
          this.serviceShare.ArticleSectionsService.getSectionById(
            taxonSection.id
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ).subscribe((res: any) => {
            const section = renderSectionFunc(
              res.data,
              articleSection.children,
              this.serviceShare.YdocService.ydoc,
              this.serviceShare,
              'end'
            );

            const taxonSecContent: SectionContent = {
              doc: null,
              parsedSecTitle: 'Taxon',
              level: 1,
              originalSectionName: this.generalSectionName,
              subsections: [],
              sectionID: section.sectionID,
              originalSecID: '',
              originalSection: section,
            };

            secContent.subsections.push(taxonSecContent);

            this.parseTaxonTreatments(section, ch, taxonSecContent);
          });
        }
      }
    });
  }

  parseBack(back: Element): void {
    this.jatsCitableElementsService.endNotesNumbers = JSON.parse(
      JSON.stringify(this.serviceShare.YdocService.endNotesMap.get('endNotesNumbers') || [])
    );
    this.jatsCitableElementsService.endNotes = JSON.parse(
      JSON.stringify(this.serviceShare.YdocService.endNotesMap.get('endNotes') || {})
    );

    Array.from(back.children).forEach((child) => {
      const isSection = child.nodeName == 'sec' || child.nodeName == 'ack';
      if (isSection && this.generalSection) {
        this.addGeneralSection(child, 2);
      } else if (child.nodeName == 'ref-list') {
        Array.from(child.children).forEach((el: Element) => {
          const personGroups = Array.from(el.querySelectorAll('person-group'));
          this.parseReferences(el, personGroups);
        });
        this.refsObj = this.serviceShare.CslService.sortCitations(this.refsObj);
      } else if (child.nodeName == 'fn-group') {
        const footNotes = child.querySelectorAll('fn');
        Array.from(footNotes).forEach((el, i) => {
          const jatsID = el.id;
          Array.from(el.children).forEach((ch) => {
            if (ch.nodeName == 'p') {
              const description = ch?.childNodes
                ? Array.from(ch.childNodes)
                    .map((c) => this.createProsemirrorNodes(c.nodeName, c.textContent, c))
                    .flat()
                : [];
              const content =
                description.length > 0
                  ? getHtmlFromFragment(PModel.Fragment.from(description), this.DOMPMSerializer)
                  : '';
              const endNote = {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                jats_id: jatsID,
                endNote: content,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                end_note_number: this.jatsCitableElementsService.endNotesNumbers.length + i,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                end_note_ID: uuidv4(),
              };
              this.jatsCitableElementsService.endNotes[endNote.end_note_ID] = endNote;
              this.jatsCitableElementsService.endNotesNumbers.push(endNote.end_note_ID);
            }
          });
        });
      }
    });
  }

  createProsemirrorNodes(
    elType: string,
    content: string,
    element: ChildNode,
    isPreview?: boolean,
    isFirst?: boolean
  ): PModel.Node[] {
    const space = isFirst ? undefined : schema.text(' ');

    if (elType != 'kwd' && !content.includes(',')) {
      content = content.trim();
    }
    if (content == '') {
      return [];
    }

    switch (elType) {
      case '#text': {
        return [schema.text(content)];
      }
      case 'bold': {
        return [
          space,
          schema.text(content, [
            schema.marks.strong.create({}),
            ...Array.from(element.childNodes)
              .map((ch) => this.createProsemirrorMark(ch.nodeName, ch))
              .filter((m) => m != undefined && m?.type.name == 'text'),
          ]),
        ];
      }
      case 'sup': {
        return [
          space,
          schema.text(content, [
            schema.marks.superscript.create({}),
            ...Array.from(element.childNodes)
              .map((ch) => this.createProsemirrorMark(ch.nodeName, ch))
              .filter((m) => m != undefined && m?.type.name == 'text'),
          ]),
          schema.text(' '),
        ];
      }
      case 'ext-link': {
        //@ts-expect-error 'attributes' may not be defined on 'element'
        const attrs = element.attributes;
        const href = attrs.getNamedItem('xlink:href')?.value || '';

        return [
          space,
          schema.text(content, [
            schema.marks.link.create({
              href,
              title: '',
            }),
            ...Array.from(element.childNodes)
              .map((ch) => this.createProsemirrorMark(ch.nodeName, ch))
              .filter((m) => m != undefined && m?.type.name == 'text'),
          ]),
          schema.text(' '),
        ];
      }
      case 'italic': {
        return [
          space,
          schema.text(content, [
            schema.marks.em.create({}),
            ...Array.from(element.childNodes)
              .map((ch) => this.createProsemirrorMark(ch.nodeName, ch))
              .filter((m) => m != undefined && m?.type.name == 'text'),
          ]),
          schema.text(' '),
        ];
      }
      case 'tp:taxon-name': {
        return isPreview
          ? [
              space,
              schema.text(content, [
                // eslint-disable-next-line @tspellcheck/spell-checker
                schema.marks.taxon.create({ removedtaxon: false, taxmarkid: uuidv4() }),
                ...Array.from(element.childNodes)
                  .map((ch) => this.createProsemirrorMark(ch.nodeName, ch, isPreview))
                  .filter((m) => m != undefined && m?.type.name == 'text'),
              ]),
              schema.text(' '),
            ]
          : [schema.text(content)];
      }
      case 'xref': {
        //@ts-expect-error 'attributes' may not be defined on 'element'
        const type = element.attributes.getNamedItem('ref-type')?.value;

        //@ts-expect-error 'attributes' may not be defined on 'element'
        const numbers = element.attributes.getNamedItem('rid')?.value?.split(',');

        if (type == 'fig') {
          const figures = this.jatsCitableElementsService.figures;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const citated_elements = [];
          const indexes = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries(figures as { [id: string]: any }).forEach(([id, value], index) => {
            if (numbers.includes(value.jats_id)) {
              citated_elements.push(id);
              indexes.push(index + 1);
            }
          });

          if (citated_elements.length == 0) return [schema.text(content)];
          const citationText = this.generateCitationText(
            citated_elements,
            element as Element,
            indexes
          );

          return [
            schema.text(citationText, [
              schema.mark('citation', {
                isFromImport: true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                citated_elements,
                citateid: uuidv4(),
              }),
            ]),
          ];
        } else if (type == 'table') {
          const tables = this.jatsCitableElementsService.tables;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const citated_elements = [];
          const indexes = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries(tables as { [id: string]: any }).forEach(([id, value], index) => {
            if (numbers.includes(value.jats_id)) {
              citated_elements.push(id);
              indexes.push(index + 1);
            }
          });

          if (citated_elements.length == 0) return [schema.text(content)];

          const citationText = this.generateCitationText(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            citated_elements,
            element as Element,
            indexes
          );
          return [
            schema.text(citationText, [
              schema.mark('table_citation', {
                isFromImport: true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                citated_elements,
                citateid: uuidv4(),
              }),
            ]),
          ];
        } else if (type == 'fn') {
          const footNotes = this.jatsCitableElementsService.endNotes;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const citated_elements = [];
          const indexes = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries(footNotes as { [id: string]: any }).forEach(([id, value], index) => {
            if (numbers.includes(value.jats_id)) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              citated_elements.push(id);
              indexes.push(index + 1);
            }
          });

          // eslint-disable-next-line @typescript-eslint/naming-convention
          if (citated_elements.length == 0) return [schema.text(content)];

          const citationText = this.generateCitationText(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            citated_elements,
            element as Element,
            indexes
          );
          return [
            schema.text(citationText, [
              schema.mark('end_note_citation', {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                citated_elements,
                citateid: uuidv4(),
              }),
            ]),
          ];
        } else if (type == 'supplementary-material') {
          const suppFiles = this.jatsCitableElementsService.supplementaryFiles;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const citated_elements = [];
          const indexes = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries(suppFiles as { [id: string]: any }).forEach(([id, value], index) => {
            if (numbers.includes(value.jats_id)) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              citated_elements.push(id);
              indexes.push(index + 1);
            }
          });

          // eslint-disable-next-line @typescript-eslint/naming-convention
          if (citated_elements.length == 0) return [schema.text(content)];

          const citationText = this.generateCitationText(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            citated_elements,
            element as Element,
            indexes
          );
          return [
            schema.text(citationText, [
              schema.mark('supplementary_file_citation', {
                isFromImport: true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                citated_elements,
                citateid: uuidv4(),
              }),
            ]),
          ];
        } else if (type == 'bibr') {
          const refMap = this.refsObj;

          const citedRefsIds = [];
          const neededRefsForCitation = {};

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries(refMap as { [id: string]: any }).forEach(([id, value]) => {
            if (numbers.includes(value.jats_id)) {
              citedRefsIds.push(id);
              neededRefsForCitation[id] = value;
            }
          });

          if (citedRefsIds.length == 0) return [schema.text(content)];

          const refCitationID = uuidv4();

          const layoutOption = this.layoutOptions.find(
            (opt) => opt.name == this.selectedLayoutOption
          );
          const citationSettings = {
            selectedSortOptions: [['Default']],
            layout: layoutOption
              ? layoutOption.layout.join('\n')
              : this.layoutOptions[0].layout.join('\n'),
          };
          const sortedCitationStrings = this.serviceShare.CslService.generateCitation(
            neededRefsForCitation,
            citationSettings
          );

          this.referenceCitations[refCitationID] = {
            text: sortedCitationStrings.text,
            refCitationIDs: citedRefsIds,
            citationLayout:
              this.layoutOptions.find((opt) => opt.name == this.selectedLayoutOption) ||
              this.layoutOptions[0], //?  citationOptions?.["No brackets"] ? {name: 'No brackets', layout: citationOptions?.["No brackets"]} : {name: "Default", layout: ['Default']},
            sortOptions: [{ name: 'Default', tag: 'Default' }],
          };

          const tooltip = citedRefsIds
            .map(
              (id) =>
                `${refMap[id].citation.textContent?.trim()}\nCiTO: ${refMap[id].refCiTO?.label || CiToTypes[0].label}`
            )
            .join('\n');
          const nodeAttrs = {
            refCitationID,
            citedRefsIds,
            contenteditableNode: false,
            citedRefsCiTOs: ['None'],
            tooltip,
          };

          const mark = schema.mark('reference_citation', nodeAttrs);
          return [schema.text(sortedCitationStrings.text, [mark])];
        }
        return [];
      }
      case 'list-item': {
        const result = [];
        Array.from(element.childNodes).forEach((el) => {
          result.push(
            ...this.createProsemirrorNodes(el.nodeName, el.textContent, el).filter(
              (c) => c != undefined
            )
          );
        });
        return [schema.nodes.list_item.create({}, result)];
      }
      case 'p': {
        const result = [];
        Array.from(element.childNodes).forEach((el) => {
          result.push(
            ...this.createProsemirrorNodes(el.nodeName, el.textContent, el).filter(
              (c) => c != undefined
            )
          );
        });
        return [schema.nodes.paragraph.create({}, result)];
      }
      case 'list': {
        const result = this.parseSectionContent(element as Element);
        return result;
      }
      case 'title': {
        return [
          schema.nodes.paragraph.create(
            {},
            schema.text(element.textContent, [schema.marks.strong.create({})])
          ),
        ];
      }
      default: {
        const marks = Array.from(element.childNodes).map((ch) =>
          this.createProsemirrorMark(ch.nodeName, ch)
        );
        return [
          schema.text(
            content,
            marks.filter((m) => m != undefined && m?.type.name != 'text')
          ),
        ];
      }
    }
  }

  createProsemirrorMark(elType: string, element: ChildNode, isPreview?: boolean): PModel.Mark {
    switch (elType) {
      case 'bold': {
        return schema.marks.strong.create({});
      }
      case 'sup': {
        return schema.marks.superscript.create({});
      }
      case 'ext-link': {
        // @ts-expect-error: 'attributes' may not be defined on 'element'
        const attrs = element.attributes;
        const href = attrs.getNamedItem('xlink:href')?.value || '';

        return schema.marks.link.create({ href, title: '' });
      }
      case 'italic': {
        return schema.marks.em.create({});
      }
      case 'tp:taxon-name': {
        return isPreview
          ? // eslint-disable-next-line @typescript-eslint/naming-convention
            schema.marks.taxon.create({ removedtaxon: false, taxmarkid: uuidv4() })
          : undefined;
      }
    }
  }

  iterateSections(
    sec: ArticleSection,
    el: Element,
    level: number,
    sectionsContent: SectionContent[]
  ): void {
    const title = this.titleTags.includes(el.children[0].tagName)
      ? el.children[0].textContent.replace(/<[^>]*>/g, '').trim()
      : sec.title.label;

    const sectionID = uuidv4();

    this.allJatsSections.push({
      title,
      section: el,
      sectionID,
    });

    const hTag = typeof level == 'number' ? level : this.sectionsContent[sec.sectionID]?.level;

    const sectionContent = {
      doc: null,
      parsedSecTitle: title,
      level: hTag || this.serviceShare.TreeService.getNodeLevel(sec).hTag,
      sectionID,
      originalSectionName: sec.title.name,
      originalSecID: sec.sectionID,
      originalSection: sec,
    };

    sectionsContent.push(sectionContent);

    let hasChildren = false;

    Array.from(el.children).forEach((child) => {
      if (child.nodeName == 'sec') {
        if (sec.type == 'simple') {
          this.allJatsSections.push({
            title: child.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
            section: child,
            sectionID: sectionContent.sectionID,
          });
        } else {
          hasChildren = true;
        }
      }
    });

    if (hasChildren && sec.type == 'complex') {
      const secChildren = el.querySelectorAll('sec');
      secChildren.forEach((c, i) => {
        this.addGeneralSection(c, level + 1, sectionContent);
      });
    }
  }

  parseSectionContent(
    child: Element,
    shouldPreserveSpaces?: boolean,
    isPreview?: boolean
  ): PModel.Node[] {
    const children = [];
    child.childNodes.forEach((ch, i) => {
      children.push(
        ...this.createProsemirrorNodes(
          ch.nodeName,
          shouldPreserveSpaces ? ch.textContent : ch.textContent.split('\n').join(' '),
          ch,
          isPreview,
          i == 0
        )
      );
    });
    if (child.nodeName == 'list') {
      let node: PModel.Node;
      if (child.attributes.getNamedItem('list-type').value == 'order') {
        node = schema.nodes.ordered_list.create(
          {},
          children.filter((c) => c != undefined)
        );
        return [node];
      } else {
        node = schema.nodes.bullet_list.create(
          {},
          children.filter((c) => c != undefined)
        );
        return [node];
      }
    } else if (child.nodeName == 'p') {
      return [
        schema.nodes.paragraph.create(
          {},
          children.filter((c) => c != undefined)
        ),
      ];
    } else if (child.nodeName == 'preformat') {
      return [
        schema.nodes.code_block.create(
          {},
          children.filter((c) => c != undefined)
        ),
      ];
    } else {
      return children.filter((c) => c != undefined);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseRefFromRefindid(ref: any): void {
    if (!ref.ref.id) {
      ref.ref.id = uuidv4();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let refStyle: any;

    if (
      this.serviceShare.YdocService.articleData &&
      this.serviceShare.YdocService.articleData.layout.citation_style
    ) {
      const style = this.serviceShare.YdocService.articleData.layout.citation_style;
      refStyle = {
        name: style.name,
        label: style.title,
        style: style.style_content,
        last_modified: new Date(style.style_updated).getTime(),
      };
    } else {
      refStyle = {
        name: 'harvard-cite-them-right',
        label: 'Harvard Cite Them Right',
        style: harvardStyle,
        last_modified: 1649665699315,
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refBasicCitation: any = this.serviceShare.CslService.getBasicCitation(ref.ref, ref.style);

    const container = document.createElement('div');
    container.innerHTML = refBasicCitation.bibliography;
    refBasicCitation.textContent = container.textContent;
    const refInstance = {
      ...ref,
      citation: refBasicCitation,
      // refType: result.referenceScheme,
      refType: {
        last_modified: Date.now(),
      },
      ref_last_modified: Date.now(),
      // refCiTO:result.refCiTO,
      refStyle,
    };

    this.refsObj[ref.ref.id] = refInstance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initializeReferenceWithStyleAndCitation(type: string, id: string, data: any) {
    const newRef = generateNewReference({ type }, data);
    const refObj = { ref: newRef };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let refStyle: any;
    if (
      this.serviceShare.YdocService.articleData &&
      this.serviceShare.YdocService.articleData.layout.citation_style
    ) {
      const style = this.serviceShare.YdocService.articleData.layout.citation_style;
      refStyle = {
        name: style.name,
        label: style.title,
        style: style.style_content,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        last_modified: new Date(style.style_updated).getTime(),
      };
    } else {
      refStyle = {
        name: 'harvard-cite-them-right',
        label: 'Harvard Cite Them Right',
        style: harvardStyle,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        last_modified: 1649665699315,
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refBasicCitation: any = this.serviceShare.CslService.getBasicCitation(
      refObj.ref,
      refStyle.style
    );
    const container = document.createElement('div');
    container.innerHTML = refBasicCitation.bibliography;
    refBasicCitation.textContent = container.textContent;
    const refInstance = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      jats_id: id,
      ...refObj,
      citation: refBasicCitation,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ref_last_modified: Date.now(),
      refStyle,
      formIOData: data,
      refType: {
        name: type,
        type: type,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        last_modified: Date.now(),
      },
    };

    this.refsObj[newRef.id] = refInstance;
  }

  /**
   * Formats the text content of a `mixedCitation` element to improve parsing of authors.
   * - Adds commas after `<name>` elements.
   * - Inserts spaces between consecutive element nodes.
   *
   * Example author format:
   * ```
   * <person-group person-group-type="author">
   *     <name name-style="western">
   *         <surname>Zapparoli</surname>
   *         <given-names>M.</given-names>
   *     </name>
   * </person-group>
   * ```
   */
  getMixedCitationText = (mixedCitation: Element): string => {
    const textParts: string[] = [];

    const loopNode = (node: Node): void => {
      if (node.nodeType === Node.TEXT_NODE) {
        textParts.push(node.textContent.trim());
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((child, index, array) => {
          loopNode(child);

          // Check for consecutive element nodes
          if (index + 1 < array.length && array[index + 1].nodeType === Node.ELEMENT_NODE) {
            // Add a comma after <name> elements, otherwise add a space
            const currentIsName = node.childNodes[index].nodeName.toLocaleLowerCase() === 'name';
            if (currentIsName) {
              textParts.push(', ');
            } else {
              textParts.push(' ');
            }
          }
        });
      }
    };

    loopNode(mixedCitation);

    return textParts.join('').trim();
  };

  parseReferences(child: Element, personGroups: Element[]): void {
    /**
         * <mixed-citation>
            Poelen, Jorrit H., James D. Simons, and Chris J. Mungall. 2014. “Global Biotic Interactions: An Open Infrastructure to Share and Analyze Species-Interaction Datasets.”
            <italic>Ecological Informatics</italic>
            24 (November): 148–59.
            <ext-link ext-link-type="uri" xlink:href="https://doi.org/10.1016/j.ecoinf.2014.08.005">https://doi.org/10.1016/j.ecoinf.2014.08.005</ext-link>
            .
           </mixed-citation>
         */
    if (child.nodeName == 'ref') {
      const mixedCitation = child.querySelector('mixed-citation');
      if (mixedCitation) {
        const citationText = this.getMixedCitationText(mixedCitation);
        // doi matching pattern -> https://stackoverflow.com/questions/27910/finding-a-doi-in-a-document-or-page
        const pattern = /\b10\.[0-9]{4,}(?:\.[0-9]+)*\/([^"\'&<>]+)\b/;
        const match = citationText.match(pattern);

        if (match) {
          this.referencesThatShouldBeFetched.push({
            obs: this.httpClient.get(this.config.externalRefsApi, {
              responseType: 'text',
              params: {
                search: 'simple',
                text: match[0],
                db: ['crossref', 'datacite', 'pubmed', 'gnub'],
              },
            }),
            doi: match[0],
            refText: citationText.replace(/<[^>]+>/g, ''),
          });
        } else {
          const text = citationText.replace(/<[^>]+>/g, '');
          this.referencesThatShouldBeFetched.push({
            obs: this.refsAPI.parseReferenceFromPlainText(text),
            refText: text,
          });
        }
      } else {
        const id = child.id;
        const type =
          child
            .querySelector('element-citation, nlm-citation')
            ?.attributes?.getNamedItem('publication-type')?.value || '';
        const authors = [];
        const editors = [];
        personGroups?.forEach((pGroup) => {
          let typeOfGroup = pGroup.attributes.getNamedItem('person-group-type')?.value;
          if (typeOfGroup == 'guest-editor') {
            typeOfGroup = 'contributor';
          }
          Array.from(pGroup.children).forEach((ch) => {
            if (ch.nodeName == 'name') {
              const person = {
                last: ch.querySelector('surname')?.textContent.trim() || '',
                first: ch.querySelector('given-names')?.textContent.trim() || '',
                name: '',
                role: typeOfGroup,
                type: 'person',
              };
              if (typeOfGroup == 'editor') {
                editors.push(person);
              } else {
                authors.push(person);
              }
            }
          });
        });

        const queryTextContent = (element: Element, selector: string): string => {
          if (!selector || !element) return '';
          return element.querySelector(selector)?.textContent?.trim() || '';
        };

        const queryAttribute = (element: Element, selector: string, attr: string): string => {
          if (!selector || !element || !attr) return '';
          return element.querySelector(selector)?.attributes?.getNamedItem(attr)?.value || '';
        };

        const getTitle = (): string => {
          const titleMappings = {
            article: 'article-title',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'article-journal': 'article-title',
            software: 'article-title',
            webpage: 'article-title',
            website: 'article-title',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'paper-conference': 'article-title',
            book: 'source',
            thesis: 'source',
            chapter: 'chapter-title',
            other: 'article-title',
          };
          return queryTextContent(child, titleMappings[type] || '');
        };

        const getPages = (): string => {
          const firstPage = queryTextContent(child, 'fpage');
          const lastPage = queryTextContent(child, 'lpage');
          const size = queryTextContent(child, 'size[units="page"]');
          return firstPage && lastPage ? `${firstPage}-${lastPage}` : size;
        };

        const getDOI = (): string => {
          const doiPattern = /\b10\.[0-9]{4,}(?:\.[0-9]+)*\/([^"\'&<>]+)\b/;
          const element = child.querySelector('ext-link, pub-id');
          const match = element?.textContent.match(doiPattern);
          return match ? element.textContent : '';
        };

        const data = {
          type,
          authors,
          editors,
          issued: queryTextContent(child, 'year'),
          'container-title': queryTextContent(child, 'source'),
          title: getTitle(),
          volume: queryTextContent(child, 'volume'),
          issue: queryTextContent(child, 'issue'),
          URL: queryAttribute(child, 'ext-link', 'xlink:href') || queryTextContent(child, 'uri'),
          city: queryTextContent(child, 'publisher-loc'),
          page: getPages(),
          version:
            queryTextContent(child, 'version') ||
            queryTextContent(child, 'comment[content-type="Version"]'),
          edition: queryTextContent(child, 'edition'),
          publisher: queryTextContent(child, 'publisher-name'),
          'translated-title': queryTextContent(child, 'trans-title'),
          'event-title': queryTextContent(child, 'conf-name'),
          'event-location': queryTextContent(child, 'conf-loc'),
          'event-date': queryTextContent(child, 'conf-date'),
          ISBN: queryTextContent(child, 'isbn'),
          DOI: getDOI(),
        };
        // refsAPI.getReferenceTypes().subscribe((refTypes: any) => {
        // TODO: ADD TYPES
        // })
        this.initializeReferenceWithStyleAndCitation(type, id, data);
      }
    }
  }

  figureComponentsInPrevew = [];
  bottomOffset = 0.3;
  columnsFormControl = { value: 2 };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowTemplate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  figureRows: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxImgHeightPers: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxImgWidthPers: any;

  figureCanvasData = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  figNewComponents: any;
  urlMapping = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMappedComponentsForPreviw = (selfRef: any) => () => {
    return JSON.parse(
      JSON.stringify(
        selfRef.figNewComponents.map((x) => {
          return { container: x };
        })
      )
    );
  };

  parseFigures(figGroups: Element[]) {
    const hasFigures = !!Object.keys(this.jatsCitableElementsService.figures).length;
    figGroups.forEach((f, i) => {
      const caption = f.querySelector('caption');
      const id = f.id;
      const figure = {
        jats_id: id,
        description: caption || '',
        figureID: uuidv4(),
        figureNumber: hasFigures
          ? Math.max(
              ...Object.values(this.jatsCitableElementsService.figures).map((v) => v.figureNumber)
            ) + 1
          : i,
        figurePlace: 'endEditor',
        viewed_by_citat: 'endEditor',
        clientID: this.serviceShare.YdocService.ydoc.clientID,
        isNew: true,
      };
      const figs = Array.from(f.children).filter((ch) => ch.nodeName == 'fig');
      const components = [];

      if (f.nodeName == 'fig-group') {
        figs.forEach((fig) => {
          const url =
            fig.querySelector('uri')?.textContent ||
            fig.querySelector('graphic')?.attributes?.getNamedItem('xlink:href')?.value ||
            '';
          const description = fig.querySelector('caption');
          if (url) {
            components.push({
              description,
              url,
              componentType: fig.querySelector('graphic') ? 'image' : 'video',
            });
          }
        });
      } else {
        const url =
          f.querySelector('uri')?.textContent ||
          f.querySelector('graphic')?.attributes?.getNamedItem('xlink:href')?.value ||
          '';
        if (url) {
          components.push({
            description: '',
            url,
            componentType: f.querySelector('graphic') ? 'image' : 'video',
          });
        }
      }
      this.figNewComponents = components;
      figure['components'] = components;
      this.serviceShare.updatePreview(this)(false);
      figure['canvasData'] = this.figureCanvasData;
      this.jatsCitableElementsService.figures[figure.figureID] = figure as Figure;
      this.jatsCitableElementsService.figuresNumbers.push(figure.figureID);
      this.jatsCitableElementsService.figuresTemplates[figure.figureID] = {
        html: figuresHtmlTemplate,
      };
    });
  }

  addContentToFigures() {
    Object.keys(this.jatsCitableElementsService.figures).forEach((key) => {
      if (this.jatsCitableElementsService.figures[key].isNew) {
        const caption = this.jatsCitableElementsService.figures[key]
          .description as HTMLTableCaptionElement;
        if (caption) {
          const description = caption?.childNodes
            ? Array.from(caption.children).map((ch) =>
                this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.figures[key].description = content;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.jatsCitableElementsService.figures[key].components.forEach((comp: any) => {
          const caption = comp.description as HTMLTableCaptionElement;

          if (caption) {
            const description = caption?.childNodes
              ? Array.from(caption.children).map((ch) =>
                  this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
                )
              : '';
            const content = description
              ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer)
              : '';
            comp.description = content;
          }
        });
        delete this.jatsCitableElementsService.figures[key].isNew;
      }
    });
  }

  parseTables(tableEls: Element[]) {
    const template = this.serviceShare.YdocService.tablesMap?.get('tablesInitialTemplate');
    const hasTables = !!Object.keys(this.jatsCitableElementsService.tables).length;
    tableEls.forEach((t, i) => {
      const jats_id = t.id;
      const header = t.children?.[1] || '';
      const tableContent =
        `<table>${t.children?.[2]?.innerHTML || t.querySelector('table')?.innerHTML}</table>` ||
        '<table><tbody><tr><td><form-field><p></p></form-field></td><td><form-field><p></p></form-field></td><td><form-field><p></p></form-field></td></tr></table></tbody>';
      const tableFooter = t?.children?.[3] || '<p></p>';
      const table = {
        jats_id,
        tableID: uuidv4(),
        tableNumber: hasTables
          ? Math.max(
              ...Object.values(this.jatsCitableElementsService.tables).map((v) => v.tableNumber)
            ) + 1
          : i,
        tablePlace: 'endEditor',
        viewed_by_citat: 'endEditor',
        clientID: this.serviceShare.YdocService.ydoc.clientID,
        header,
        tableContent,
        tableFooter,
        isNew: true,
      };

      this.jatsCitableElementsService.tables[table.tableID] = table;
      this.jatsCitableElementsService.tablesNumbers.push(table.tableID);
      this.jatsCitableElementsService.tablesTemplates[table.tableID] = { html: template };
    });
  }

  addContentToTables() {
    this.serviceShare.ProsemirrorEditorsService.editMode = true;
    Object.keys(this.jatsCitableElementsService.tables).forEach(async (key) => {
      if (this.jatsCitableElementsService.tables[key].isNew) {
        const header = this.jatsCitableElementsService.tables[key]
          .header as HTMLTableCaptionElement;
        const footer = this.jatsCitableElementsService.tables[key]
          .tableFooter as HTMLTableCaptionElement;
        if (header) {
          const description = header?.childNodes
            ? Array.from(header.children).map((ch) =>
                this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.tables[key].header = content;
        }
        if (footer) {
          const description = footer?.childNodes
            ? Array.from(footer.children).map((ch) =>
                this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.tables[key].tableFooter = content;
        }
        const tableData = {
          ...this.jatsCitableElementsService.tables[key],
          viewed_by_citat: 'endEditor',
          tableContent: this.jatsCitableElementsService.tables[key].tableContent,
          tableHeader: this.jatsCitableElementsService.tables[key].header,
          tableFooter: this.jatsCitableElementsService.tables[key].tableFooter,
        };
      }
    });
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 200);
  }

  parseSupplementaryMaterials(materialEls: Element[]) {
    const template = this.serviceShare.YdocService.supplementaryFilesMap?.get(
      'supplementaryFilesInitialTemplate'
    );
    const hasSuplFiles = !!Object.keys(this.jatsCitableElementsService.supplementaryFiles).length;
    materialEls.forEach((supFile, i) => {
      const jats_id = supFile.id;
      const caption = supFile.querySelector('caption');

      const suppFile = {
        jats_id,
        supplementary_file_ID: uuidv4(),
        supplementary_file_number: hasSuplFiles
          ? Math.max(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...Object.values(this.jatsCitableElementsService.supplementaryFiles).map(
                (v: any) => v.supplementary_file_number
              )
            ) + 1
          : i,
        title: caption.children[0] || '',
        brief_description: supFile.querySelector('p') || caption.children[1] || '',
        authors: supFile.querySelector('attrib') || '',
        url:
          supFile.querySelector('ext-link')?.attributes?.getNamedItem('xlink:href')?.value ||
          supFile.querySelector('media')?.attributes?.getNamedItem('xlink:href')?.value ||
          '',
        data_type:
          supFile.attributes.getNamedItem('mimetype')?.value ||
          supFile.querySelector('statement')?.children?.[1]?.textContent ||
          '',
        isNew: true,
      };
      this.jatsCitableElementsService.supplementaryFilesNumbers.push(
        suppFile.supplementary_file_ID
      );
      this.jatsCitableElementsService.supplementaryFiles[suppFile.supplementary_file_ID] =
        suppFile as SupplementaryFile;
      this.jatsCitableElementsService.supplFileTemplates[suppFile.supplementary_file_ID] = {
        html: template,
      };
    });
  }

  addContentToSupplementaryMaterials() {
    Object.keys(this.jatsCitableElementsService.supplementaryFiles).forEach((key) => {
      if (this.jatsCitableElementsService.supplementaryFiles[key].isNew) {
        const title = this.jatsCitableElementsService.supplementaryFiles[key].title as HTMLElement;
        const brief_description = this.jatsCitableElementsService.supplementaryFiles[key]
          .brief_description as HTMLElement;
        const authors = this.jatsCitableElementsService.supplementaryFiles[key]
          .authors as HTMLElement;
        const data_type = this.jatsCitableElementsService.supplementaryFiles[key]
          .data_type as HTMLElement;
        if (title) {
          const description = title?.childNodes
            ? schema.nodes.paragraph.create(
                {},
                ...Array.from(title.childNodes).map((ch) =>
                  this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
                )
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(description.content), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.supplementaryFiles[key].title = content;
        }
        if (brief_description) {
          const description = brief_description?.childNodes
            ? schema.nodes.paragraph.create(
                {},
                ...Array.from(brief_description.childNodes).map((ch) =>
                  this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
                )
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(description.content), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.supplementaryFiles[key].brief_description = content;
        }
        if (authors) {
          const description = authors?.childNodes
            ? Array.from(authors.childNodes).map((ch) =>
                this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.supplementaryFiles[key].authors = content;
        }
        if (typeof data_type != 'string') {
          const description = data_type?.childNodes
            ? Array.from(data_type.childNodes).map((ch) =>
                this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)
              )
            : '';
          const content = description
            ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer)
            : '';
          this.jatsCitableElementsService.supplementaryFiles[key].data_type = content;
        }
        delete this.jatsCitableElementsService.supplementaryFiles[key].isNew;
      }
    });
  }

  parseSectionsFromModal(
    sections: Element[],
    articleSection: ArticleSection | SectionContent,
    level: number,
    shouldPreserveSpaces: boolean,
    title: string,
    hasOriginalContent: boolean
  ): Promise<PModel.Node> {
    return new Promise((resolve) => {
      const section = articleSection as ArticleSection;

      this.serviceShare.ProsemirrorEditorsService.editMode = true;

      if (section?.title?.name == 'Taxon') {
        this.taxonData = {};
        const el = sections.find((el) => el?.nodeName == 'tp:nomenclature');
        if (!el) resolve(schema.nodes.doc.create({}));

        this.getTaxonData(Array.from(sections[0].children));
        const htmlTemplate = section.prosemirrorHTMLNodesTempl;
        const sectionForm = new UntypedFormGroup({});

        filterFieldsValues(
          section.formIOSchema,
          { data: this.taxonData },
          this.serviceShare,
          articleSection.sectionID,
          true,
          '',
          false
        );
        this.formBuilderService.populateDefaultValues(
          this.taxonData,
          section.formIOSchema,
          section.sectionID,
          section,
          sectionForm
        );
        this.formBuilderService.buildFormGroupFromSchema(
          sectionForm,
          section.formIOSchema,
          section
        );
        sectionForm.patchValue(this.taxonData);

        this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
          htmlTemplate,
          this.taxonData,
          sectionForm,
          null
        ).then((result: string) => {
          const templDiv = document.createElement('div');
          templDiv.innerHTML = result;
          const node = PModel.DOMParser.fromSchema(schema).parse(templDiv.firstChild);
          resolve(node);
        });
      } else if (section?.title?.name == '[MM] Materials') {
        const element = sections.find(
          (el) =>
            el?.nodeName == 'tp:treatment-sec' &&
            el?.attributes?.getNamedItem?.('sec-type')?.value == 'materials'
        );

        if (!element) resolve(schema.nodes.doc.create({}));

        const materialSections = this.getMaterialsData(element);
        const { data, nodeForm } = this.serviceShare.TreeService.orderMaterialSections(
          materialSections,
          new UntypedFormGroup({})
        );

        this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
          section.prosemirrorHTMLNodesTempl,
          data,
          nodeForm,
          null
        ).then((result: string) => {
          const templDiv = document.createElement('div');
          templDiv.innerHTML = result;

          const node = PModel.DOMParser.fromSchema(schema).parse(templDiv.firstChild);
          resolve(node);
        });
      } else if (this.dataPaperService.dataPaperSpecificSections.includes(section?.title?.name)) {
        // Custom parsing for data paper sections
        this.dataPaperService.parseDataPaperSpecificSectionsFromJats(sections, section, resolve);
      } else {
        const nodes = [];
        const sectionContent = [];
        const wordsContent: PModel.Node[] = [];
        const titleFormioKey =
          (articleSection as ArticleSection)?.title?.name == this.sectionMappings?.title ||
          (articleSection as ArticleSection)?.title?.name == '[AM] Title'
            ? (articleSection as ArticleSection)?.formIOSchema?.components?.[0]?.key
            : undefined;

        const element = document.createElement('div');
        element.innerHTML = title;
        const parsed = this.serviceShare.DOMPMParser.parse(element);
        const content =
          titleFormioKey && hasOriginalContent
            ? [
                parsed.firstChild,
                this.serviceShare.ProsemirrorEditorsService.editorContainers[
                  articleSection.sectionID
                ].editorView.state.doc.content.firstChild.firstChild,
              ]
            : parsed.firstChild;

        const heading = schema.nodes.heading.create(
          {
            tagName: 'h' + level,
            formControlName: titleFormioKey || 'sectionTreeTitle',
            controlPath: titleFormioKey || 'sectionTreeTitle',
            contenteditableNode: titleFormioKey
              ? true
              : (articleSection as ArticleSection)?.title?.editable,
          },
          content
        );

        nodes.push(heading);

        sections.forEach((sec) => {
          if (sec) {
            Array.from(sec.children).forEach((child, i, arr) => {
              this.processChild(
                child,
                i,
                arr,
                section,
                shouldPreserveSpaces,
                wordsContent,
                sectionContent
              );
            });
          }
        });

        if (!titleFormioKey && title != 'Taxon treatments' && title != 'Treatment sections') {
          const paragraph = schema.nodes.paragraph.create(
            {
              formControlName: 'sectionContent',
              controlPath: 'sectionContent',
            },
            wordsContent
          );
          let content: PModel.Node[] = [];
          if (sectionContent.length > 0 && wordsContent.length > 0) {
            content = [...sectionContent, paragraph];
          } else if (sectionContent.length > 0 && wordsContent.length == 0) {
            content = sectionContent;
          }

          if (hasOriginalContent) {
            const originalContent =
              this.serviceShare.ProsemirrorEditorsService.editorContainers[articleSection.sectionID]
                .editorView.state.doc.lastChild;
            if (
              (originalContent &&
                originalContent.content['content'][0] &&
                originalContent.content['content'][0].childCount > 0) ||
              (originalContent && originalContent.content['content']?.length > 1)
            ) {
              content.push(...originalContent.content['content']);
            }
          }

          if (!content.length) {
            content = [paragraph];
          }

          nodes.push(
            schema.nodes.form_field.create(
              {
                formControlName: 'sectionContent',
                controlPath: 'sectionContent',
              },
              content
            )
          );
        }
        resolve(schema.nodes.doc.create({}, nodes));
      }

      setTimeout(() => {
        this.serviceShare.ProsemirrorEditorsService.editMode = false;
      }, 200);
    });
  }

  processChild(
    child: Element,
    index: number,
    arr: Node[],
    section: ArticleSection,
    shouldPreserveSpaces: boolean,
    wordsContent: PModel.Node[],
    sectionContent: PModel.Node[]
  ): void {
    // Skip nodes that should not be processed.
    const excludedNodes = ['table-wrap', 'label'];
    if (excludedNodes.includes(child.nodeName)) {
      return;
    }

    if (child.nodeName === 'kwd') {
      const separator = index === arr.length - 1 ? '' : ', ';
      const text = shouldPreserveSpaces
        ? child.textContent
        : child.textContent.split('\n').join(' ');
      wordsContent.push(
        ...this.createProsemirrorNodes(child.nodeName, text.trim() + separator, child)
      );
    } else if (child.nodeName === 'sec' || child.nodeName === 'title') {
      if (section.custom && section.title.name.includes('Abstract')) {
        sectionContent.push(...this.parseSectionContent(child, shouldPreserveSpaces));
      }
    } else {
      sectionContent.push(...this.parseSectionContent(child, shouldPreserveSpaces));
    }
  }

  parseCollectionData(sec: ArticleSection, element: Element) {
    const iterateCollectionData = (
      elements: Element[],
      level: number,
      sectionContent?: SectionContent
    ) => {
      elements.forEach((child) => {
        this.addGeneralSection(child, level, sectionContent);
      });
    };

    const sectionID = uuidv4();
    this.allJatsSections.push({ title: sec.title.label, section: element, sectionID });
    const sectionContent: SectionContent = {
      doc: null,
      parsedSecTitle: sec.title.label,
      level: 2,
      originalSectionName: sec.title.name,
      sectionID,
      originalSecID: sec.sectionID,
      originalSection: sec,
    };
    this.sectionsContent.push(sectionContent);

    // Iterate over all `sec` children elements
    const collectionSectionsFromXML = Array.from(element.children).filter(
      (ch) => ch.nodeName == 'sec'
    );
    iterateCollectionData(collectionSectionsFromXML, 3, sectionContent);
  }

  parseDataResources(sec: ArticleSection, element: Element) {
    const renderDataSetChildren = (
      dSec: ArticleSection,
      element: Element,
      sectionName: string,
      sectionsContent: SectionContent[]
    ) => {
      if (!dSec) return;

      const child = dSec.children.find((sec) => sec.title.name == sectionName);
      if (child) {
        this.iterateSections(child, element, 4, sectionsContent);
      }
    };

    const iterateDataSets = (
      element: Element,
      section: ArticleSection,
      sectionsContent: SectionContent[]
    ) => {
      Array.from(element.children).forEach((child) => {
        if (child.nodeName != 'sec') return;
        const secType = child.attributes.getNamedItem('sec-type').value.toLocaleLowerCase();

        if (secType.includes('data set name')) {
          renderDataSetChildren(section, child, '[PS] Data set name', sectionsContent);
        } else if (secType.includes('data format') && !secType.includes('version')) {
          renderDataSetChildren(section, child, '[PS] Data format', sectionsContent);
        } else if (secType.includes('character set')) {
          renderDataSetChildren(section, child, '[PS] Character set', sectionsContent);
        } else if (secType.includes('download url')) {
          renderDataSetChildren(section, child, '[PS] Download URL', sectionsContent);
        } else if (secType.includes('data format version')) {
          renderDataSetChildren(section, child, '[PS] Data format version', sectionsContent);
        } else if (secType.includes('description')) {
          const descriptionSec = section.children.find(
            (sec) =>
              sec.title.name == this.sectionMappings?.data_description ||
              sec.title.name == '[PS] Data set description'
          );
          this.iterateSections(descriptionSec, child, 4, sectionsContent);
        }
      });
    };

    const renderDataSet = (
      element: Element,
      section: ArticleSection,
      sectionsContent: SectionContent[]
    ) => {
      // if (element && !section) {
      //   // XML section is not present in the editor's sections structure.
      //   const fileredSections = getFilteredSectionChooseData(
      //     this.serviceShare.TreeService.findNodeById(sec.sectionID),
      //     this.serviceShare.TreeService
      //   );
      //   const section = fileredSections.find((sec) => sec.source == 'template');

      //   if (section) {
      // Add the section in the editor
      // const newSection = this.serviceShare.TreeService.addNodeAtPlaceChange(
      //   sec.sectionID,
      //   section.template,
      //   'end'
      // );
      //     setTimeout(() => {
      //       renderDataSet(element, newSection, sectionsContent);
      //     }, 200);
      //   }
      // } else {
      // XML section is present in the editor's sections structure.
      const sectionID = uuidv4();

      this.allJatsSections.push({
        title: section?.title.label,
        section: element,
        sectionID,
      });
      const sectionContent: SectionContent = {
        doc: null,
        level:
          this.sectionsContent[section.sectionID]?.level ||
          this.serviceShare.TreeService.getNodeLevel(section).hTag,
        sectionID,
        originalSectionName: section?.title.name,
        subsections: [],
        originalSecID: section.sectionID,
        originalSection: section,
      };

      this.sectionsContent.push(sectionContent);

      iterateDataSets(element, section, sectionContent.subsections);
      // }
    };

    const iterateDataResources = (
      elements: Element[],
      level: number,
      sectionsContent: SectionContent[]
    ) => {
      const dataSetSections = sec.children.filter(
        (s) => s.title.name == this.sectionMappings?.data_set || s.title.name == '[PS] Data set'
      );
      const dataSetElements: Element[] = [];

      elements.forEach((child) => {
        if (child.nodeName != 'sec') return;

        const secType = child.attributes.getNamedItem('sec-type').value.toLocaleLowerCase();
        // Checks if sec-type is "Data set" and not "Data set name" or "Number of data sets"
        const isSecTypeDataSet = /data set/.test(secType) && !/(name|number)/.test(secType);

        if (isSecTypeDataSet) {
          dataSetElements.push(child);
        } else if (secType.includes('data package title')) {
          const section = sec.children.find(
            (s) =>
              s.title.name == this.sectionMappings?.data_package_title ||
              s.title.name == '[PS] Data package title'
          );
          this.iterateSections(section, child, level, sectionsContent);
        } else if (secType.includes('resource link')) {
          const section = sec.children.find(
            (s) =>
              s.title.name == this.sectionMappings?.resource_link ||
              s.title.name == '[PS] Resource link'
          );
          this.iterateSections(section, child, level, sectionsContent);
        } else if (secType.includes('alternative identifiers')) {
          const section = sec.children.find(
            (s) =>
              s.title.name == this.sectionMappings?.alternative_identifiers ||
              s.title.name == '[PS] Alternative identifiers'
          );
          this.iterateSections(section, child, level, sectionsContent);
        }
      });

      // Iterate over each found data set section in the XML and add it in the editor if missing
      dataSetElements.forEach((dataSetEl, index) => {
        renderDataSet(dataSetEl, dataSetSections[index], sectionsContent);
      });
    };
    const sectionID = uuidv4();
    this.allJatsSections.push({ title: sec.title.label, section: element, sectionID });
    const sectionContent: SectionContent = {
      doc: null,
      parsedSecTitle: sec.title.label,
      level:
        this.sectionsContent[sec.sectionID]?.level ||
        this.serviceShare.TreeService.getNodeLevel(sec).hTag,
      originalSectionName: sec.title.name,
      subsections: [],
      sectionID,
      originalSecID: sec.sectionID,
    };
    this.sectionsContent.push(sectionContent);

    // Iterate over all `sec` children elements
    const dataResourceSectionsFromXML = Array.from(element.children).filter(
      (ch) => ch.nodeName == 'sec'
    );
    iterateDataResources(dataResourceSectionsFromXML, 3, sectionContent.subsections);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  taxonData: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  materialsData: any[] = [];

  parseTaxonTreatments(sec: ArticleSection, element: Element, sectionContent: SectionContent) {
    let taxonSec = sec;

    const renderTreatmentSections = (tSec: ArticleSection, ch: Element, sectionName: string) => {
      let treatmentSectionContent = sectionContent.subsections.find(
        (sectionContent) => sectionContent.originalSectionName == '[MM] Treatment sections'
      );

      const section = tSec.children.find((s) => s.title.name == '[MM] Treatment sections');
      if (!treatmentSectionContent) {
        treatmentSectionContent = {
          doc: null,
          parsedSecTitle: section.title.label,
          level: 1,
          sectionID: section.sectionID,
          originalSecID: '',
          originalSectionName: section.title.name,
          subsections: [],
          originalSection: section,
        };

        sectionContent.subsections.push(treatmentSectionContent);
      }

      if (section) {
        const s = section.children.find((sec) => sec.title.name == sectionName);
        if (s) {
          this.iterateSections(s, ch, 3, treatmentSectionContent.subsections);
        } else {
          const s = section.children[0];
          s.title.name = '[MM]' + sectionName;
          s.title.label = sectionName;
          this.iterateSections(s, ch, 3, treatmentSectionContent.subsections);
        }
      } else {
        const section = tSec.children.find((s) => s.title.name == sectionName);
        this.iterateSections(section, ch, 1, sectionContent.subsections);
      }
    };

    const iterateTaxon = (elements: Element[], secContent: SectionContent, isTaxon?: boolean) => {
      elements.forEach((child, index) => {
        if (child.nodeName == 'tp:taxon-treatment') {
          taxonSec = sec.children[index];
          if (taxonSec) {
            iterateTaxon(Array.from(child.children), sectionContent, false);
          }
        } else if (child.nodeName == 'tp:nomenclature') {
          this.getTaxonData(Array.from(child.children));
          const valuesCopy = {};
          Object.keys(this.taxonData).forEach((key) => {
            valuesCopy[key] = this.taxonData[key];
          });
          const label = this.serviceShare.TreeService.generateTaxonTitle(this.taxonData).taxonTitle;

          this.allJatsSections.push({
            title: label,
            section: child,
            sectionID: secContent.sectionID,
          });
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type').value == 'materials'
        ) {
          const materialsParent = taxonSec.children.find(
            (sec) => sec.title.name == '[MM] Materials'
          );
          this.allJatsSections.push({
            title: 'Materials Download as CSV or XLSX',
            section: child,
            sectionID: materialsParent.sectionID,
          });

          secContent.subsections.push({
            doc: null,
            parsedSecTitle: 'Materials Download as CSV or XLSX',
            level: 1,
            sectionID: materialsParent.sectionID,
            originalSecID: '',
            originalSectionName: materialsParent.title.name,
            originalSection: materialsParent,
          });
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Description'
        ) {
          renderTreatmentSections(
            taxonSec,
            child,
            this.sectionMappings?.description || '[MM] Description'
          );
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Diagnosis'
        ) {
          renderTreatmentSections(
            taxonSec,
            child,
            this.sectionMappings?.diagnosis || '[MM] Diagnosis'
          );
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Ecology'
        ) {
          renderTreatmentSections(
            taxonSec,
            child,
            this.sectionMappings?.etymology || '[MM] Etymology'
          );
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Conservation'
        ) {
          renderTreatmentSections(
            taxonSec,
            child,
            this.sectionMappings?.distribution || '[MM] Distribution'
          );
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Biology'
        ) {
          renderTreatmentSections(taxonSec, child, this.sectionMappings?.biology || '[MM] Biology');
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Notes'
        ) {
          renderTreatmentSections(taxonSec, child, this.sectionMappings?.notes || '[MM] Notes');
        } else if (
          child.nodeName == 'tp:treatment-sec' &&
          child.attributes.getNamedItem('sec-type')?.value == 'Taxon discussion'
        ) {
          renderTreatmentSections(
            taxonSec,
            child,
            this.sectionMappings?.taxon_discussion || '[MM] Taxon discussion'
          );
        } else {
          renderTreatmentSections(
            taxonSec,
            child,
            child.attributes.getNamedItem('sec-type')?.value
          );
        }
      });
    };

    iterateTaxon(Array.from(element.children), sectionContent, true);
  }

  getMaterialsData(element: Element) {
    this.materialsData = [];
    const listItems = Array.from(element.querySelectorAll('list-item'));
    // typeStatus
    // typeHeading
    listItems.forEach((item) => {
      const materialData = {};
      Array.from(item.children[0]?.children || []).forEach((el) => {
        if (el.nodeName == 'named-content') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, prop] = el.attributes.getNamedItem('content-type').value.split(':');
          materialData[prop] = el.textContent;
        }
      });
      materialData['typeHeading'] = materialData['typeStatus'];
      this.materialsData.push(materialData);
    });
    const sections = [];
    for (const row of this.materialsData) {
      Object.keys(row).forEach((key: string) => {
        if (key !== 'typeStatus') {
          row[key] = row[key].trim() + ';&nbsp;';
        }
      });
      const section = JSON.parse(JSON.stringify(material));
      section.mode = '';
      section.active = false;
      section.defaultFormIOValues = row;
      section.sectionID = uuidv4();
      section.label = row.typeStatus;
      section.parentId = row.parentId;
      sections.push(section);
    }

    return sections;
  }

  getTaxonData(elements: Element[]) {
    elements.forEach((el) => {
      if (el.nodeName == 'tp:taxon-name') {
        this.getTaxonData(Array.from(el.children));
      } else if (el.nodeName == 'tp:taxon-name-part') {
        const attribute = el.attributes.getNamedItem('taxon-name-part-type')?.value;
        this.taxonData[attribute] = el.textContent || '';
        if (attribute == 'genus' || attribute == 'species') {
          this.taxonData['rank'] = 'subspecies';
        }
      } else if (el.nodeName == 'tp:taxon-authority') {
        this.taxonData['authorandyear'] = el.textContent || '';
      } else if (el.nodeName == 'tp:taxon-status') {
        this.taxonData['typeoftreatment'] = el.textContent || '';
      }
    });
  }

  addCitableElementsToEditor() {
    this.subscription.unsubscribe();

    this.serviceShare.YdocService.referenceCitationsMap.set('refsAddedToArticle', {});
    this.serviceShare.YdocService.referenceCitationsMap.set('referencesInEditor', {});

    if (this.jatsCitableElementsService.figuresNumbers.length > 0) {
      this.serviceShare.YdocService.figuresMap?.set(
        'figuresTemplates',
        this.jatsCitableElementsService.figuresTemplates
      );
      setTimeout(() => {
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.jatsCitableElementsService.figures,
          this.jatsCitableElementsService.figuresNumbers,
          'citation'
        );
      }, 100);
    }
    if (Object.keys(this.refsObj).length > 0) {
      Object.keys(this.referenceCitations).forEach((key) => {
        this.referenceCitations[key].citationLayout =
          this.layoutOptions.find((opt) => opt.name == this.selectedLayoutOption) ||
          this.layoutOptions[0];
      });
      this.serviceShare.YdocService.referenceCitationsMap.set('refsAddedToArticle', this.refsObj);
      this.serviceShare.YdocService.referenceCitationsMap.set(
        'referenceCitations',
        this.referenceCitations
      );
    }
    if (this.jatsCitableElementsService.endNotesNumbers.length > 0) {
      this.serviceShare.CitableElementsService.writeElementDataGlobal(
        this.jatsCitableElementsService.endNotes,
        this.jatsCitableElementsService.endNotesNumbers,
        'end_note_citation'
      );
    }
    if (this.jatsCitableElementsService.tablesNumbers.length > 0) {
      this.serviceShare.YdocService.tablesMap?.set(
        'tablesTemplates',
        this.jatsCitableElementsService.tablesTemplates
      );
      setTimeout(() => {
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.jatsCitableElementsService.tables,
          this.jatsCitableElementsService.tablesNumbers,
          'table_citation'
        );
      }, 200);
    }
    if (this.jatsCitableElementsService.supplementaryFilesNumbers.length > 0) {
      this.serviceShare.YdocService.supplementaryFilesMap?.set(
        'supplementaryFilesTemplates',
        this.jatsCitableElementsService.supplFileTemplates
      );
      setTimeout(() => {
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.jatsCitableElementsService.supplementaryFiles,
          this.jatsCitableElementsService.supplementaryFilesNumbers,
          'supplementary_file_citation'
        );
      }, 100);
    }
  }

  addCitableElementsToYdoc() {
    this.subscription.unsubscribe();

    this.serviceShare.YdocService.referenceCitationsMap.set('refsAddedToArticle', {});
    this.serviceShare.YdocService.referenceCitationsMap.set('referencesInEditor', {});

    if (this.jatsCitableElementsService.figuresNumbers.length > 0) {
      this.serviceShare.YdocService.figuresMap?.set(
        'figuresTemplates',
        this.jatsCitableElementsService.figuresTemplates
      );

      this.serviceShare.YdocService.figuresMap?.set(
        'ArticleFiguresNumbers',
        this.jatsCitableElementsService.figuresNumbers
      );

      this.serviceShare.YdocService.figuresMap?.set(
        'ArticleFigures',
        this.jatsCitableElementsService.figures
      );
    }

    if (Object.keys(this.refsObj).length > 0) {
      Object.keys(this.referenceCitations).forEach((key) => {
        this.referenceCitations[key].citationLayout =
          this.layoutOptions.find((opt) => opt.name == this.selectedLayoutOption) ||
          this.layoutOptions[0];
      });
      this.serviceShare.YdocService.referenceCitationsMap.set('refsAddedToArticle', this.refsObj);
      this.serviceShare.YdocService.referenceCitationsMap.set(
        'referenceCitations',
        this.referenceCitations
      );
    }

    if (this.jatsCitableElementsService.endNotesNumbers.length > 0) {
      this.serviceShare.YdocService.endNotesMap.set(
        'endNotes',
        this.jatsCitableElementsService.endNotes
      );
      this.serviceShare.YdocService.endNotesMap.set(
        'endNotesNumbers',
        this.jatsCitableElementsService.endNotesNumbers
      );
    }

    if (this.jatsCitableElementsService.tablesNumbers.length > 0) {
      this.serviceShare.YdocService.tablesMap.set(
        'tablesTemplates',
        this.jatsCitableElementsService.tablesTemplates
      );
      this.serviceShare.YdocService.tablesMap.set(
        'ArticleTables',
        this.jatsCitableElementsService.tables
      );
      this.serviceShare.YdocService.tablesMap.set(
        'ArticleTablesNumbers',
        this.jatsCitableElementsService.tablesNumbers
      );
    }
    if (this.jatsCitableElementsService.supplementaryFilesNumbers.length > 0) {
      this.serviceShare.YdocService.supplementaryFilesMap?.set(
        'supplementaryFilesTemplates',
        this.jatsCitableElementsService.supplFileTemplates
      );
      this.serviceShare.YdocService.supplementaryFilesMap.set(
        'supplementaryFiles',
        this.jatsCitableElementsService.supplementaryFiles
      );
      this.serviceShare.YdocService.supplementaryFilesMap.set(
        'supplementaryFilesNumbers',
        this.jatsCitableElementsService.supplementaryFilesNumbers
      );
    }

    this.setEmptyCitableElements();
  }

  setEmptyCitableElements() {
    this.jatsCitableElementsService.figures = {};
    this.jatsCitableElementsService.figuresNumbers = [];
    this.jatsCitableElementsService.figuresTemplates = {} as ElementHtmlEntry;
    this.jatsCitableElementsService.tables = {};
    this.jatsCitableElementsService.tablesNumbers = [];
    this.jatsCitableElementsService.tablesTemplates = {} as ElementHtmlEntry;
    this.jatsCitableElementsService.supplementaryFilesNumbers = [];
    this.jatsCitableElementsService.supplementaryFiles = {};
    this.jatsCitableElementsService.supplFileTemplates = {} as ElementHtmlEntry;
    this.jatsCitableElementsService.endNotesNumbers = [];
    this.jatsCitableElementsService.endNotes = {};
    this.refsObj = {};
  }

  allCollaborators: ArticleCollaborator[] = [];
  allAuthors: authorListData[] = [];

  collaboratorsForInvite: {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    is_co_author: boolean;
    affiliations: any[];
  }[] = [];

  getAllContributors() {
    const contributors = this.doc.querySelectorAll('contrib');
    const collaboratorsCopy = [
      ...this.serviceShare.YdocService.collaborators.get('collaborators').collaborators,
    ];
    const authorsListCopy = [...this.serviceShare.YdocService.collaborators.get('authorsList')];

    const allAffiliations = {};
    const affiliations = this.doc.querySelectorAll('aff');

    affiliations.forEach((aff) => {
      const id = aff.getAttribute('id');
      const institution = aff.querySelector('institution')?.textContent?.trim();
      const addrLineCity = aff.querySelector('addr-line[content-type="city"]')?.textContent?.trim();
      const country = aff.querySelector('country')?.textContent?.trim();

      allAffiliations[id] = {
        affiliation: institution,
        city: addrLineCity,
        country,
      };
    });

    contributors.forEach((c) => {
      const givenNames = c.querySelector('given-names')?.textContent?.trim() || '';
      const surname = c.querySelector('surname')?.textContent?.trim() || '';
      const email = c.querySelector('email')?.textContent?.trim() || '';
      const role = 'author';
      const is_co_author = c.getAttribute('corresp') === 'yes';
      const xrefs = c.querySelectorAll('xref[ref-type="aff"]');

      const contributorAffiliations = [];
      xrefs.forEach((xref, index) => {
        const rid = xref.getAttribute('rid');
        if (allAffiliations[rid]) {
          contributorAffiliations.push({ ...allAffiliations[rid], order: index + 1 });
        }
      });

      const addCollaborator = () => {
        this.collaboratorsForInvite.push({
          id: null,
          name: `${givenNames} ${surname}`,
          first_name: givenNames,
          last_name: surname,
          email,
          role,
          is_co_author,
          affiliations: contributorAffiliations,
        });

        collaboratorsCopy.push({
          access: roleMapping[role],
          name: `${givenNames} ${surname}`,
          email,
          first_name: givenNames,
          last_name: surname,
          role,
          role_label: 'Author',
          id: email,
          auth_role: roleMapping[role],
          allowed_article_versions: [],
          hide_my_comments_from_user: [],
          anonymize_me_from: [],
          settings: {},
          is_co_author,
          is_owner: false,
          affiliations: contributorAffiliations,
        });

        authorsListCopy.push({ authorId: email, authorEmail: email });
      };

      if (email && email.trim() && !collaboratorsCopy.find((c) => c.email === email)) {
        addCollaborator();
      }
    });

    this.allCollaborators = collaboratorsCopy;
    this.allAuthors = authorsListCopy;
  }

  inviteContributors() {
    const postBody = {
      article: {
        id: this.serviceShare.YdocService.articleData.uuid,
        title: this.serviceShare.YdocService.articleData.name,
      },
      message: '',
      invited: this.collaboratorsForInvite,
    };

    this.allUsersService.sendInviteInformation(postBody).subscribe(
      (result) => {
        console.log(result);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  containsHTMLTags(str) {
    const regex = /<[^>]*>/g; // Regular expression to match HTML tags
    return regex.test(str);
  }

  /**
   * Determines the appropriate citation text based on the number of cited elements
   * and the type of the element being cited (e.g. Table, Fig, Suppl. material).
   * Also removes any redundant citation text (e.g., "Fig", "Figs", "Table", "Suppl. material") preceding the element.
   */
  private generateCitationText(
    citedElements: Array<string>,
    element: Element,
    indexes: Array<number>
  ): string {
    const refTypeMap = this.getRefTypeMap();
    const redundantTextMap = this.getRedundantTextMap();

    const refType = element.attributes.getNamedItem('ref-type')?.value;
    if (!refType || !refTypeMap[refType]) {
      return '';
    }

    const citationType = refTypeMap[refType];

    // Clean up redundant text if it exists before the xref element
    this.removeRedundantText(element.previousSibling, refType, redundantTextMap);

    // Generate citation text based on the number of elements cited
    const citationKey = citedElements.length > 1 ? 'multipleElTxt' : 'singleElTxt';
    return `${citationElementMap[citationType][citationKey]} ${indexes.join(', ')}`;
  }

  // Removes redundant text before the xref element if it exists
  private removeRedundantText(
    previousSibling: Node | null,
    refType: string,
    redundantTextMap: { [key: string]: RegExp[] }
  ) {
    if (!previousSibling || previousSibling.nodeType !== Node.TEXT_NODE) {
      return; // No previous text node
    }

    const previousText = previousSibling.textContent?.trim();
    if (!previousText || !redundantTextMap[refType]) return; // No relevant redundant text

    // Check for redundant text using RegExp and remove it
    for (const redundantText of redundantTextMap[refType]) {
      if (redundantText.test(previousText)) {
        previousSibling.textContent = previousText.replace(redundantText, '').trim();
        break;
      }
    }
  }

  // Helper to map xref types to citation type keys
  private getRefTypeMap() {
    return {
      table: 'table_citation',
      fig: 'citation',
      fn: 'end_note_citation',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'supplementary-material': 'supplementary_file_citation',
    };
  }

  // Helper to map redundant text strings for each type
  private getRedundantTextMap(): RedundantTextMap {
    return {
      fig: [/^Figs?\.*$/], // Matches "Fig", "Fig.", "Figs", "Figs."
      table: [/^Tables?\.*$/], // Matches "Table", "Table.", "Tables", "Tables."
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'supplementary-material': [/^Suppl\. materials?\.*$/], // Matches "Suppl. material", etc.
    };
  }

  renderGeneralSection(sectionFromBackend: Section): ArticleSection {
    if (sectionFromBackend) {
      const { sectionMenusAndSchemaHTMLDefs, sectionTemplate, modalTemplate } =
        parseSecHTMLMenuAndSchemaDefs(sectionFromBackend.template, {
          menusL: 'customSectionHTMLMenuType',
          tagsL: 'customSectionHTMLAllowedTags',
        });

      const shemas = parseSecFormIOJSONMenuAndSchemaDefs(sectionFromBackend.schema, {
        menusL: 'customSectionJSONMenuType',
        tagsL: 'customSectionJSONAllowedTags',
      });
      const sectionMenusAndSchemaDefsFromJSON = shemas.sectionMenusAndSchemaDefsFromJSON;

      const sectionMenusAndSchemasDefsfromJSONByfieldsTags =
        shemas.sectionMenusAndSchemasDefsfromJSONByfieldsTags;

      const sectionMenusAndSchemaDefs = {
        menus: {
          ...sectionMenusAndSchemaHTMLDefs.menus,
          ...sectionMenusAndSchemaDefsFromJSON.menus,
        },
        schemas: {
          ...sectionMenusAndSchemaHTMLDefs.schemas,
          ...sectionMenusAndSchemaDefsFromJSON.schemas,
        },
      };
      const section: ArticleSection = {
        title: {
          label: sectionFromBackend.label,
          name: sectionFromBackend.name,
          template: sectionFromBackend.label,
          editable: true,
        },
        sectionID: uuidv4(),
        edit: { active: false, main: true },
        add: { active: true, main: false },
        delete: { active: true, main: false },
        addSubSection: { active: true, main: false },
        mode: 'documentMode',
        formIOSchema: sectionFromBackend.schema,
        pivotId: sectionFromBackend.pivot_id,
        id: sectionFromBackend.id,
        allow_compatibility: sectionFromBackend.allow_compatibility,
        compatibility_extended: sectionFromBackend.compatibility_extended,
        menusAndSchemasDefs: sectionMenusAndSchemaDefs,
        initialRender: undefined,
        active: true,
        defaultFormIOValues: undefined,
        prosemirrorHTMLNodesTempl: sectionTemplate,
        modalTemplate,
        children: [],
        type: 'complex',
        custom: false,
        sectionIdFromBackend: sectionFromBackend.id,
        sectionTypeID: sectionFromBackend.id,
        sectionTypeVersion: sectionFromBackend.version,
        sectionMeta: { main: false },
        originalSectionTemplate: JSON.parse(JSON.stringify(sectionFromBackend)),
        customSchema: { isCustom: false },
        compatibility: sectionFromBackend.compatibility,
        sectionMenusAndSchemasDefsfromJSONByfieldsTags,
      };

      const minmaxValds = {};
      sectionFromBackend.complex_section_settings.forEach((secMinMax) => {
        minmaxValds[secMinMax.pivot_id] = {
          min: secMinMax.min_instances,
          max: secMinMax.max_instances,
          section_id: secMinMax.section_id,
        };
      });

      section.subsectionValidations = minmaxValds;

      return section;
    }
  }
}
