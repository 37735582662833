import { Compatibility3 } from '@app/core/models/article.models';

export interface editorData {
  editorId: string;
  menuType: string;
  editorMeta?: editorMeta;
}

export interface editorMeta {
  label?: string;
  placeHolder?: string;
  prosemirrorJsonTemplate?: any;
  formioJson?: any;
}

export interface taxonomicCoverageContentData {
  description: editorData;
  taxaArray: taxa[]; //table rows
}

export interface taxa {
  scietificName: editorData;
  commonName: editorData;
  rank: dropdownData;
}

export interface dropdownData {
  options: string[];
  defaulValue?: string;
}

export interface titleContent {
  name?: string;
  label: string;
  template: string;
  editable: boolean;
}

export interface sectionContent {
  type:
    | 'content'
    | 'taxonomicCoverageContentType'
    | 'editorContentType'
    | 'TaxonTreatmentsMaterial';
  contentData?: sectionContentData;
  key: 'sectionContent';
}

export type titleContentData = editorData | string;
export type sectionContentData = editorData | taxonomicCoverageContentData;

export interface ArticleSection {
  initialRender?: string;
  sectionID: string;
  allow_compatibility: boolean;
  compatibility_extended: null | any[];
  active: boolean;
  children: ArticleSection[];
  add: { active: boolean; main: boolean };
  edit: { active: boolean; main: boolean };
  delete: { active: boolean; main: boolean };
  addSubSection?: { active: boolean; main: boolean };
  pivotId: number | undefined;
  id: number;
  menusAndSchemasDefs: {
    menus: { [key: string]: any[] };
    schemas: { [key: string]: { nodes: string[]; marks: string[] } };
  };
  mode: 'documentMode' | 'editMode' | 'noSchemaSectionMode';
  title: titleContent;
  override?: any;
  prosemirrorHTMLNodesTempl?: string;
  formIOSchema?: any;
  sectionIdFromBackend: number;
  defaultFormIOValues?: any;
  type: 'complex' | 'simple';
  sectionTypeID: number;
  sectionTypeVersion: number;
  sectionMeta: { main: boolean };
  subsectionValidations?: { [sec_id_backend: number]: { min: number; max: number } };
  compatibility?: Compatibility3;
  custom?: boolean;
  customSchema: { isCustom: boolean; schema?: { nodes: string[]; marks: string[] } };
  sectionMenusAndSchemasDefsfromJSONByfieldsTags?: { [key: string]: { menu: any; schema: any } };
  jats_tag?: string;
  originalSectionTemplate: any;
  modalTemplate?: string;
  level?: number;
  shouldNotShow?: boolean;
  template?: string;
}

export interface basicArticleSection {
  name: string;
  label: string;
  children: basicArticleSection[];
  sectionID: string;
  active: boolean;
  subsectionValidations: any;
  pivotId: number;
  id: number;
  sectionTypeID: number;
  shouldNotShow?: boolean;
  parentId?: string;
  level?: number;
}

export interface flatArticleSection {
  title: string;
  sectionID: string;
  parentSectionID: string;
  prosemirrorHTMLNodesTempl: string;
}

export enum SectionNamesMapping {
  taxon = 'Taxon',
  taxonTreatments = '[MM] Taxon treatments',
  generalSection = '[PS] General Section',
}
