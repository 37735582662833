/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendAPIErrorBody, ErrorPublishResponse, ServiceType } from '../../models';
import { CustomError, CustomErrorType } from '../../custom-errors/models';
import { AuthService } from '@core/services/auth.service';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { ERROR_PUBLISHER, ErrorPublisher } from './error-publisher.token';
import { NetworkError } from '../../custom-errors/network-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private readonly notificationAction = 'sendSystemErrorNotification';
  private readonly serviceName = 'article-editor';

  constructor(
    private readonly authService: AuthService,
    private readonly snackbar: SnackbarService,
    @Inject(ERROR_PUBLISHER) private readonly errorPublisher: ErrorPublisher
  ) {}

  public publishToBackend(
    error: CustomError | (() => CustomError)
  ): Observable<ErrorPublishResponse> {
    if (typeof error === 'function') {
      error = error();
    }
    const backendAPIErrorBody = this.buildBackendAPIErrorBody(error);
    return this.errorPublisher.publish(backendAPIErrorBody);
  }

  public log(error: CustomError): void {
    console.error(error.details);
  }

  public notifyUser(error: CustomError): void {
    this.snackbar.error(error.message, 'Ok', { duration: 15000 });
  }

  private buildBackendAPIErrorBody(error: CustomError): BackendAPIErrorBody {
    const message =
      error.customErrorType === CustomErrorType.network ? NetworkError.message : error.message;
    return {
      action: this.notificationAction,
      service_name: this.serviceName,
      service_id: 'NO_ACCESS_TO_ENV_VARIABLES', // TODO: we don't have access to the environment variables here
      message,
      user_id: this.authService.currentUser?.id,
      timestamp: error.timestamp,
      service_type: ServiceType.frontend,
      details: error.details,
    };
  }
}
