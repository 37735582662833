import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendAPIErrorBody, ErrorPublishResponse } from '../../models';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { ErrorPublisher } from './error-publisher.token';

@Injectable()
export class ErrorPublishingService implements ErrorPublisher {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  private get apiUrl(): string {
    return `${this.config.apiUrl}/event-dispatcher/notifications`;
  }

  public publish(errorBody: BackendAPIErrorBody): Observable<ErrorPublishResponse> {
    return this.http.post<ErrorPublishResponse>(this.apiUrl, errorBody);
  }
}
