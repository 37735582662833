import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Journal, JournalResponse } from '@app/core/models/journal.models';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  journals: Journal[];
  private readonly logoUrl = 'i/journal_logos/';

  constructor(
    private readonly articleSectionsService: ArticleSectionsService,
    private readonly prosemirrorEditorsService: ProsemirrorEditorsService
  ) {}

  ngOnInit(): void {
    this.articleSectionsService.getJournals().subscribe((journalsData: JournalResponse) => {
      if (journalsData) {
        this.journals = journalsData.data;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.prosemirrorEditorsService.spinning) {
      this.prosemirrorEditorsService.stopSpinner();
    }
  }

  getLogoUrl(journal: Journal): string {
    return `${journal.journal_url}${this.logoUrl}${journal.logo}`;
  }
}
