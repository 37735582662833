import { HttpRequest } from '@angular/common/http';
import { BaseCustomError } from './base-custom.error';
import { CustomErrorType, NetworkErrorDetails } from './models';

export class NetworkError extends BaseCustomError {
  public static message = 'It seems you are offline. Please check your connection and try again.';

  public customErrorType: CustomErrorType.network;
  public timestamp: number;
  public details: NetworkErrorDetails;

  constructor(public request: HttpRequest<unknown>) {
    const timestamp = Date.now();
    super(NetworkError.message, timestamp);
    this.customErrorType = CustomErrorType.network;
    this.timestamp = timestamp;
    this.details = this.extractRequestMetadata(request);
  }

  private extractRequestMetadata(request: HttpRequest<unknown>): NetworkErrorDetails {
    return {
      url: request.url,
      method: request.method,
      headers: this.extractHeaders(request),
      body: request.body,
    };
  }

  private extractHeaders(request: HttpRequest<unknown>): Record<string, string> {
    return request.headers.keys().reduce(
      (accumulator, key) => ({
        ...accumulator,
        [key]: request.headers.get(key) || '',
      }),
      {}
    );
  }
}
