import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';

import { Version, VersionChange, VersionUserInfo } from '@app/core/models/version.models';
import { RequestErrorDialogComponent } from '@app/editor/dialogs/request-error-dialog/request-error-dialog.component';
import { CollaboratorAnonymizationService } from '@app/editor/services/collaborator-anonymization/collaborator-anonymization.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit, AfterViewInit {
  @ViewChild('versionContainer', { read: ElementRef }) versionContainer: ElementRef;

  @Input() version: Version;
  @Input() versions: Version[];

  users = [];
  currentVersionTime: number;

  errorMessage = 'Choosed version does not exist!';

  constructor(
    private sharedService: ServiceShare,
    private ydocService: YdocService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private anonymizer: CollaboratorAnonymizationService,
    private dialog: MatDialog,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}

  get canRestoreVersion(): boolean {
    return !this.ydocService.currUser.allowed_article_versions.length;
  }

  ngOnInit(): void {
    if (this.version.currentVersion) {
      this.currentVersionTime = new Date().getTime();

      const nextVersionChanges = this.ydocService.ydoc.getMap('nextVersionChanges');
      this.users = (nextVersionChanges.get('users') as VersionUserInfo[]) || [];

      nextVersionChanges.observe(() => {
        this.users = (nextVersionChanges.get('users') as VersionUserInfo[]) || [];
        this.currentVersionTime = new Date().getTime();
        this.changeDetector.detectChanges();
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.ydocService.lastSelectedVersion == this.version.index) {
      this.versionContainer.nativeElement?.classList.add('selected');
      setTimeout(() => {
        const element = document.querySelector('.all-versions-container') as HTMLElement;
        if (element) {
          element.scrollTop = this.versionContainer.nativeElement?.offsetTop;
        }
      }, 100);
    } else {
      this.versionContainer.nativeElement?.classList.remove('selected');
    }
    if (this.version.currentVersion && !this.sharedService.isArticleVersionPreview) {
      this.versionContainer.nativeElement?.classList.add('selected');
    }
    this.changeDetector.detectChanges();
  }

  getAnonymizedUserName(userId: string, userName: string): string {
    return this.anonymizer.getDisplayName(userId, userName);
  }

  selectVersion(version: Version): void {
    if (this.ydocService.lastSelectedVersion != version.index) {
      this.prosemirrorEditorsService.spinSpinner();
      this.checkIfExist(version).subscribe((exist: boolean) => {
        if (exist) {
          (document.querySelector('.version-wrapper .selected') as HTMLElement)?.classList?.remove(
            'selected'
          );
          this.versionContainer.nativeElement?.classList.add('selected');
          this.ydocService.versionSubject.next(VersionChange.reRender);
          this.router.navigate([`${this.ydocService.roomName.split('/')[0]}`], {
            fragment: `${version.index}`,
          });
        } else {
          this.dialog
            .open(RequestErrorDialogComponent, {
              width: '563px',
              data: { message: 'Access to this resource is denied!' },
            })
            .afterClosed()
            .subscribe(() => this.prosemirrorEditorsService.stopSpinner());
        }
      });
    }
  }

  restoreVersion(event: Event, version: Version): void {
    event.stopImmediatePropagation();
    this.prosemirrorEditorsService.spinSpinner();

    window.indexedDB.deleteDatabase(this.ydocService.roomName.split('/')[0]);

    this.checkIfExist(version)
      .pipe(
        switchMap((exists) => {
          if (!exists) {
            return of(null);
          }

          return this.sharedService.httpClient.post(
            `${this.appConfig.apiUrl}/article-storage/article/${this.ydocService.articleData.uuid}/restore/${version.index}`,
            {}
          );
        })
      )
      .subscribe({
        next: (response: unknown) => {
          if (!response) {
            this.dialog
              .open(RequestErrorDialogComponent, {
                width: '563px',
                data: { message: 'Access to this resource is denied!' },
              })
              .afterClosed()
              .subscribe(() => this.prosemirrorEditorsService.stopSpinner());
            return;
          }
          this.ydocService.lastSelectedVersion = undefined;
          this.router.navigate([`${this.ydocService.roomName.split('/')[0]}`]);
          this.ydocService.versionSubject.next(VersionChange.reconnect);
          this.ydocService.lastSelectedVersion = undefined;
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
  }

  checkIfExist(version: Version): Observable<boolean> {
    return this.sharedService.httpClient
      .get<{
        result: boolean;
      }>(
        `${this.appConfig.apiUrl}/article-storage/article/${this.ydocService.articleData.uuid}/version/${version.index}/exists`
      )
      .pipe(
        map((response) => response.result),
        catchError(() => {
          return of(false);
        })
      );
  }

  returnToNewestVersion(): void {
    this.ydocService.returnToNewestVersion();
  }
}
