import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Map } from 'yjs';

import { YdocService } from '../../services/ydoc.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { AddFigureDialogV2Component } from './add-figure-dialog-v2/add-figure-dialog-v2.component';
import { AskBeforeDeleteComponent } from '../ask-before-delete/ask-before-delete.component';
import { Figure } from '@app/editor/services/citable-elements.models';

@Component({
  selector: 'app-figures-dialog',
  templateUrl: './figures-dialog.component.html',
  styleUrls: ['./figures-dialog.component.scss'],
})
export class FiguresDialogComponent {
  figuresMap?: Map<any>;
  figuresNumbers?: string[];
  figures?: { [key: string]: Figure };
  editedFigures: { [key: string]: boolean } = {};
  deletedFigures: string[] = [];

  constructor(
    public ydocService: YdocService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FiguresDialogComponent>,
    private serviceShare: ServiceShare
  ) {
    let figuresNumbersArray = ydocService.figuresMap!.get('ArticleFiguresNumbers');
    let figures = ydocService.figuresMap!.get('ArticleFigures');
    this.figuresNumbers = JSON.parse(JSON.stringify(figuresNumbersArray));
    this.figures = JSON.parse(JSON.stringify(figures));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.figuresNumbers!, event.previousIndex, event.currentIndex);
    this.serviceShare.CitableElementsService.writeElementDataGlobal(
      this.figures!,
      this.figuresNumbers,
      'citation'
    );
  }

  editFigure(fig: Figure, figIndex: number) {
    this.dialog
      .open(AddFigureDialogV2Component, {
        data: { fig, updateOnSave: false, index: figIndex, figID: fig.figureID },
        disableClose: this.serviceShare.CitableElementsService.closeOnClickOutsideFigures,
      })
      .afterClosed()
      .subscribe((result: { figure: Figure }) => {
        if (result && result.figure) {
          this.figuresNumbers?.splice(figIndex, 1, result.figure.figureID);
          this.figures![result.figure.figureID] = result.figure;
          this.editedFigures[result.figure.figureID] = true;
          this.serviceShare.CitableElementsService.writeElementDataGlobal(
            this.figures!,
            this.figuresNumbers,
            'citation'
          );
        } else {
        }
      });
  }

  deleteFigure(fig: Figure, figIndex: number) {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { type: 'figure', dontshowType: true, objName: 'Figure №' + (figIndex + 1) },
      panelClass: 'ask-before-delete-dialog',
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.figuresNumbers?.splice(figIndex, 1);
        delete this.figures![fig.figureID];
        if (this.editedFigures[fig.figureID]) {
          delete this.editedFigures[fig.figureID];
        }
        this.serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.figures!,
          this.figuresNumbers,
          'citation'
        );
      }
    });
  }

  addFigure() {
    this.dialog
      .open(AddFigureDialogV2Component, {
        data: { fig: undefined, updateOnSave: false, index: this.figuresNumbers?.length },
        disableClose: this.serviceShare.CitableElementsService.closeOnClickOutsideFigures,
      })
      .afterClosed()
      .subscribe((result: { figure: Figure }) => {
        if (result && result.figure) {
          this.figuresNumbers?.push(result.figure.figureID);
          this.figures![result.figure.figureID] = result.figure;
          this.serviceShare.CitableElementsService.writeElementDataGlobal(
            this.figures!,
            this.figuresNumbers,
            'citation'
          );
        }
      });
  }

  saveFigures() {
    this.dialogRef.close(true);
  }

  deleteAll() {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { objName: 'all', type: 'figures' },
      panelClass: 'ask-before-delete-dialog',
      width: '300px !important',
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.figuresNumbers = [];
        Object.keys(this.editedFigures).forEach((figID) => {
          if (this.editedFigures[figID]) {
            delete this.editedFigures[figID];
          }
        });
        this.figures = {};
        this.serviceShare.CitableElementsService.writeElementDataGlobal(
          this.figures,
          this.figuresNumbers,
          'citation'
        );
      }
    });
  }

  cancelFiguresEdit() {
    this.dialogRef.close();
  }
}
