import { createReducer, on } from '@ngrx/store';
import * as CommentsActions from './comments.actions';
import { CommentsState } from './models';

export const initialState: CommentsState = {
  filter: {
    showResolved: false,
    byCreators: [],
    iAmMentioned: false,
    search: '',
  },
  lastSelectedComment: {},
  filteredComments: [],
};

export const commentsReducer = createReducer(
  initialState,
  on(CommentsActions.setShowResolved, (state, { showResolved }) => ({
    ...state,
    filter: { ...state.filter, showResolved },
  })),
  on(
    CommentsActions.setLastSelectedComment,
    (state, { commentId, pos, sectionId, commentMarkId, focus }) => ({
      ...state,
      lastSelectedComment: { commentId, pos, sectionId, commentMarkId, focus },
    })
  ),
  on(CommentsActions.clearLastSelectedComment, (state) => ({
    ...state,
    lastSelectedComment: {},
  })),
  on(CommentsActions.deselectAllComments, (state) => ({
    ...state,
    lastSelectedComment: {},
  })),
  on(CommentsActions.updateCommentsFilter, (state, { filter }) => ({
    ...state,
    filter: { ...state.filter, ...filter },
  })),
  on(CommentsActions.setCommentsFilter, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(CommentsActions.setFilteredComments, (state, { comments }) => ({
    ...state,
    filteredComments: comments,
  }))
);
