import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommentsState } from './models';

export const selectCommentsState = createFeatureSelector<CommentsState>('comments');

export const selectShowResolved = createSelector(
  selectCommentsState,
  (state) => state.filter.showResolved
);

export const selectLastSelectedComment = createSelector(
  selectCommentsState,
  (state: CommentsState) => {
    return state.lastSelectedComment;
  }
);

export const hasActiveFilter = createSelector(selectCommentsState, (state: CommentsState) => {
  const { filter } = state;

  return (
    filter.showResolved ||
    filter.iAmMentioned ||
    filter.search.length > 0 ||
    filter.byCreators.some((creator) => creator)
  );
});

export const selectFilteredComments = createSelector(
  selectCommentsState,
  (state) => state.filteredComments
);
