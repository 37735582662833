import { Injectable } from '@angular/core';
import { AwtError } from '../models';
import { RuntimeError } from '../custom-errors/runtime-error';
import { CustomError } from '../custom-errors/models';
import { ZoneError } from '../custom-errors/zone-error';
import { UnknownError } from '../custom-errors/unknown-error';
import { StoredError } from '../models/stored-error.model';
import { HttpError } from '../custom-errors/http-error';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ErrorFactoryService {
  constructor() {}

  createError(error: CustomError | AwtError | (() => CustomError | AwtError)): CustomError {
    if (typeof error === 'function') {
      return this.createError(error());
    }

    if (this.isCustomError(error)) {
      return error;
    }

    let customError: CustomError;

    if (error instanceof HttpErrorResponse) {
      customError = new HttpError(error, 'unknown');
    } else if (error instanceof Error) {
      if (error.name === 'ZoneAwareError' || error.message.includes('Zone')) {
        customError = new ZoneError(error);
      } else {
        customError = new RuntimeError(error, 'unknown');
      }
    } else if (error instanceof globalThis.ErrorEvent) {
      customError = new RuntimeError(new Error(error.message), error.filename || 'unknown');
    } else {
      customError = new UnknownError(error);
    }

    return customError;
  }

  createConstructorError(error: Error, serviceName: string): StoredError {
    return {
      ...this.createError(error),
      serviceName,
    };
  }

  private isCustomError(error: AwtError | CustomError): error is CustomError {
    return 'customErrorType' in error;
  }
}
