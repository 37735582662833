import { createAction, props } from '@ngrx/store';
import { CommentsState, CommentsFilter } from './models';

export const setShowResolved = createAction(
  '[Comments] Set Show Resolved',
  props<{ showResolved: boolean }>()
);

export const setLastSelectedComment = createAction(
  '[Comments] Set Last Selected Comment',
  props<CommentsState['lastSelectedComment']>()
);

export const clearLastSelectedComment = createAction('[Comments] Clear Last Selected Comment');

export const deselectAllComments = createAction('[Comments] Deselect All Comments');

export const updateCommentsFilter = createAction(
  '[Comments] Update Comments Filter',
  props<{ filter: Partial<CommentsFilter> }>()
);

export const setCommentsFilter = createAction(
  '[Comments] Set Comments Filter',
  props<{ filter: CommentsFilter }>()
);

export const setFilteredComments = createAction(
  '[Comments] Set Filtered Comments',
  props<{ comments: CommentsState['filteredComments'] }>()
);
