import { CustomErrorType } from './models';
import { BaseCustomError } from './base-custom.error';

export class ZoneError extends BaseCustomError {
  public customErrorType: CustomErrorType.zone;
  public timestamp: number;
  public details: {
    originalError: Error;
    zoneName: string;
    userMessage: string;
  };

  constructor(
    public originalError: Error,
    public zoneName: string = 'unknown',
    public userMessage: string = 'An application error occurred.'
  ) {
    const timestamp = Date.now();
    super(`Zone.js Error: ${originalError.message}`, timestamp);
    this.customErrorType = CustomErrorType.zone;
    this.timestamp = timestamp;
    this.details = {
      originalError,
      zoneName,
      userMessage,
    };
    this.stack = originalError.stack;
  }
}
