import { Inject, Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { Script, ScriptRecord, ScriptLoader } from './script-loader.models';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService implements ScriptLoader {
  nonce = '';
  // Map keyed by script name. Each entry contains the script info and a ReplaySubject.
  private loadedScripts = new Map<string, ScriptRecord>();

  constructor(
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.nonce = this.generateNonce();
  }

  /**
   * Loads a single script by name and source URL.
   * If the script is already loading or loaded, returns the cached observable.
   *
   * @param name A unique identifier for the script.
   * @param src The URL of the script.
   */
  loadScript(name: string, src: string): Observable<void> {
    return this.ngZone.runOutsideAngular(() => {
      if (this.loadedScripts.has(name)) {
        return this.loadedScripts.get(name)!.subject.asObservable();
      }

      const subject = new ReplaySubject<void>(1);
      const scriptData: Script = { name, src, loaded: false };
      this.loadedScripts.set(name, { script: scriptData, subject });

      const scriptElement = this.document.createElement('script');
      scriptElement.src = src;
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.nonce = this.nonce;

      scriptElement.onload = () => {
        this.ngZone.run(() => {
          const record = this.loadedScripts.get(name);
          if (record) {
            record.script.loaded = true;
          }
          subject.next();
          subject.complete();
        });
      };

      scriptElement.onerror = () => {
        this.ngZone.run(() => {
          subject.error(`Failed to load script ${name} from ${src}`);
        });
      };

      this.document.head.appendChild(scriptElement);

      return subject.asObservable();
    });
  }

  /**
   * Loads multiple scripts concurrently.
   *
   * @param scripts An array of objects each containing a name and src.
   * @returns An Observable that emits an array of void values once all scripts are loaded.
   */
  loadScripts(scripts: { name: string; src: string }[]): Observable<void[]> {
    const observables = scripts.map((script) => this.loadScript(script.name, script.src));
    return forkJoin(observables);
  }

  /**
   * Generates a random nonce value for script security.
   */
  private generateNonce(): string {
    return Array.from(crypto.getRandomValues(new Uint8Array(16)))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  }
}
