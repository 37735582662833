import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandler } from '../global-error-handler/global-error-handler';
import { HttpError } from '../custom-errors/http-error';
import { RetryService } from '../services/retry-service/retry.service';
import { BroadcasterService } from '../../services/broadcaster.service';
import { CONSTANTS } from '../../services/constants';
import { NetworkError } from '../custom-errors/network-error';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly errorHandler: GlobalErrorHandler,
    private readonly retryService: RetryService,
    private broadcaster: BroadcasterService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | (() => HttpErrorResponse)) => {
        if (typeof error === 'function') {
          error = error();
        }

        if (error.status === 0 && !navigator.onLine) {
          const networkError = new NetworkError(request);
          this.errorHandler.handleNetworkError(networkError);
          this.broadcaster.broadcast(CONSTANTS.ERROR, {
            error: networkError.message,
            timeout: 5000,
          });
          return throwError(() => networkError);
        }

        const isAuthError = [401, 403].includes(error.status);
        if (isAuthError) {
          return throwError(() => error);
        }

        if (this.retryService.shouldRetry(request)) {
          return this.retryService.retryWithConfig(request)(next.handle(request));
        }

        const customError = new HttpError(error, request.method);
        this.errorHandler.handleHttpError(customError);
        this.broadcaster.broadcast(CONSTANTS.ERROR, {
          error: 'Something went wrong',
          timeout: 5000,
        });
        return throwError(() => customError);
      })
    );
  }
}
