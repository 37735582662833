import { HttpErrorResponse } from '@angular/common/http';
import {
  HttpErrorDetails,
  NetworkErrorDetails,
  ProsemirrorErrorDetails,
  RuntimeErrorDetails,
  UiErrorDetails,
  UnknownErrorDetails,
  ZoneErrorDetails,
} from './custom-errors/models';

export type CustomErrorDetails =
  | HttpErrorDetails
  | ProsemirrorErrorDetails
  | UiErrorDetails
  | RuntimeErrorDetails
  | NetworkErrorDetails
  | ZoneErrorDetails
  | UnknownErrorDetails;

export type OtherErrorDetails = unknown;

export type ErrorDetails = CustomErrorDetails | OtherErrorDetails;

export enum ServiceType {
  frontend = 'frontend',
  backend = 'backend',
}

// The expected format by the backend API
export interface BackendAPIErrorBody {
  action: 'sendSystemErrorNotification';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_id: string | number;
  message: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: string;
  timestamp: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_type: ServiceType;
  details: CustomErrorDetails;
}

export type AwtError = Error | HttpErrorResponse | globalThis.ErrorEvent;

export type ErrorPublishResponse = unknown;
