import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Renders HTML content safely by sanitizing it
 * @param value - The HTML string to render
 * @returns SafeHtml that can be rendered in templates
 */
@Pipe({
  name: 'renderHtml',
})
export class RenderHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) {
      return '';
    }

    return this.sanitizer.sanitize(SecurityContext.HTML, value) || '';
  }
}
