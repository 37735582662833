/* eslint-disable spellcheck/spell-checker */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { YdocService } from '@app/editor/services/ydoc.service';
import { UserInfo } from '@app/editor/comments-section/comment.models';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorAnonymizationService {
  private anonymizationChanges$ = new BehaviorSubject<void>(null);

  constructor(private ydocService: YdocService) {
    this.ydocService.collaboratorsSubject.subscribe(() => {
      this.anonymizationChanges$.next();
    });
  }

  shouldAnonymizeUser(userId: string): boolean {
    if (!userId) return false;

    const currentUser = this.ydocService.currUser;
    if (!currentUser) return false;

    const collaborator = this.ydocService
      .getCollaborators()
      .find((c) => c.id === userId || c.user_id === userId);

    if (!collaborator) return false;

    return (
      collaborator.anonymize_me_from?.includes(currentUser.auth_role) ||
      collaborator.anonymize_me_from?.includes(currentUser.id)
    );
  }

  shouldHideComments(userId: string): boolean {
    if (!userId) return false;

    const currentUser = this.ydocService.currUser;
    if (!currentUser) return false;

    const collaborator = this.ydocService
      .getCollaborators()
      .find((c) => c.id === userId || c.user_id === userId);

    if (!collaborator) return false;

    return (
      collaborator.hide_my_comments_from_user?.includes(currentUser.auth_role) ||
      collaborator.hide_my_comments_from_user?.includes(currentUser.id)
    );
  }

  shouldHideChangePropositions(userId: string): boolean {
    // Track changes use the same hiding rules as comments
    return this.shouldHideComments(userId);
  }

  getDisplayName(userId: string, defaultName: string): string {
    if (!this.shouldAnonymizeUser(userId)) {
      return defaultName;
    }

    const collaborator = this.ydocService
      .getCollaborators()
      .find((c) => c.id === userId || c.user_id === userId);

    if (!collaborator) return defaultName;

    // Use the backend-provided anonymized_name if available
    return collaborator.anonymized_name || 'Anonymized';
  }

  getHiddenCommentsUserIds(): string[] {
    const currentUser = this.ydocService.currUser;
    if (!currentUser) return [];

    return this.ydocService
      .getCollaborators()
      .filter((c) => c.id !== currentUser.id && c.user_id !== currentUser.id)
      .filter(
        (c) =>
          c.hide_my_comments_from_user?.includes(currentUser.auth_role) ||
          c.hide_my_comments_from_user?.includes(currentUser.id)
      )
      .map((c) => c.id || c.user_id);
  }

  getHiddenChangePropositionUserIds(): string[] {
    // Track changes use the same hiding rules as comments
    return this.getHiddenCommentsUserIds();
  }

  getAnonymizationChanges(): Observable<void> {
    return this.anonymizationChanges$.asObservable();
  }

  getAnonymizedUserInfo(userInfo: UserInfo): UserInfo {
    if (!this.shouldAnonymizeUser(userInfo.id)) {
      return userInfo;
    }

    const collaborator = this.ydocService
      .getCollaborators()
      .find((c) => c.id === userInfo.id || c.user_id === userInfo.id);

    if (!collaborator) {
      return userInfo;
    }

    return {
      ...userInfo,
      name: this.getDisplayName(userInfo.id, userInfo.name),
    };
  }
}
