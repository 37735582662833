import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Node } from 'prosemirror-model';
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import { AskBeforeDeleteComponent } from '@app/editor/dialogs/ask-before-delete/ask-before-delete.component';
import { AddTableDialogComponent } from '@app/editor/dialogs/citable-tables-dialog/add-table-dialog/add-table-dialog.component';
import { AddEndNoteComponent } from '@app/editor/dialogs/end-notes/add-end-note/add-end-note.component';
import { AddFigureDialogV2Component } from '@app/editor/dialogs/figures-dialog/add-figure-dialog-v2/add-figure-dialog-v2.component';
import { AddSupplementaryFileComponent } from '@app/editor/dialogs/supplementary-files/add-supplementary-file/add-supplementary-file.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { citationElementMap } from '@app/editor/services/citable-elements.models';

interface buttonsActions {
  moveElementUpFnc: () => void;
  moveElementDownFnc: () => void;
  editElementFnc: () => void;
  deleteElementFnc: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class CitableElementsEditButtonsService {
  citableElementsEditButtonsPluginKey = new PluginKey('citableElementsEditButtonsPlugin');
  citableElementsEditButtonsPlugin: Plugin;

  citableElementsBlockNodesNames = [
    'block_figure',
    'block_table',
    'block_supplementary_file',
    'block_end_note',
  ];
  citationElementsNodeNames = [
    'citation',
    'supplementary_file_citation',
    'table_citation',
    'end_note_citation',
    'reference_citation',
  ];

  elementsMaps = {
    block_figure: {
      idProp: 'figure_id',
      yjsMap: 'figuresMap',
      elementsObj: 'ArticleFigures',
      numberPropInObj: 'figureNumber',
      elementNumbersObj: 'ArticleFiguresNumbers',
      elementEditComponent: AddFigureDialogV2Component,
      closeOnClickOutside: true,
      getDialogData: function (item: any, itemIndex: number, itemId: string) {
        return { fig: item, updateOnSave: false, index: itemIndex, figID: itemId };
      },
      resultElProp: 'figure',
      elementCitationName: 'citation',
      name: 'Figure',
    },
    block_table: {
      idProp: 'table_id',
      yjsMap: 'tablesMap',
      elementsObj: 'ArticleTables',
      numberPropInObj: 'tableNumber',
      elementNumbersObj: 'ArticleTablesNumbers',
      elementEditComponent: AddTableDialogComponent,
      closeOnClickOutside: true,
      getDialogData: function (item: any, itemIndex: number, itemId: string) {
        return { table: item, updateOnSave: false, index: itemIndex, tableID: itemId };
      },
      resultElProp: 'table',
      elementCitationName: 'table_citation',
      name: 'Table',
    },
    block_supplementary_file: {
      idProp: 'supplementary_file_id',
      yjsMap: 'supplementaryFilesMap',
      elementNumbersObj: 'supplementaryFilesNumbers',
      elementsObj: 'supplementaryFiles',
      numberPropInObj: 'supplementary_file_number',
      elementEditComponent: AddSupplementaryFileComponent,
      closeOnClickOutside: true,
      getDialogData: function (item: any, itemIndex: number, itemId: string) {
        return {
          supplementaryFile: item,
          updateOnSave: false,
          index: itemIndex,
          supplementaryFileID: itemId,
        };
      },
      resultElProp: 'supplementaryFile',
      elementCitationName: 'supplementary_file_citation',
      name: 'Suppl. material',
    },
    block_end_note: {
      idProp: 'end_note_id',
      yjsMap: 'endNotesMap',
      elementsObj: 'endNotes',
      numberPropInObj: 'end_note_number',
      elementNumbersObj: 'endNotesNumbers',
      elementEditComponent: AddEndNoteComponent,
      closeOnClickOutside: true,
      getDialogData: function (item: any, itemIndex: number, itemId: string) {
        return { endNote: item, updateOnSave: false, index: itemIndex, endNoteID: itemId };
      },
      resultElProp: 'endNote',
      elementCitationName: 'end_note_citation',
      name: 'Note',
    },
  };

  elementActions: buttonsActions = {
    moveElementUpFnc: () => {},
    moveElementDownFnc: () => {},
    editElementFnc: () => {},
    deleteElementFnc: () => {},
  };
  ctableElementButtonsClasses = [
    'move-citable-item-up-button',
    'move-citable-item-down-button',
    'edit-citable-item-button',
    'delete-citable-item-button',
  ];

  setListeners(
    dontRenderMoveUpButton: boolean,
    dontRenderMoveDownButton: boolean,
    moveElementUpBtn: HTMLDivElement,
    moveElementDownBtn: HTMLDivElement,
    fnsObj: buttonsActions
  ) {
    if (!dontRenderMoveUpButton) {
      moveElementUpBtn.style.display = 'block';
    } else {
      moveElementUpBtn.style.display = 'none';
    }

    if (!dontRenderMoveDownButton) {
      moveElementDownBtn.style.display = 'block';
    } else {
      moveElementDownBtn.style.display = 'none';
    }

    this.elementActions.moveElementUpFnc = fnsObj.moveElementUpFnc;
    this.elementActions.moveElementDownFnc = fnsObj.moveElementDownFnc;
    this.elementActions.editElementFnc = fnsObj.editElementFnc;
    this.elementActions.deleteElementFnc = fnsObj.deleteElementFnc;
  }

  getElTypeFromPath(path: any[]) {
    let counter = 0;
    let citableElementTag: string = undefined;
    let el: Node = undefined;
    let elPos: number = undefined;
    while (!citableElementTag && counter < path.length) {
      let node = path[counter];
      let nodeTag = node.type.name;
      if (this.citableElementsBlockNodesNames.includes(nodeTag)) {
        citableElementTag = nodeTag;
        el = node;
        elPos = path[counter - 1];
      }
      counter += 3;
    }
    return { citableElementTag, el, elPos };
  }

  constructor(
    private serviceShare: ServiceShare,
    public dialog: MatDialog
  ) {
    const self = this;

    this.serviceShare.YdocService.ydocStateObservable.subscribe(({ event }) => {
      if (event == 'docIsBuild') {
        this.elementsMaps.block_end_note.closeOnClickOutside =
          this.serviceShare.CitableElementsService.closeOnClickOutsideFootNotes;
        this.elementsMaps.block_figure.closeOnClickOutside =
          this.serviceShare.CitableElementsService.closeOnClickOutsideFigures;
        this.elementsMaps.block_supplementary_file.closeOnClickOutside =
          this.serviceShare.CitableElementsService.closeOnClickOutsideSupplementaryFiles;
        this.elementsMaps.block_table.closeOnClickOutside =
          this.serviceShare.CitableElementsService.closeOnClickOutsideTables;
      }
    });

    this.citableElementsEditButtonsPlugin = new Plugin({
      key: this.citableElementsEditButtonsPluginKey,
      state: {
        init: (_: any, state) => {
          return { sectionName: _.sectionName };
        },
        apply(tr, prev, _, newState) {
          return prev;
        },
      },
      props: {
        handleDOMEvents: {
          blur: (view, event) => {
            if (
              event.relatedTarget &&
              event.relatedTarget instanceof HTMLButtonElement &&
              this.ctableElementButtonsClasses.includes(event.relatedTarget.className)
            ) {
              event.relatedTarget.click();
            }
          },
        },
        handleClickOn(view: EditorView, pos: number, node: Node, _, event) {
          const { state } = view;
          const { schema } = state;

          if (node.type === schema.nodes.figure_component) {
            const $pos = state.doc.resolve(pos);
            const selection = TextSelection.create(view.state.doc, $pos.before());
            view.dispatch(state.tr.setSelection(selection));
            return true;
          } else if (
            node.type.name == 'figure_block' ||
            node.type.name == 'figure_components_container'
          ) {
            return true;
          }
        },
      },
      view: function (view: EditorView) {
        return {
          update: (view: EditorView) => {
            const pluginState = self.citableElementsEditButtonsPluginKey.getState(view.state);
            const focusedEditor = serviceShare.DetectFocusService.sectionName;
            const currentEditor = pluginState.sectionName;
            const $pos = view.state.doc.resolve(view.state.selection.$anchor.pos);
            const { node } = $pos.parent.childAfter($pos.parentOffset);
            // change logic to search mark
            if (
              node &&
              node.marks?.find((mark) => this.citationElementsNodeNames?.includes(mark.type?.name))
            ) {
              return;
            }
            if (currentEditor != focusedEditor) return;

            self.attachButtons(view);
          },
          destroy: () => {},
        };
      },
    });
  }

  attachButtons(view: EditorView) {
    //@ts-ignore
    const path = view.state.selection.$from.path;
    const { citableElementTag, el, elPos } = this.getElTypeFromPath(path);
    const { from, to } = view.state.selection;

    const btnsWrapper = document.getElementsByClassName(
      'editor_buttons_wrapper'
    )[0] as HTMLDivElement;
    if (!btnsWrapper) return;
    const editCitableElementsContainer = btnsWrapper.getElementsByClassName(
      'citable-elements-edit-btn-container'
    )[0] as HTMLDivElement;
    const deleteCitationBtnContainer = btnsWrapper.getElementsByClassName(
      'delete-citable-el-btn-container'
    )[0] as HTMLDivElement;
    const moveElementUpContainer = btnsWrapper.getElementsByClassName(
      'move-up-btn-container'
    )[0] as HTMLDivElement;
    const moveElementDownContainer = btnsWrapper.getElementsByClassName(
      'move-down-btn-container'
    )[0] as HTMLDivElement;

    if (!editCitableElementsContainer) return;

    if (citableElementTag && from == to) {
      editCitableElementsContainer.style.display = 'block';
      deleteCitationBtnContainer.style.display = 'block';
      const editBtn = editCitableElementsContainer.getElementsByClassName(
        'edit-citable-el-button'
      )[0] as HTMLButtonElement;
      const deleteBtn = deleteCitationBtnContainer.getElementsByClassName(
        'delete-citable-el-btn'
      )[0] as HTMLButtonElement;
      const moveUpBtn = moveElementUpContainer.getElementsByClassName(
        'move-up-btn'
      )[0] as HTMLButtonElement;
      const moveDownBtn = moveElementDownContainer.getElementsByClassName(
        'move-down-btn'
      )[0] as HTMLButtonElement;

      const elementMap = this.elementsMaps[citableElementTag];
      const elementId = el.attrs[elementMap.idProp];

      const elementYjsMap = this.serviceShare.YdocService[elementMap.yjsMap];
      const elementsObj = elementYjsMap.get(elementMap.elementsObj);
      const elementsNumbersArr = elementYjsMap.get(elementMap.elementNumbersObj) as string[];

      const elementNumber = elementsNumbersArr.indexOf(elementId);
      const elIsFirstInOrder = elementNumber == 0;
      const elIsLastInOrder = elementNumber == elementsNumbersArr.length - 1;

      editBtn.removeAllListeners('click');
      deleteBtn.removeAllListeners('click');
      moveUpBtn.removeAllListeners('click');
      moveDownBtn.removeAllListeners('click');

      this.setListeners(
        elIsFirstInOrder,
        elIsLastInOrder,
        moveElementUpContainer,
        moveElementDownContainer,
        {
          moveElementUpFnc: () => {
            let elementNumbersArrCopy = JSON.parse(JSON.stringify(elementsNumbersArr)) as string[];
            let id = elementNumbersArrCopy.splice(elementNumber, 1);
            elementNumbersArrCopy.splice(elementNumber - 1, 0, ...id);
            this.serviceShare.CitableElementsService.writeElementDataGlobal(
              elementsObj,
              elementNumbersArrCopy,
              elementMap.elementCitationName
            );
            view.dispatch(
              view.state.tr.setSelection(TextSelection.create(view.state.doc, from - el.nodeSize))
            );
          },
          moveElementDownFnc: () => {
            let elementNumbersArrCopy = JSON.parse(JSON.stringify(elementsNumbersArr)) as string[];
            let id = elementNumbersArrCopy.splice(elementNumber, 1);
            elementNumbersArrCopy.splice(elementNumber + 1, 0, ...id);
            this.serviceShare.CitableElementsService.writeElementDataGlobal(
              elementsObj,
              elementNumbersArrCopy,
              elementMap.elementCitationName
            );
            view.dispatch(
              view.state.tr.setSelection(TextSelection.create(view.state.doc, to + el.nodeSize))
            );
          },
          editElementFnc: () => {
            this.dialog
              .open(elementMap.elementEditComponent, {
                data: elementMap.getDialogData(elementsObj[elementId], elementNumber, elementId),
                disableClose: !elementMap.closeOnClickOutside,
              })
              .afterClosed()
              .subscribe((result: any) => {
                if (result) {
                  let editedItem = result[elementMap.resultElProp];
                  let elementObjCopy = JSON.parse(JSON.stringify(elementsObj));
                  elementObjCopy[elementId] = editedItem;
                  if (elementMap.resultElProp == 'table') {
                    Object.keys(elementObjCopy).forEach((key) => {
                      this.serviceShare.CitableElementsService.deletedTablesForRerender[key] =
                        citationElementMap['table_citation'].getElFormIOSubmission(
                          elementObjCopy[key],
                          undefined,
                          this.serviceShare
                        );
                    });
                  }
                  this.serviceShare.CitableElementsService.writeElementDataGlobal(
                    elementObjCopy,
                    elementsNumbersArr,
                    elementMap.elementCitationName,
                    editedItem?.editMode
                  );
                } else {
                  this.serviceShare.ProsemirrorEditorsService.editMode = false;
                }
              });
          },
          deleteElementFnc: () => {
            const objName =
              elementMap.name === this.elementsMaps.block_end_note.name
                ? `*${elementNumber + 1}`
                : `${elementMap.name} №${elementNumber + 1}`;

            let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
              data: { objName, type: elementMap.resultElProp, dontshowType: true },
              panelClass: 'ask-before-delete-dialog',
            });
            dialogRef.afterClosed().subscribe((data: any) => {
              if (data) {
                let elementObjCopy = JSON.parse(JSON.stringify(elementsObj));
                let elementNumsCopy = JSON.parse(JSON.stringify(elementsNumbersArr));
                if (elementObjCopy[elementId]) {
                  delete elementObjCopy[elementId];
                }
                if (elementMap.resultElProp == 'table') {
                  delete this.serviceShare.CitableElementsService.deletedTablesForRerender[
                    elementObjCopy.tableID
                  ];
                }
                elementNumsCopy.splice(elementNumber, 1);
                this.serviceShare.CitableElementsService.writeElementDataGlobal(
                  elementObjCopy,
                  elementNumsCopy,
                  elementMap.elementCitationName
                );
                this.serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
                editCitableElementsContainer.style.display = 'none';
                deleteCitationBtnContainer.style.display = 'none';
                moveElementUpContainer.style.display = 'none';
                moveElementDownContainer.style.display = 'none';
              }
            });
          },
        }
      );

      editBtn.addEventListener('click', () => this.elementActions.editElementFnc());
      deleteBtn.addEventListener('click', () => this.elementActions.deleteElementFnc());
      moveUpBtn.addEventListener('click', () => this.elementActions.moveElementUpFnc());
      moveDownBtn.addEventListener('click', () => this.elementActions.moveElementDownFnc());
      return;
    } else {
      editCitableElementsContainer.style.display = 'none';
      deleteCitationBtnContainer.style.display = 'none';
      moveElementUpContainer.style.display = 'none';
      moveElementDownContainer.style.display = 'none';
    }
  }
}
