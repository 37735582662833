import { ChangeDetectorRef, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { TextSelection } from 'prosemirror-state';

import { SharedChangesService } from '../shared-changes/shared-changes.service';
import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';
import { ChangeData } from '../change.models';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Injectable()
export class ChangesNavigationService {
  searchResults: ChangeData[] = [];

  searchForm = new UntypedFormControl('');
  isSelected = false;

  searching: boolean = false;
  searchIndex: number = 0;

  constructor(
    private changesService: TrackChangesService,
    private sharedService: ServiceShare,
    private sharedChangesService: SharedChangesService
  ) {}

  setFormControlChangeListener(changeDetectorRef: ChangeDetectorRef): void {
    this.sharedChangesService.subscription$.add(
      this.searchForm.valueChanges.pipe(debounce(() => interval(700))).subscribe((val: string) => {
        const sortedChanges = this.sharedChangesService.allChanges.sort((c1, c2) => {
          if (c1.domTop != c2.domTop) {
            return c1.domTop - c2.domTop;
          } else {
            return c1.pmDocStartPos - c2.pmDocStartPos;
          }
        });
        if (val && typeof val == 'string' && val != '' && val.trim().length > 0) {
          const searchVal = val.toLocaleLowerCase();

          const foundChanges = sortedChanges.filter(
            (data) =>
              data.changeTxt.toLocaleLowerCase().includes(searchVal) ||
              data.changeAttrs.username.toLocaleLowerCase().includes(searchVal)
          );
          if (foundChanges.length > 0) {
            this.searchResults = foundChanges;
            this.searchIndex = 0;
            this.selectChange(foundChanges[0]);
            this.isSelected = true;
            this.searching = true;
          } else {
            this.searchResults = [];
            this.searchIndex = 0;
            this.isSelected = false;
            this.searching = true;
          }
        } else {
          this.searchResults = sortedChanges;
          this.searching = false;
          this.isSelected = false;
        }
        changeDetectorRef.detectChanges();
      })
    );
  }

  selectChange(change: ChangeData): void {
    const actualMark = this.changesService.changesObj[change.changeMarkId];
    const edView =
      this.sharedService.ProsemirrorEditorsService.editorContainers[actualMark.section].editorView;
    const st = edView.state;
    const doc = st.doc;
    const tr = st.tr;
    const textSelection = new TextSelection(
      doc.resolve(actualMark.pmDocStartPos),
      doc.resolve(actualMark.pmDocStartPos)
    );
    edView.dispatch(tr.setSelection(textSelection));
    const articleElement = document.getElementsByClassName(
      'main-editor-container'
    )[0] as HTMLDivElement;
    articleElement.scroll({
      top: actualMark.domTop - 300,
      left: 0,
      behavior: 'smooth',
    });
    edView.focus();
  }

  endSearch(): void {
    this.searching = false;
    this.searchIndex = 0;
    this.isSelected = false;
    this.searchResults = [];
    this.searchForm.setValue('');
    setTimeout(() => {
      this.changesService.changesChangeSubject$.next('end search');
    }, 10);
  }

  selectPreviousChangeFromSearch(): void {
    this.searchIndex--;
    this.select();
  }

  selectNextChangeFromSearch(): void {
    if ((this.isSelected && this.searchIndex == 0) || this.searchIndex > 0) {
      this.searchIndex++;
    }
    this.select();
  }

  select(): void {
    this.isSelected = true;
    const change = this.searchResults[this.searchIndex];
    this.selectChange(change);
  }
}
