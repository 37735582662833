import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { ErrorService } from '../services/error-service/error.service';
import { CustomError } from '../custom-errors/models';
import { AppConfig, APP_CONFIG } from '@core/services/app-config';
import { ErrorHandlerConfig } from '../custom-errors/models';
import { ERROR_HANDLER_CONFIG } from './error-handler.config';
import { AwtError } from '../models';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseCustomError } from '../custom-errors/base-custom.error';
import { HttpError } from '../custom-errors/http-error';
import { NetworkError } from '../custom-errors/network-error';
import { ErrorFactoryService } from '../error-factory/error-factory.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly errorService: ErrorService,
    private readonly errorFactory: ErrorFactoryService,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    @Inject(ERROR_HANDLER_CONFIG) private readonly config: ErrorHandlerConfig
  ) {}

  public handleError(error: AwtError | CustomError | (() => AwtError | CustomError)): void {
    try {
      if (typeof error === 'function') {
        error = error();
      }

      // Skip HTTP and Network errors as they are handled by their respective interceptors
      if (error instanceof HttpError || error instanceof NetworkError) {
        return;
      }

      const customError = this.isCustomError(error) ? error : this.errorFactory.createError(error);
      this.processError(customError);
    } catch (e) {
      console.error('Error in error handler:', e);
    }
  }

  public handleHttpError(error: HttpError): void {
    try {
      this.processError(error);
    } catch (e) {
      console.error('Error in HTTP error handler:', e);
    }
  }

  public handleNetworkError(error: NetworkError): void {
    try {
      this.processError(error);
    } catch (e) {
      console.error('Error in network error handler:', e);
    }
  }

  public handleStoredError(error: CustomError): void {
    try {
      this.processStoredError(error);
    } catch (e) {
      console.error('Error in stored error handler:', e);
    }
  }

  private processError(error: CustomError): void {
    if (this.shouldLog(error)) {
      this.handleLogging(error);
    }

    if (this.shouldNotifyUser(error)) {
      this.handleNotification(error);
    }

    if (this.shouldSendToBackend(error)) {
      this.handleErrorSending(error);
    }
  }

  private processStoredError(error: CustomError): void {
    if (this.shouldLog(error)) {
      this.handleLogging(error);
    }

    if (this.shouldSendToBackend(error)) {
      this.handleErrorSending(error);
    }
  }

  private shouldLog(error: CustomError): boolean {
    return (
      this.config.loggingConfig.enabled &&
      !this.appConfig.production &&
      !this.config.loggingConfig.excludedTypes?.includes(error.customErrorType)
    );
  }

  private handleLogging(error: CustomError): void {
    this.errorService.log(error);
  }

  private shouldNotifyUser(error: CustomError): boolean {
    return (
      this.config.notificationConfig.enabled &&
      this.config.notificationConfig.includedTypes.includes(error.customErrorType)
    );
  }

  private handleNotification(error: CustomError): void {
    this.errorService.notifyUser(error);
  }

  private shouldSendToBackend(error: CustomError): boolean {
    return (
      this.config.backendPublishConfig.enabled &&
      !this.config.backendPublishConfig.excludedTypes.includes(error.customErrorType)
    );
  }

  private handleErrorSending(error: CustomError | (() => CustomError)): void {
    const errorToSend = typeof error === 'function' ? error() : error;
    this.errorService
      .publishToBackend(errorToSend)
      .pipe(
        catchError((err) => {
          // TODO: ensure these are not caught by the interceptor
          console.error('Failed to send error to backend:', err);
          return of(null);
        })
      )
      .subscribe();
  }

  private isCustomError(error: AwtError | CustomError): error is CustomError {
    return 'customErrorType' in error;
  }
}
