import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { html } from '@codemirror/lang-html';
import { basicSetup, EditorState, EditorView } from '@codemirror/basic-setup';
import { DOMParser, DOMSerializer, Fragment, Node } from 'prosemirror-model';
import { uuidv4 } from 'lib0/random';
import * as Y from 'yjs';

import { schema } from '@app/editor/utils/Schema';
import { tableJson } from '@app/editor/utils/section-templates/form-io-json/citableTableJSON';
import { YdocService } from '@app/editor/services/ydoc.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { filterFieldsValues } from '@app/editor/utils/fieldsMenusAndScemasFns';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { MaterialModule } from '@app/shared/material.module';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { FormioModule } from '@formio/angular';
import { FormControlNameDirective } from '@app/editor/directives/form-control-name.directive';
import { CommonModule } from '@angular/common';
import { updateYFragment } from '../../../../y-prosemirror-src/plugins/sync-plugin';
import { getHtmlFromFragment } from '@app/editor/utils/prosemirrorHelpers/transactionControllingFunctions';
import { FormBuilderService } from '@app/editor/services/form-builder.service';
import { citationElementMap, Table } from '@app/editor/services/citable-elements.models';

@Component({
  selector: 'app-add-table-dialog',
  templateUrl: './add-table-dialog.component.html',
  styleUrls: ['./add-table-dialog.component.scss'],
})
export class AddTableDialogComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;

  initFunc: (selfRef: any) => () => void;
  renderCodemMirrorEditors: (selfRef: any) => (tableID: string) => string;
  onChange: (selfRef: any) => (change: any) => void;
  submitTable: (selfRef: any) => () => void;
  onSubmit: (selfRef: any) => (submission?: any) => void;

  defaultSubmitTable: () => void;
  defaultOnChange: (change: any) => void;
  defaultOnSubmit: (submission?: any) => void;
  renderForm = false;
  hidehtml = true;
  sectionContent = JSON.parse(JSON.stringify(tableJson));
  codemirrorHTMLEditor?: EditorView;
  tablesTemplatesObj: any;
  codemirrorHtmlTemplate: HTMLElement;
  section = { mode: 'editMode' };
  sectionForm = new UntypedFormGroup({});
  tableID?: string;
  modalTemplate: string;
  isValid: boolean = false;
  formIoSubmission: any;
  formIoRoot: any;
  editMode = false;
  updateYdoc: Y.Doc;
  originUpdates = [];

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddTableDialogComponent>,
    private ydocService: YdocService,
    private serviceShare: ServiceShare,
    private formBuilderService: FormBuilderService,
    @Inject(APP_CONFIG) readonly config: AppConfig,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      table: Table | undefined;
      updateOnSave: boolean;
      index: number;
      tableID: string | undefined;
    }
  ) {
    this.prosemirrorEditorsService.editMode = true;

    this.modalTemplate = this.ydocService.tablesMap.get('tablesModalTemplate') as string;
    const self = this;
    this.initFunc = (selfRef: this) => () => {
      try {
        selfRef.codemirrorHtmlTemplate = document.querySelector('.codemirrorHtmlTemplate');
        const tableInitioalFormIOJSON = self.ydocService.tablesMap.get('tablesInitialFormIOJson');
        if (tableInitioalFormIOJSON) {
          selfRef.sectionContent = JSON.parse(JSON.stringify(tableInitioalFormIOJSON));
        }

        selfRef.tableID = selfRef.data.tableID || uuidv4();
        const tableHTML = selfRef.renderCodemMirrorEditors(selfRef)(selfRef.tableID!);
        selfRef.sectionContent.props = { isCitableElement: true };
        self.formBuilderService.setAutoFocusInSchema(selfRef.sectionContent);

        if (!selfRef.data.table) {
          selfRef.renderForm = true;
        } else {
          selfRef.editMode = true;
          const editor = self.prosemirrorEditorsService.editorContainers[selfRef.tableID];
          const DOMPMSerializer = DOMSerializer.fromSchema(schema);

          //@ts-ignore
          const tableNode = Fragment.from(editor.editorView.state.doc.content.content[0]);

          selfRef.sectionContent.components[0].defaultValue = getHtmlFromFragment(
            tableNode,
            DOMPMSerializer
          );
          selfRef.sectionContent.components[1].defaultValue = getHtmlFromFragment(
            tableNode,
            DOMPMSerializer
          );
          selfRef.sectionContent.components[2].defaultValue = getHtmlFromFragment(
            tableNode,
            DOMPMSerializer
          );

          selfRef.sectionContent.components[0].tableID = selfRef.data.tableID;
          selfRef.sectionContent.components[1].tableID = selfRef.data.tableID;
          selfRef.sectionContent.components[2].tableID = selfRef.data.tableID;
          selfRef.sectionContent.components[0].editMode = true;
          selfRef.sectionContent.components[1].editMode = true;
          selfRef.sectionContent.components[2].editMode = true;

          selfRef.renderForm = true;
        }
      } catch (e) {
        console.error(e);
      }
    };

    this.renderCodemMirrorEditors = (selfRef: this) => (tableID: string) => {
      try {
        selfRef.tablesTemplatesObj = self.ydocService.tablesMap?.get('tablesTemplates');
        const initialTableTemplate = self.ydocService.tablesMap!.get('tablesInitialTemplate');
        let currFigTemplates: any;
        if (!selfRef.tablesTemplatesObj[tableID]) {
          selfRef.tablesTemplatesObj[tableID] = { html: initialTableTemplate };
          currFigTemplates = selfRef.tablesTemplatesObj[tableID];
        } else {
          currFigTemplates = selfRef.tablesTemplatesObj[tableID];
        }
        const prosemirrorNodesHtml = currFigTemplates.html;

        selfRef.codemirrorHTMLEditor = new EditorView({
          state: EditorState.create({
            doc: prosemirrorNodesHtml,
            extensions: [basicSetup, html()],
          }),
          parent: selfRef.codemirrorHtmlTemplate,
        });
        return prosemirrorNodesHtml;
      } catch (e) {
        console.error(e);
      }
    };

    this.onChange = (selfRef: any) => (change: any) => {
      self.serviceShare.ProsemirrorEditorsService.editMode = true;
      if (change instanceof Event) {
      } else {
        selfRef.formIoSubmission = change.data;

        const regex = /<[^>]*>/g;
        if (!selfRef.data.table) {
          const headerContent = change.data?.tableHeader
            ? change.data.tableHeader.replace(regex, '')
            : '';
          const tableContent = change.data?.tableHeader
            ? change.data.tableContent.replace(regex, '')
            : '';

          selfRef.isValid = headerContent.trim().length && tableContent.trim().length;
        } else {
          const div = document.createElement('div');
          div.innerHTML = change.data?.tableHeader;

          const headerContent = div
            .querySelectorAll('table-description')[1]
            .innerHTML.replace(regex, '');
          const tableContent = div.querySelector('table-content').innerHTML.replace(regex, '');

          selfRef.isValid = headerContent.trim().length && tableContent.trim().length;
        }

        if (change.changed && change.changed.instance) {
          selfRef.formIoRoot = change.changed.instance.root;
        }
      }
    };

    this.submitTable = (selfRef: this) => () => {
      if (selfRef.formIoRoot) {
        selfRef.formIoRoot.submit();
      }
    };

    this.onSubmit = (selfRef: this) => async (submision?: any) => {
      try {
        selfRef.tablesTemplatesObj = self.ydocService.tablesMap?.get('tablesTemplates');

        const escapeHTMLInSubmission = (obj: any) => {
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] == 'string') {
              obj[key] = obj[key]
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&');
            } else {
              try {
                escapeHTMLInSubmission(obj[key]);
              } catch (e) {
                console.error(e);
              }
            }
          });
        };
        const tr = selfRef.codemirrorHTMLEditor?.state.update();
        selfRef.codemirrorHTMLEditor?.dispatch(tr!);

        const prosemirrorNewNodeContent = selfRef.codemirrorHTMLEditor?.state.doc.sliceString(
          0,
          selfRef.codemirrorHTMLEditor?.state.doc.length
        );

        submision.data.tableID = selfRef.tableID;

        submision.data.viewed_by_citat = selfRef.data.tableID
          ? selfRef.data.table?.viewed_by_citat!
          : 'endEditor';
        selfRef.tablesTemplatesObj[selfRef.tableID!] = { html: prosemirrorNewNodeContent };
        self.ydocService.tablesMap?.set('tablesTemplates', selfRef.tablesTemplatesObj);
        submision.data.tableNumber = selfRef.data.index;

        if (selfRef.editMode) {
          const DOMPMSerializer = DOMSerializer.fromSchema(schema);
          const div = document.createElement('div');
          div.innerHTML = submision.data.tableHeader;
          const tableNode = DOMParser.fromSchema(schema).parse(div.firstChild);

          const getDataFromNode = (node: Node) => {
            node.content.forEach((node1, offset, index) => {
              if (node1.type.name == 'table_header_container') {
                node1.content.forEach((node2) => {
                  if (node2.type.name == 'table_description') {
                    submision.data.tableHeader = getHtmlFromFragment(
                      node2.content,
                      DOMPMSerializer
                    );
                  }
                });
              } else {
                getDataFromNode(node1);
              }
            });
          };
          getDataFromNode(tableNode);
        } else {
          const tableFormGroup = citationElementMap.table_citation.buildElementFormGroup(
            submision.data
          );
          const interpolatedHTML = await this.prosemirrorEditorsService.interpolateTemplate(
            prosemirrorNewNodeContent!,
            submision.data,
            tableFormGroup,
            null,
            { table: true }
          );
          const templ = document.createElement('div');
          templ.innerHTML = interpolatedHTML;
          const Slice = DOMParser.fromSchema(schema).parse(templ.firstChild);
          const node = schema.nodes['tables_nodes_container'].create({}, Slice.content.firstChild);

          const xmlFragment = self.prosemirrorEditorsService.getXmlFragment(
            undefined,
            selfRef.tableID
          );
          updateYFragment(self.ydocService.ydoc, xmlFragment, node, new Map());
          self.prosemirrorEditorsService.renderCustomEditor(
            document.createElement('div'),
            selfRef.tableID,
            true
          );
        }

        filterFieldsValues(
          selfRef.sectionContent,
          submision,
          self.serviceShare,
          undefined,
          false,
          prosemirrorNewNodeContent,
          true
        );

        const newTable = {
          header: submision.data.tableHeader,
          editMode: selfRef.editMode,
          tableNumber: selfRef.data.index,
          clientID: this.ydocService.ydoc.clientID,
          tableID: submision.data.tableID,
          tablePlace: selfRef.data.tableID ? selfRef.data.table?.tablePlace! : 'endEditor',
          viewed_by_citat: selfRef.data.tableID
            ? selfRef.data.table?.viewed_by_citat!
            : 'endEditor',
        };

        self.dialogRef.close({ table: newTable });
      } catch (error) {
        console.error(error);
      }
    };
    this.defaultOnChange = this.onChange(this);
    this.defaultSubmitTable = this.submitTable(this);
    this.defaultOnSubmit = this.onSubmit(this);
  }
  isLoading = false;
  ngAfterViewInit(): void {
    if (this.modalTemplate) {
      setTimeout(() => {
        this.createComponent(this.modalTemplate);
      }, 0);
    } else {
      this.initFunc(this)();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  component: ComponentRef<any>;
  createComponent(template: string): void {
    const self = this;
    const data = this.data;
    const config = this.config;

    const component = Component({
      template,
      standalone: true,
      imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatFormioModule,
        FormioModule,
        FormControlNameDirective,
      ],
    })(
      class implements AfterViewInit {
        sectionContent = JSON.parse(JSON.stringify(tableJson));
        renderForm = false;
        data = data;
        config = config;
        section = { mode: 'editMode' };
        sectionForm = new UntypedFormGroup({});
        tableID?: string;
        hidehtml = true;
        isLoading = false;
        editMode = self.editMode;
        originUpdates = self.originUpdates;
        updateYdoc = self.updateYdoc;

        initFunc = self.initFunc;
        renderCodemMirrorEditors = self.renderCodemMirrorEditors;
        onChange = self.onChange(this);
        submitTable = self.submitTable(this);
        onSubmit = self.onSubmit(this);

        ngAfterViewInit(): void {
          this.initFunc(this)();
        }

        tablesTemplatesObj: any;
        codemirrorHTMLEditor: EditorView;
        codemirrorHtmlTemplate: HTMLElement;

        isValid: boolean = false;
        formIoSubmission: any;
        formIoRoot: any;
      }
    );

    this.component = this.container.createComponent(component);
  }

  ngOnDestroy(): void {
    this.component?.destroy();
    this.container?.clear();
  }
}
