import { Inject, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';

import { uuidv4 } from 'lib0/random';
import { Attrs, Fragment, Mark, Node, ResolvedPos, Schema } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import { DOMParser } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';

import { schema } from '../utils/Schema';
import { getCitationIfAny } from '../utils/citableElementsHelpers';
import { ServiceShare } from './service-share.service';
import { updateYFragment } from '../../y-prosemirror-src/plugins/sync-plugin';
import { CITATION_ELEMENTS } from '../utils/menu/menu-dialogs';
import {
  citableElementWithStaticViews,
  citationElementMap,
  Citations,
  citationsPMNodeNames,
  DisplayedViews,
  elementsContainersPMNodesNames,
  ElementsHtmlStructure,
  ElementsNumbersObj,
  ElementsObjects,
  ElementsTypes,
  ElementsTypeToCitationMap,
  EndNote,
  Figure,
  ModalTemplates,
  OlderVersionCitableElements,
  SupplementaryFile,
  Table,
} from './citable-elements.models';
import { CitableSection } from '@app/core/models/article.models';
import { HelperCitableElementsService } from './helper-citable-elements.service';

@Injectable({
  providedIn: 'root',
})
export class CitableElementsService {
  deletedTablesForRerender = {};

  rendered = 0;

  renderedViews = 0; // 2 1- for viewd element and 1-for element in end editor

  closeOnClickOutsideTables = false;
  closeOnClickOutsideFootNotes = false;
  closeOnClickOutsideSupplementaryFiles = false;
  closeOnClickOutsideFigures = false;

  updatingElementsAndElementsCitations = false;

  updatingOnlyElementsView = false;

  subscription: Subscription;

  elementsFromPopupEdit: {
    [key: string]: Table | Figure | SupplementaryFile | EndNote;
  } = {};

  constructor(
    private serviceShare: ServiceShare,
    private helperCitableElementsService: HelperCitableElementsService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.serviceShare.shareSelf('CitableElementsService', this);

    this.serviceShare.YdocService.ydocStateObservable.subscribe(({ event }) => {
      if (event == 'docIsBuild') {
        const tablesSchema = this.serviceShare.YdocService.citableElementsSections.find(
          (sec: CitableSection) => sec.name == 'Tables'
        )?.template;
        const supplementaryMaterialsSchema =
          this.serviceShare.YdocService.citableElementsSections.find(
            (sec: CitableSection) => sec.name == 'SupplementaryMaterials'
          )?.template;
        const footnotesSchema = this.serviceShare.YdocService.citableElementsSections.find(
          (sec: CitableSection) => sec.name == 'Footnotes'
        )?.template;
        const figuresSchema = this.serviceShare.YdocService.citableElementsSections.find(
          (sec: CitableSection) => sec.name == 'Figures'
        )?.template;

        if (tablesSchema?.includes(ModalTemplates.Tables)) {
          this.closeOnClickOutsideTables = true;
        }
        if (supplementaryMaterialsSchema?.includes(ModalTemplates.SupplementaryFiles)) {
          this.closeOnClickOutsideSupplementaryFiles = true;
        }
        if (footnotesSchema?.includes(ModalTemplates.Footnotes)) {
          this.closeOnClickOutsideFootNotes = true;
        }
        if (figuresSchema?.includes(ModalTemplates.Figures)) {
          this.closeOnClickOutsideFigures = true;
        }
      }
    });
  }

  getElementsTemplates(): ElementsHtmlStructure {
    const elementsTemplatesObj = {} as ElementsHtmlStructure;

    citationsPMNodeNames.forEach((elcitatName) => {
      const elMapping = citationElementMap[elcitatName];
      const elTemplatesYjsMap = this.serviceShare.YdocService[elMapping.yjsMap].get(
        elMapping.templatesObj
      );
      elementsTemplatesObj[elMapping.type] = elTemplatesYjsMap;
    });

    return elementsTemplatesObj;
  }

  getElementsNumbersObjs(): ElementsNumbersObj {
    const elementsNumbersObjs = {} as ElementsNumbersObj;

    citationsPMNodeNames.forEach((citType) => {
      const elMap = citationElementMap[citType];
      const elementNumbers = this.serviceShare.YdocService[elMap.yjsMap].get(
        elMap.elementNumbersObj
      );
      elementsNumbersObjs[elMap.type] = elementNumbers;
    });

    return elementsNumbersObjs;
  }

  updateCitatsText(citats: {
    [sectionID: string]:
      | {
          [citatID: string]: {
            displayedViewsHere: DisplayedViews[];
            citedElementsIDs: string[];
            position: number;
            citationType: string;
          };
        }
      | undefined;
  }): void {
    const elementsNumbersObjs = this.getElementsNumbersObjs();
    Object.keys(citats).forEach((sectionID) => {
      if (citats[sectionID]) {
        Object.keys(citats[sectionID]!).forEach((citatID) => {
          if (!this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionID]) {
            citats[sectionID] = undefined;
          } else {
            const edView =
              this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
            edView.state.doc.nodesBetween(0, edView.state.doc.nodeSize - 2, (node, pos) => {
              const citatMark = getCitationIfAny(node);
              if (citatMark.length > 0) {
                const citationMark = citatMark[0];
                if (citationMark.attrs.citateid == citatID) {
                  let citatedElements = [...citationMark.attrs.citated_elements];
                  const elementMaping = citationElementMap[citationMark.type.name];
                  const citatType = elementMaping.type;

                  const elementNumbers = elementsNumbersObjs[citatType];

                  let citElClearFromComponents: string[] = [];
                  const citElsComponents: { [key: string]: string[] } = {};
                  citatedElements.forEach((el: string) => {
                    if (el) {
                      const data = el.split('|');
                      const elId = data[0];
                      if (data[1]) {
                        if (!citElsComponents[elId]) {
                          citElsComponents[elId] = [];
                        }
                        citElsComponents[elId].push(data[1]);
                      }
                      if (citElClearFromComponents.indexOf(elId) == -1) {
                        citElClearFromComponents.push(elId);
                      }
                    }
                  });
                  if (
                    (citElClearFromComponents.length == 1 &&
                      elementNumbers?.indexOf(citElClearFromComponents[0]) == -1) ||
                    (citElClearFromComponents.length > 1 &&
                      citElClearFromComponents.filter((table) => {
                        return elementNumbers?.indexOf(table) !== -1;
                      }).length == 0)
                  ) {
                    if (citationMark.attrs.nonexistingelement !== 'true') {
                      const citateNodeText = elementMaping.deletedElTxt;
                      let newNode = (edView.state.schema as Schema).text(citateNodeText) as Node;
                      newNode = newNode.mark([
                        edView.state.schema.mark(citationMark.type.name, {
                          ...citationMark.attrs,
                          nonexistingelement: 'true',
                        }),
                      ]);
                      if (node.nodeSize <= 1) return;
                      edView.dispatch(
                        edView.state.tr
                          .replaceWith(pos, pos + node.nodeSize, newNode)
                          .setMeta('citatsTextChange', true)
                      );
                    }
                  } else {
                    citElClearFromComponents.forEach((elid) => {
                      if (elementNumbers?.indexOf(elid) == -1) {
                        citatedElements = citatedElements.filter((elIDS: string) => {
                          const data = elIDS.split('|');
                          if (data[0] == elid) {
                            return false;
                          }
                          return true;
                        });
                      }
                    });
                    citElClearFromComponents = citElClearFromComponents.filter((elId: string) => {
                      return elementNumbers.includes(elId);
                    });
                    let citatString =
                      citElClearFromComponents.length == 1
                        ? elementMaping.singleElTxt
                        : elementMaping.multipleElTxt;
                    const elsArr: string[] = [];
                    elementNumbers?.forEach((element, i) => {
                      if (citElClearFromComponents.indexOf(element) !== -1) {
                        if (citElsComponents[element]) {
                          citElsComponents[element].forEach((elComponent, j) => {
                            if (j == 0) {
                              const figures = this.serviceShare.YdocService[
                                elementMaping.yjsMap
                              ].get(elementMaping.elementsObj) as {
                                [key: string]: Figure;
                              };
                              let index: number;

                              Object.keys(figures).forEach((key) => {
                                const figure = figures[key].components.find(
                                  (c) => c.id == elComponent
                                );
                                if (figure) {
                                  index = figures[key].components.findIndex(
                                    (c: any) => c.id == elComponent
                                  );
                                }
                              });
                              elsArr.push(`${i + 1}${String.fromCharCode(97 + +index)}`);
                            } else {
                              elsArr.push(`${String.fromCharCode(97 + +elComponent)}`);
                            }
                          });
                        } else {
                          elsArr.push(`${i + 1}`);
                        }
                      }
                    });
                    let newNode;
                    const marks = node.marks.filter(
                      (m) => !CITATION_ELEMENTS.includes(m.type.name)
                    );
                    if (elementMaping.getCitationNode) {
                      newNode = elementMaping.getCitationNode(
                        elsArr,
                        citationMark.attrs,
                        citatedElements,
                        edView
                      );
                      newNode.content[0]?.marks?.push(...marks);
                    } else {
                      citatString += elsArr.join(', ');
                      citatString += ' ';
                      newNode = (edView.state.schema as Schema).text(citatString) as Node;
                      newNode = newNode.mark([
                        edView.state.schema.mark(citationMark.type.name, {
                          ...citationMark.attrs,
                          citated_elements: citatedElements,
                          nonexistingtable: 'false',
                        }),
                        ...marks,
                      ]);
                    }
                    if (node.nodeSize <= 1) return;
                    edView.dispatch(
                      edView.state.tr
                        .replaceWith(pos, pos + node.nodeSize, newNode)
                        .setMeta('citatsTextChange', true)
                    );
                  }
                }
              }
            });
          }
        });
      }
    });
  }

  getElementsObj(): ElementsObjects {
    const elementsObjs = {} as ElementsObjects;
    console.log(this.elementsFromPopupEdit);

    citationsPMNodeNames.forEach((citType) => {
      if (citType == 'supplementary_file_citation' || citType == 'end_note_citation') return;
      const elMap = citationElementMap[citType];
      if (this.elementsFromPopupEdit[citType]) {
        elementsObjs[elMap.type] = this.elementsFromPopupEdit[citType];
      } else {
        const elementObjs = this.serviceShare.YdocService[elMap.yjsMap].get(elMap.elementsObj);
        elementsObjs[elMap.type] = elementObjs;
      }
    });

    return elementsObjs;
  }

  resetCountedRenderedViews(): void {
    this.rendered = 0;
  }

  allElsAreRendered(): void {
    setTimeout(() => {
      if (this.updatingElementsAndElementsCitations) {
        this.serviceShare.YjsHistoryService.stopCapturingUndoItem();
        this.updatingElementsAndElementsCitations = false;
      }
      if (this.updatingOnlyElementsView) {
        this.serviceShare.YjsHistoryService.stopCapturingUndoItem();
        this.updatingOnlyElementsView = false;
      }
      this.serviceShare.YjsHistoryService.stopBigNumberItemsCapturePrevention();
    }, 20);
  }

  getCitationsInElementsObj(elementObj: ElementsObjects): ElementsObjects {
    const elTypes = Object.keys(elementObj);
    const elMaps = {};

    elTypes.forEach((type) => {
      const citationName = ElementsTypeToCitationMap[type];
      elMaps[type] = citationElementMap[citationName];
    });
    const citationsInElements = {} as ElementsObjects;

    elTypes.forEach((elType) => {
      const elementsOfType = elementObj[elType];
      const elementsIds = Object.keys(elementsOfType);
      citationsInElements[elType] = {};
      elementsIds.forEach((elId) => {
        const elData = elementsOfType[elId];
        const HTMLstringInEl = elMaps[elType].getElsStrings(elData);
        const citationsInElement = []; // hold all the citation in the element seperated by types
        elTypes.forEach((elType1) => {
          const elementCitationHTMLTag = elMaps[elType1].htmlTag;
          const searchElementCitationREGEX = new RegExp(
            `<${elementCitationHTMLTag}.+?(?=<\/${elementCitationHTMLTag}>)<\/${elementCitationHTMLTag}>`,
            'gm'
          );
          const searchElementIdREGEX = new RegExp(`citated_elements=".+?"`, 'gm');

          const innerCited = [];
          let match;
          while ((match = searchElementCitationREGEX.exec(HTMLstringInEl)) !== null) {
            if (match[0].length > 0) {
              let citedElements = match[0].match(searchElementIdREGEX);
              if (citedElements.length > 0) {
                citedElements = citedElements[0]
                  .split('"')[1]
                  .split(',')
                  .map((el) => el.split('|')[0]);
              }
              citedElements = citedElements.filter((el) => el.length > 0);
              innerCited.push({
                citat: match[0],
                index: match.index,
                type: elType1,
                citationName: citedElements,
              });
            }
          }
          citationsInElement.push(...innerCited);
        });
        citationsInElement.sort((a, b) => {
          return a.index - b.index;
        });

        citationsInElements[elType][elId] = citationsInElement;
      });
    });
    return citationsInElements;
  }

  updateElementsNumbers(
    newElements: { [key: string]: Figure | Table | SupplementaryFile | EndNote },
    elementNumbers: string[],
    elementNumberProp: string
  ): {
    [key: string]: Figure | Table | SupplementaryFile | EndNote;
  } {
    Object.keys(newElements).forEach((elKey) => {
      const elNumber = elementNumbers.indexOf(elKey);
      newElements[elKey][elementNumberProp] = elNumber;
    });
    return newElements;
  }

  writeElementDataGlobal(
    newElements: { [key: string]: Figure | Table | SupplementaryFile | EndNote },
    elementsNumbers: string[],
    type: string,
    editMode?: boolean
  ): void {
    this.serviceShare.ProsemirrorEditorsService.editMode = true;
    const elementMapping = citationElementMap[type];
    const oldCitats = JSON.parse(
      JSON.stringify(this.serviceShare.YdocService.citableElementsMap?.get('elementsCitations'))
    );
    const oldElNums = JSON.parse(
      JSON.stringify(
        this.serviceShare.YdocService[elementMapping.yjsMap].get(elementMapping.elementNumbersObj)
      )
    );
    const oldEls = JSON.parse(
      JSON.stringify(
        this.serviceShare.YdocService[elementMapping.yjsMap].get(elementMapping.elementsObj)
      )
    );
    this.serviceShare.YjsHistoryService!.startCapturingNewUndoItem();

    const newElsCopy = JSON.parse(JSON.stringify(newElements));
    const newElsNumsCopy = JSON.parse(JSON.stringify(elementsNumbers));

    const newNumbers = this.updateElementsNumbers(
      newElsCopy,
      newElsNumsCopy,
      elementMapping.elementNumberProp
    );

    this.serviceShare.YdocService[elementMapping.yjsMap].set(
      elementMapping.elementNumbersObj,
      newElsNumsCopy
    );
    this.serviceShare.YdocService[elementMapping.yjsMap].set(
      elementMapping.elementsObj,
      newElements
    );

    const citats = this.serviceShare.YdocService.citableElementsMap?.get('elementsCitations');
    this.serviceShare.YjsHistoryService!.addUndoItemInformation({
      type: elementMapping.type,
      data: {
        oldData: {
          cites: oldCitats,
          elementNumbers: oldElNums,
          elements: oldEls,
        },
        newData: {
          cites: JSON.parse(JSON.stringify(citats)),
          elementNumbers: JSON.parse(JSON.stringify(elementsNumbers)),
          elements: JSON.parse(JSON.stringify(newElements)),
        },
      },
    });
    // this.serviceShare.YjsHistoryService!.stopCapturingUndoItem();

    this.elementsFromPopupEdit[type] = newElsCopy;
    if (
      this.serviceShare.compareObjects(
        this.serviceShare.YdocService.citableElementsMap.get('elementsCitations'),
        citats
      )
    ) {
      this.serviceShare.YdocService.citableElementsMap.set('elementsCitations', citats);
    }

    if (type == 'end_note_citation') {
      const endNotesTemplate =
        this.serviceShare.YdocService.endNotesMap!.get('endNotesInitialTemplate');
      const endNoteFormGroup = citationElementMap.end_note_citation.buildElementFormGroup(
        Object.values(newNumbers as { [key: string]: EndNote })
      );

      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        endNotesTemplate,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(newNumbers).sort((a: any, b: any) => a.end_note_number - b.end_note_number),
        endNoteFormGroup
      ).then((result: string) => {
        const templ = document.createElement('div');
        templ.innerHTML = result;
        const Slice = DOMParser.fromSchema(schema).parse(templ.firstChild);
        const xmlFragment = this.serviceShare.YdocService.ydoc.getXmlFragment('endNotesEditor');
        updateYFragment(xmlFragment.doc, xmlFragment, Slice, new Map());
        const citations = this.helperCitableElementsService.getElementsCitations(
          this.serviceShare.ProsemirrorEditorsService.editorContainers
        );
        this.updateCitatsText(citations);
      });
    } else if (type == 'supplementary_file_citation') {
      const supplementaryFilesTemplate = this.serviceShare.YdocService.supplementaryFilesMap!.get(
        'supplementaryFilesInitialTemplate'
      );
      const supplementaryFilesFormGroup =
        citationElementMap.supplementary_file_citation.buildElementFormGroup(
          Object.values(newNumbers as { [key: string]: SupplementaryFile })
        );

      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        supplementaryFilesTemplate,
        Object.values(newNumbers).sort(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any, b: any) => a.supplementary_file_number - b.supplementary_file_number
        ),
        supplementaryFilesFormGroup
      ).then((result: string) => {
        const templ = document.createElement('div');
        templ.innerHTML = result;
        const Slice = DOMParser.fromSchema(schema).parse(templ.firstChild);
        const xmlFragment =
          this.serviceShare.YdocService.ydoc.getXmlFragment('supplementaryFilesView');
        updateYFragment(xmlFragment.doc, xmlFragment, Slice, new Map());
        const citations = this.helperCitableElementsService.getElementsCitations(
          this.serviceShare.ProsemirrorEditorsService.editorContainers
        );
        this.updateCitatsText(citations);
      });
    } else if (type == 'table_citation' && editMode) {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    } else {
      this.serviceShare.updateCitableElementsViewsAndCites();
    }
  }

  calcDisplayedViewsInCitation(
    citedElementsIds: string[],
    citationType: string,
    elementsNumbersObj: ElementsNumbersObj,
    displayedElementsObj: { [key: string]: boolean[] },
    citationsInElementsObj: ElementsObjects,
    displayedElsOnCurrCitation: { elId: string; type: string }[]
  ): void {
    // dont display view of elements that should be shown only in the end editor
    if (citableElementWithStaticViews.includes(ElementsTypeToCitationMap[citationType])) {
      return;
    }
    // loops all the view that are cited on the passed citation but are not displayed and add these views in displayedElsOnCurrCitation
    // calcs the views nested in one another in order
    const elsOfCurrTypeNumbers = elementsNumbersObj[citationType] as string[];
    const elsDisplayedOfCurrType = displayedElementsObj[citationType] as boolean[];
    const citationsInElsOfCurrType = citationsInElementsObj[citationType];

    // get the element with highest index number in order

    let maxIndex = -1;
    citedElementsIds.forEach((el) => {
      const i = elsOfCurrTypeNumbers.indexOf(el);
      if (i > maxIndex) {
        maxIndex = i;
      }
    });

    // get all element until el with max index cited

    const allCitedElements: string[] = [];
    for (let i = 0; i <= maxIndex; i++) {
      const elId = elementsNumbersObj[citationType][i];
      allCitedElements.push(elId);
    }

    // filter all id of elements that are not displayed

    const notDisplayedElsIds: string[] = [];

    allCitedElements.forEach((elId, i) => {
      if (!elsDisplayedOfCurrType[i]) {
        notDisplayedElsIds.push(elId);
      }
    });

    // mark each el that is not displayed for display on the curr citation and loop all citation on the element
    notDisplayedElsIds.forEach((elId) => {
      const elIndex = elsOfCurrTypeNumbers.indexOf(elId);
      if (!elsDisplayedOfCurrType[elIndex]) {
        displayedElsOnCurrCitation.push({ elId, type: citationType });
        elsDisplayedOfCurrType[elIndex] = true;
        const citationsOnEl = citationsInElsOfCurrType[elId];
        if (citationsOnEl) {
          citationsOnEl.forEach((citation) => {
            const citedElsInCitation = citation.citedElements;
            const citationTypeInner = citation.type;
            this.calcDisplayedViewsInCitation(
              citedElsInCitation,
              citationTypeInner,
              elementsNumbersObj,
              displayedElementsObj,
              citationsInElementsObj,
              displayedElsOnCurrCitation
            );
          });
        }
      }
    });
  }

  markElementsCitatsViews = (
    citatsBySection: Citations,
    elsDisplayedInObj: { [key: string]: { [key: string]: string } },
    olderElements?: { [key: string]: { [key: string]: { [key: string]: boolean } } }
  ): Citations => {
    this.resetCountedRenderedViews();
    const elementsNumbersObjs = this.getElementsNumbersObjs();
    const elementsNumbersObjsCopy = {};
    Object.keys(elementsNumbersObjs).forEach((elType) => {
      elementsNumbersObjsCopy[elType] = JSON.parse(JSON.stringify(elementsNumbersObjs[elType]));
    });

    const elementsObjs = this.getElementsObj();
    Object.keys(this.serviceShare.ProsemirrorEditorsService.editorContainers).forEach((key) => {
      if (key == 'endNotesEditor' || key == 'supplementaryFilesView') return;
      let containersCount = 0;
      const view = this.serviceShare.ProsemirrorEditorsService.editorContainers[key].editorView;
      view.state.doc.descendants((el) => {
        if ([...elementsContainersPMNodesNames, 'example'].includes(el.type.name)) {
          containersCount++;
        }
      });
      let deleted = false;
      let tr1: Transaction;
      const del = () => {
        deleted = false;
        tr1 = view.state.tr;
        view.state.doc.descendants((node, position) => {
          if ([...elementsContainersPMNodesNames, 'example'].includes(node.type.name) && !deleted) {
            deleted = true;
            tr1 = tr1
              .deleteRange(position, position + node.nodeSize)
              .setMeta('citable-elements-rerender', true);
          }
        });
        view.dispatch(tr1);
      };
      for (let index = 0; index < containersCount; index++) {
        del();
      }
      if (key == 'endEditor') {
      }
    });

    const elementsDysplayedViews = {};
    Object.keys(elementsNumbersObjsCopy).forEach((elType) => {
      elementsDysplayedViews[elType] = elementsNumbersObjsCopy[elType].map(() => {
        return false;
      });
    });

    const articleFlatStructure = this.serviceShare.YdocService.articleStructureMap.get(
      'articleSectionsStructureFlat'
    ) as string[];

    const citationsInElementsObj = this.getCitationsInElementsObj(elementsObjs);
    articleFlatStructure.forEach((sectionID: string) => {
      const sortable = [];
      for (const citat in citatsBySection[sectionID]) {
        if (citatsBySection[sectionID][citat]) {
          sortable.push([citat, citatsBySection[sectionID][citat]]);
        }
      }

      sortable.sort(function (a, b) {
        return a[1].position - b[1].position;
      });

      sortable.forEach((citatData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const displayedElementViewsHere: any[] = [];
        const citationType = citatData[1].citationType;
        const citedElements = citatData[1].citedElementsIDs.map((el) => el?.split('|')?.[0]);

        this.helperCitableElementsService.getViewsOnCitation(
          citedElements,
          citationType,
          elementsNumbersObjs,
          elementsDysplayedViews,
          citationsInElementsObj,
          displayedElementViewsHere
        );
        citatsBySection[sectionID][citatData[0]].displayedViewsHere = displayedElementViewsHere;
        displayedElementViewsHere.forEach((el) => {
          elsDisplayedInObj[el.type][el.elId] = sectionID;
        });
      });
    });
    const nonDisplayedViewsWithIndexes = {};
    Object.keys(elsDisplayedInObj).forEach((type) => {
      const elementsOfType = elsDisplayedInObj[type];
      nonDisplayedViewsWithIndexes[type] = [];
      Object.keys(elementsOfType).forEach((elId) => {
        if (elementsOfType[elId] == 'endEditor') {
          nonDisplayedViewsWithIndexes[type].push({
            elId,
            elIndex: elementsNumbersObjs[type].indexOf(elId),
          });
        }
      });
    });
    let typesToRenderInEndEdtior = 0;
    let count = 0;
    const countRendered = () => {
      count++;
      if (count == typesToRenderInEndEdtior) {
        this.countRenderedViews();
      }
    };
    const numberOfNonDisplayedView = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.values(nonDisplayedViewsWithIndexes).reduce((prev: number, curr: any[]) => {
      return (prev += curr.length);
    }, 0);
    if (numberOfNonDisplayedView == 0) {
      this.countRenderedViews();
    }
    Object.keys(nonDisplayedViewsWithIndexes).forEach((type) => {
      const nonDisplayedEditors = nonDisplayedViewsWithIndexes[type];
      if (nonDisplayedEditors.length > 0) {
        typesToRenderInEndEdtior++;
      }
      nonDisplayedEditors.sort((a, b) => a.elIndex - b.elIndex);

      this.addElementsInEndEditor(type, nonDisplayedEditors, countRendered, olderElements);
    });
    return citatsBySection;
  };

  addElementsInEndEditor(
    type: string,
    nonDisplayedEditors: {
      elId: string;
      elIndex: number;
    }[],
    countRendered: () => void,
    olderElements: {
      [key: string]: {
        [key: string]: {
          [key: string]: boolean;
        };
      };
    }
  ): void {
    const elType = type;
    const typeMapping = citationElementMap[ElementsTypeToCitationMap[elType]];

    const elementObjs = this.getElementsObj();
    const elementsTemplates = this.getElementsTemplates();
    const renderedViewsMetaData = [];
    let renderedViews = 0;
    const view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers['endEditor']?.editorView;
    if (!view) return;
    const DOMPMParser = DOMParser.fromSchema(view.state.schema);

    const displayViews = (data: {
      renderedViewsMetaData: {
        elType: string;
        elementData: { tableID: string };
        renderedData: Node;
      }[];
      citationType: string;
      labelState?: string;
    }): void => {
      const nodeStart: number = view.state.doc.nodeSize - 2;

      const elementNodes = data.renderedViewsMetaData.map((el) => {
        if (el.elType == 'table') {
          const node1 = view.state.schema.nodes['tables_nodes_container'].create(
            {},
            el.renderedData
          );
          const updateXmlFragment = this.serviceShare.ProsemirrorEditorsService.getXmlFragment(
            undefined,
            el.elementData.tableID
          );
          updateYFragment(this.serviceShare.YdocService.ydoc, updateXmlFragment, node1, new Map());
          return view.state.schema.nodes['example'].create({
            id: el.elementData.tableID,
            contentEditableNode: true,
          });
        } else {
          return el.renderedData;
        }
      });

      const endEditorPrefixNodes = typeMapping.getEndEditorPrefixNodes
        ? typeMapping.getEndEditorPrefixNodes(data.labelState)
        : [];

      const schema = view.state.schema as Schema;
      const container = schema.nodes[typeMapping.containerNodeName].create({}, [
        ...endEditorPrefixNodes,
        ...elementNodes,
      ]);

      view.dispatch(
        view.state.tr
          .insert(nodeStart, Fragment.from(container))
          .setMeta('citable-elements-rerender', true)
      );
      setTimeout(() => {
        countRendered();
      }, 0);
    };

    nonDisplayedEditors.forEach((el, i) => {
      const elId = el.elId;
      const elementData = elementObjs[elType][elId];
      const elementTemplate = elementsTemplates[elType][elId];
      typeMapping.setSectionData(elementData, 'endEditor', 'endEditor');
      let serializedElementToFormIOsubmission;
      let elementFormGroup;

      if (elType == 'table') {
        serializedElementToFormIOsubmission =
          this.deletedTablesForRerender[elId] ||
          typeMapping.getElFormIOSubmission(elementData, 'endEditor', this.serviceShare);
        serializedElementToFormIOsubmission.tableNumber = elementData.tableNumber;
        serializedElementToFormIOsubmission.viewed_by_citat = 'endEditor';
        elementFormGroup = typeMapping.buildElementFormGroup(serializedElementToFormIOsubmission);
      } else {
        serializedElementToFormIOsubmission = typeMapping.getElFormIOSubmission(
          elementData,
          'endEditor'
        );
        elementFormGroup = typeMapping.buildElementFormGroup(serializedElementToFormIOsubmission);
      }

      if (olderElements) {
        serializedElementToFormIOsubmission[typeMapping.elementNumberProp] = i;
      }
      if (!elementTemplate?.html) return;
      this.serviceShare.ProsemirrorEditorsService.editMode = true;
      this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
        elementTemplate.html,
        serializedElementToFormIOsubmission,
        elementFormGroup
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ).then((data: any) => {
        let oldVersionAction: string;

        if (elType == 'figure') {
          if (elementData.canvasData?.nOfColumns) {
            data = data.replaceAll(
              'alt="image"',
              `alt="image" numberOfColumns="${elementData.canvasData.nOfColumns}"`
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          elementData.components.forEach((component: any) => {
            if (
              component.url.includes(this.config.CDNService) &&
              component.componentType == 'image'
            ) {
              const imageId = component.url.split('m/')[1];
              data = data.replace(
                'img-placeholder _ng',
                `img-placeholder class="image-placeholder" numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" imageId="${imageId}" imageSrc="${this.config.CDNService}" isFromCDN="true" _ng`
              );
              data = data.replace(
                'src=""',
                `src imageId="${imageId}" class="figure-image" imageSrc="${this.config.CDNService}" isFromCDN="true"`
              );
              data = data.replace(
                'class="figure-zoom"',
                `class="figure-zoom " src="${this.config.CDNService}/${imageId}"`
              );
              data = data.replace(
                'class="figure-download"',
                `class="figure-download " src="${this.config.CDNService}/${imageId}"`
              );
            } else if (
              component.componentType == 'image' &&
              !component.url.includes(this.config.CDNService)
            ) {
              data = data.replace(
                'img-placeholder _ng',
                `img-placeholder numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" class="image-placeholder" imageSrc="${component.url}" isFromCDN="false" _ng`
              );
              data = data.replace(
                'src=""',
                `src="${component.url}" class="figure-image" isFromCDN="false"`
              );
              data = data.replace(
                'class="figure-zoom"',
                `class="figure-zoom " src="${component.url}"`
              );
              data = data.replace(
                'class="figure-download"',
                `class="figure-download " src="${component.url}"`
              );
            }
          });
        }

        const templ = document.createElement('div');
        templ.innerHTML = data;

        const Slice = DOMPMParser.parse(templ.firstChild);

        renderedViews++;

        let labelState = undefined;

        if (olderElements) {
          const user =
            this.serviceShare.ProsemirrorEditorsService.permanentUserData.getUserByClientId(
              serializedElementToFormIOsubmission.clientID
            );
          if (olderElements[elType][elId]?.added) {
            if (
              Object.keys(elementObjs[elType]).length == Object.keys(olderElements[elType]).length
            ) {
              labelState = user + '&insertion';
            }
            const userColor = user.split('&')[1];
            oldVersionAction = `border: 2px solid ${userColor}; padding: 3px; border-radius: 3px; display: block;`;
            //@ts-expect-error 'attrs' may not have a defined index signature for 'styling'
            Slice.content.firstChild.attrs['styling'] = oldVersionAction;
            //@ts-expect-error 'attrs' may not have a defined index signature for 'class'
            Slice.content.firstChild.attrs['class'] = 'insertion';
          } else if (olderElements[elType][elId]?.removed) {
            if (
              Object.keys(elementObjs[elType]).length == Object.keys(olderElements[elType]).length
            ) {
              labelState = user + '&deletion';
            }
            oldVersionAction = 'citable-element-removed deletion';
            //@ts-expect-error 'attrs' may not have a defined index signature for 'class'
            Slice.content.firstChild.attrs['class'] = oldVersionAction;
          }
        }

        renderedViewsMetaData[i] = {
          renderedData: Slice.content.firstChild,
          elementData,
          elType,
        };
        if (renderedViews == nonDisplayedEditors.length) {
          if (!olderElements) {
            displayViews({
              renderedViewsMetaData: renderedViewsMetaData.sort(
                (a, b) =>
                  a.elementData[typeMapping.elementNumberProp] -
                  b.elementData[typeMapping.elementNumberProp]
              ),
              citationType: type,
            });
          } else {
            displayViews({
              renderedViewsMetaData: renderedViewsMetaData.sort(
                (a, b) =>
                  a.elementData[typeMapping.elementNumberProp] -
                  b.elementData[typeMapping.elementNumberProp]
              ),
              citationType: type,
              labelState,
            });
          }
        }
      });
    });
  }

  citateEndNote(
    selectedEndNotes: boolean[],
    sectionID: string,
    citatAttrs: Attrs,
    view?: EditorView
  ): void {
    try {
      this.serviceShare.YjsHistoryService.startCapturingNewUndoItem();

      const matkType = 'end_note_citation';

      const endNotesMaping = citationElementMap[matkType];

      const endNotesNumbers = this.serviceShare.YdocService[endNotesMaping.yjsMap].get(
        endNotesMaping.elementNumbersObj
      );
      const endNotesCitations = this.serviceShare.YdocService[endNotesMaping.yjsMap].get(
        endNotesMaping.elementCitations
      );

      let insertionView: EditorView;
      if (!view) {
        insertionView =
          this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
      } else {
        insertionView = view;
      }
      let citatStartPos: number;
      let citatEndPos: number;

      if (citatAttrs) {
        const citatId = citatAttrs.citateid;
        insertionView.state.doc.nodesBetween(
          0,
          insertionView.state.doc.nodeSize - 2,
          (node, pos) => {
            if (
              node.marks.filter((mark) => {
                return mark.type.name == matkType;
              }).length > 0
            ) {
              const citationMark = node.marks.filter((mark) => {
                return mark.type.name == matkType;
              })[0];
              if (citationMark.attrs.citateid == citatId) {
                citatStartPos = pos;
                citatEndPos = pos + node.nodeSize;
              }
            }
          }
        );
      }

      let citateId;
      if (citatAttrs) {
        citateId = citatAttrs.citateid;
      } else {
        citateId = uuidv4();
      }
      if (selectedEndNotes.filter((e) => e).length == 0 && !citatAttrs) {
        return;
      } else if (selectedEndNotes.filter((e) => e).length == 0 && citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(citatStartPos, citatEndPos, Fragment.empty)
        );
        citatAttrs.citated_elements.forEach((citatId) => {
          if (endNotesCitations[citatId] > 1) {
            endNotesCitations[citatId]--;
          } else {
            delete endNotesCitations[citatId];
          }
        });
        this.serviceShare.updateCitableElementsViews();
        return;
      }

      let citatString =
        selectedEndNotes.filter((e) => e).length > 1
          ? endNotesMaping.multipleElTxt
          : endNotesMaping.singleElTxt;
      const citated: number[] = [];
      selectedEndNotes.forEach((fig, index) => {
        if (fig) {
          citated.push(index + 1);
        }
      });
      citatString += citated.join(',  ');
      citatString += ' ';
      const citatedEndNotesIds = selectedEndNotes.reduce<string[]>((prev, curr, index) => {
        if (curr) {
          return prev.concat(curr ? [endNotesNumbers![index]] : []);
        } else {
          return prev;
        }
      }, []);

      const citateNodeText = citatString;
      let node = (insertionView.state.schema as Schema).text(citateNodeText) as Node;
      const mark = (insertionView.state.schema as Schema).mark(matkType, {
        citated_elements: citatedEndNotesIds,
        citateid: citateId,
      });
      node = node.mark([mark]);
      if (citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr
            .replaceWith(citatStartPos, citatEndPos, node)
            .setMeta('citatsTextChange', true)
        );
      } else {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(
            insertionView.state.selection.from,
            insertionView.state.selection.to,
            node
          )
        );
      }

      citatedEndNotesIds.forEach((citatId) => {
        if (!endNotesCitations[citatId]) {
          endNotesCitations[citatId] = 1;
        } else {
          endNotesCitations[citatId]++;
        }
      });

      this.serviceShare.YdocService[endNotesMaping.yjsMap].set(
        'endNotesCitations',
        endNotesCitations
      );

      this.serviceShare.updateCitableElementsViews();
    } catch (e) {
      console.error(e);
    }
    this.serviceShare.YjsHistoryService.stopCapturingUndoItem();
  }

  citateSupplementaryFile(
    selectedSupplementaryFiles: boolean[],
    sectionID: string,
    citatAttrs: Attrs,
    view?: EditorView
  ): void {
    try {
      this.serviceShare.YjsHistoryService.startCapturingNewUndoItem();

      const matkType = 'supplementary_file_citation';

      const supplementaryFileMaping = citationElementMap[matkType];

      const supplementaryFileNumbers = this.serviceShare.YdocService[
        supplementaryFileMaping.yjsMap
      ].get(supplementaryFileMaping.elementNumbersObj);

      let insertionView: EditorView;
      if (!view) {
        insertionView =
          this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
      } else {
        insertionView = view;
      }
      let citatStartPos: number;
      let citatEndPos: number;

      if (citatAttrs) {
        const citatId = citatAttrs.citateid;
        insertionView.state.doc.nodesBetween(
          0,
          insertionView.state.doc.nodeSize - 2,
          (node, pos) => {
            if (
              node.marks.filter((mark) => {
                return mark.type.name == matkType;
              }).length > 0
            ) {
              const citationMark = node.marks.filter((mark) => {
                return mark.type.name == matkType;
              })[0];
              if (citationMark.attrs.citateid == citatId) {
                citatStartPos = pos;
                citatEndPos = pos + node.nodeSize;
              }
            }
          }
        );
      }

      let citateId;
      if (citatAttrs) {
        citateId = citatAttrs.citateid;
      } else {
        citateId = uuidv4();
      }
      if (selectedSupplementaryFiles.filter((e) => e).length == 0 && !citatAttrs) {
        return;
      } else if (selectedSupplementaryFiles.filter((e) => e).length == 0 && citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(citatStartPos, citatEndPos, Fragment.empty)
        );
        this.serviceShare.updateCitableElementsViews();
        return;
      }

      let citatString =
        selectedSupplementaryFiles.filter((e) => e).length > 1
          ? supplementaryFileMaping.multipleElTxt
          : supplementaryFileMaping.singleElTxt;

      const citated: number[] = [];
      selectedSupplementaryFiles.forEach((fig, index) => {
        if (fig) {
          citated.push(index + 1);
        }
      });
      citatString += citated.join(',  ');
      citatString += ' ';

      const citatedSupplementaryFilesIds = selectedSupplementaryFiles.reduce<string[]>(
        (prev, curr, index) => {
          if (curr) {
            return prev.concat(curr ? [supplementaryFileNumbers![index]] : []);
          } else {
            return prev;
          }
        },
        []
      );

      const citateNodeText = citatString;
      let node = (insertionView.state.schema as Schema).text(citateNodeText) as Node;
      const mark = (insertionView.state.schema as Schema).mark(matkType, {
        citated_elements: citatedSupplementaryFilesIds,
        citateid: citateId,
      });
      node = node.mark([mark]);
      if (citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr
            .replaceWith(citatStartPos, citatEndPos, node)
            .setMeta('citatsTextChange', true)
        );
      } else {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(
            insertionView.state.selection.from,
            insertionView.state.selection.to,
            node
          )
        );
      }

      this.serviceShare.updateCitableElementsViews();
    } catch (e) {
      console.error(e);
    }
    this.serviceShare.YjsHistoryService.stopCapturingUndoItem();
  }

  citateTables(
    selectedTables: boolean[],
    sectionID: string,
    citatAttrs: Attrs,
    view?: EditorView
  ): void {
    try {
      this.serviceShare.YjsHistoryService.startCapturingNewUndoItem();

      const matkType = 'table_citation';

      const tableMaping = citationElementMap[matkType];

      const tablesNumbers = this.serviceShare.YdocService[tableMaping.yjsMap].get(
        tableMaping.elementNumbersObj
      );

      let insertionView: EditorView;
      if (!view) {
        insertionView =
          this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
      } else {
        insertionView = view;
      }
      let citatStartPos: number;
      let citatEndPos: number;

      if (citatAttrs) {
        const citatId = citatAttrs.citateid;
        insertionView.state.doc.nodesBetween(
          0,
          insertionView.state.doc.nodeSize - 2,
          (node, pos) => {
            if (
              node.marks.filter((mark) => {
                return mark.type.name == matkType;
              }).length > 0
            ) {
              const citationMark = node.marks.filter((mark) => {
                return mark.type.name == matkType;
              })[0];
              if (citationMark.attrs.citateid == citatId) {
                citatStartPos = pos;
                citatEndPos = pos + node.nodeSize;
              }
            }
          }
        );
      }

      let citateId;
      if (citatAttrs) {
        citateId = citatAttrs.citateid;
      } else {
        citateId = uuidv4();
      }
      if (selectedTables.filter((e) => e).length == 0 && !citatAttrs) {
        return;
      } else if (selectedTables.filter((e) => e).length == 0 && citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(citatStartPos, citatEndPos, Fragment.empty)
        );

        this.serviceShare.updateCitableElementsViews();
        return;
      }

      let citatString =
        selectedTables.filter((e) => e).length > 1
          ? tableMaping.multipleElTxt
          : tableMaping.singleElTxt;

      const citated: number[] = [];
      selectedTables.forEach((fig, index) => {
        if (fig) {
          citated.push(index + 1);
        }
      });
      citatString += citated.join(',  ');
      citatString += ' ';

      const citatedFigureIds = selectedTables.reduce<string[]>((prev, curr, index) => {
        if (curr) {
          return prev.concat(curr ? [tablesNumbers![index]] : []);
        } else {
          return prev;
        }
      }, []);

      const tables = this.serviceShare.YdocService[tableMaping.yjsMap].get(tableMaping.elementsObj);

      citatedFigureIds.forEach((tableId: string) => {
        tables[tableId].viewed_by_citat = sectionID;
        tables[tableId].tablePlace = sectionID;
      });

      this.serviceShare.YdocService[tableMaping.yjsMap].set(tableMaping.elementsObj, tables);

      const citateNodeText = citatString;
      let node = (insertionView.state.schema as Schema).text(citateNodeText) as Node;
      const mark = (insertionView.state.schema as Schema).mark(matkType, {
        citated_elements: citatedFigureIds,
        citateid: citateId,
      });
      node = node.mark([mark]);
      if (citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr
            .replaceWith(citatStartPos, citatEndPos, node)
            .setMeta('citatsTextChange', true)
        );
      } else {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(
            insertionView.state.selection.from,
            insertionView.state.selection.to,
            node
          )
        );
      }

      this.serviceShare.updateCitableElementsViews();
    } catch (e) {
      console.error(e);
    }
    this.serviceShare.YjsHistoryService.stopCapturingUndoItem();
  }

  citateFigures(
    selectedFigures: boolean[],
    figuresComponentsChecked: { [key: string]: boolean[] },
    sectionID: string,
    citatAttrs: Attrs,
    view?: EditorView
  ): void {
    try {
      this.serviceShare.YjsHistoryService.startCapturingNewUndoItem();

      const matkType = 'citation';

      const figureMaping = citationElementMap[matkType];

      const figuresNumbers = this.serviceShare.YdocService[figureMaping.yjsMap].get(
        figureMaping.elementNumbersObj
      );
      const figures = this.serviceShare.YdocService[figureMaping.yjsMap].get(
        figureMaping.elementsObj
      );

      let insertionView: EditorView;
      if (!view) {
        insertionView =
          this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
      } else {
        insertionView = view;
      }
      let citatStartPos: number;
      let citatEndPos: number;

      if (citatAttrs) {
        const citatId = citatAttrs.citateid;
        insertionView.state.doc.nodesBetween(
          0,
          insertionView.state.doc.nodeSize - 2,
          (node, pos) => {
            if (
              node.marks.filter((mark) => {
                return mark.type.name == matkType;
              }).length > 0
            ) {
              const citationMark = node.marks.filter((mark) => {
                return mark.type.name == matkType;
              })[0];
              if (citationMark.attrs.citateid == citatId) {
                citatStartPos = pos;
                citatEndPos = pos + node.nodeSize;
              }
            }
          }
        );
      }

      let citateId;
      if (citatAttrs) {
        citateId = citatAttrs.citateid;
      } else {
        citateId = uuidv4();
      }
      if (selectedFigures.length == 0 && !citatAttrs) {
        return;
      } else if (selectedFigures.length == 0 && citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(citatStartPos, citatEndPos, Fragment.empty)
        );

        this.serviceShare.updateCitableElementsViews();
        return;
      }

      let citatString =
        selectedFigures.length > 1 ? figureMaping.multipleElTxt : figureMaping.singleElTxt;

      const citated = [];
      selectedFigures.forEach((fig, index) => {
        if (fig) {
          citated.push(index + 1);
          if (figuresComponentsChecked[figuresNumbers![index]].filter((e) => !e).length > 0) {
            let count = 0;
            figuresComponentsChecked[figuresNumbers![index]].forEach((comp, j) => {
              if (comp) {
                if (count == 0) {
                  citated[citated.length - 1] += String.fromCharCode(97 + j);
                  count++;
                } else {
                  citated.push(String.fromCharCode(97 + j));
                  count++;
                }
              }
            });
          }
        }
      });
      citatString += citated.join(',  ');
      citatString += ' ';

      const citatedFigureIds = selectedFigures.reduce<string[]>((prev, curr, index) => {
        if (curr) {
          if (
            figuresComponentsChecked[figuresNumbers![index]].filter((e) => e).length ==
            figuresComponentsChecked[figuresNumbers![index]].length
          ) {
            return prev.concat(curr ? [figuresNumbers![index]] : []);
          } else {
            const idsWithComponents = figuresComponentsChecked[figuresNumbers![index]].reduce<
              string[]
            >((p, c, i) => {
              const componentID = figures[figuresNumbers![index]].components[i].id;
              return p.concat(c ? [figuresNumbers![index] + '|' + componentID] : []);
            }, []);
            return prev.concat(idsWithComponents);
          }
        } else {
          return prev;
        }
      }, []);

      citatedFigureIds.forEach((figureId: string) => {
        const id = figureId.split('|')[0];
        figures[id].viewed_by_citat = sectionID;
        figures[id].tablePlace = sectionID;
      });

      this.serviceShare.YdocService[figureMaping.yjsMap].set(figureMaping.elementsObj, figures);

      const citateNodeText = citatString;
      let node = (insertionView.state.schema as Schema).text(citateNodeText) as Node;
      const mark = (insertionView.state.schema as Schema).mark(matkType, {
        citated_elements: citatedFigureIds,
        citateid: citateId,
      });
      node = node.mark([mark]);
      if (citatAttrs) {
        insertionView.dispatch(
          insertionView.state.tr
            .replaceWith(citatStartPos, citatEndPos, node)
            .setMeta('citatsTextChange', true)
        );
      } else {
        insertionView.dispatch(
          insertionView.state.tr.replaceWith(
            insertionView.state.selection.from,
            insertionView.state.selection.to,
            node
          )
        );
      }

      this.serviceShare.updateCitableElementsViews();
    } catch (e) {
      console.error(e);
    }
    this.serviceShare.YjsHistoryService.stopCapturingUndoItem();
  }

  updateElementsCitationsV2(): void {
    const citations = this.helperCitableElementsService.getElementsCitations(
      this.serviceShare.ProsemirrorEditorsService.editorContainers,
      true
    );
    this.updateCitatsText(citations);
    setTimeout(() => {
      this.serviceShare.YjsHistoryService.endBigOperationCapture();
    }, 10);
  }

  allViewAreRendered(): void {
    setTimeout(() => {
      if (this.updatingElementsAndElementsCitations) {
        this.updateElementsCitationsV2();
      } else {
        this.serviceShare.YjsHistoryService.endBigOperationCapture();
      }
      citationsPMNodeNames.forEach((citType) => {
        if (this.elementsFromPopupEdit[citType]) {
          this.elementsFromPopupEdit[citType] = undefined;
        }
      });
    }, 10);
  }

  countRenderedViews(): void {
    this.renderedViews++;
    if (this.renderedViews == 2) {
      if (this.subscription) {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
      }
      this.renderedViews = 0;
      this.allViewAreRendered();
    }
  }

  displayElements(
    citats: Citations,
    elsDisplayedInObj: { [key: string]: { [key: string]: string } },
    olderVersion?: { [key: string]: { [key: string]: { added?: boolean; removed?: boolean } } }
  ): void {
    if (this.subscription) {
      return;
    }
    const elementObjs = this.getElementsObj();
    const elementsTemplates = this.getElementsTemplates();
    let numberOfElsObj: number = 0;

    Object.keys(elementObjs).forEach((type) => {
      const elsOfType = elementObjs[type];
      const nOfEls = Object.keys(elsOfType).filter((elId) => {
        return elsDisplayedInObj[type][elId] != 'endEditor';
      }).length;
      numberOfElsObj += nOfEls;
    });
    if (numberOfElsObj == 0 && !olderVersion) {
      this.countRenderedViews();
      return;
    }
    const doneEditing = new Subject<{
      renderedViewsMetaData: {
        renderedData: Node;
        elementData: { tableID: string };
        elType: string;
      }[];
      citationType: string;
      citatID: string;
      edView: EditorView;
    }>();
    Object.keys(citats).forEach((sectionId) => {
      const view =
        this.serviceShare.ProsemirrorEditorsService.editorContainers[sectionId].editorView;
      const citatsInEditor = citats[sectionId];
      Object.keys(citatsInEditor).forEach((citatId) => {
        const citat = citatsInEditor[citatId];
        const citatID = citatId;
        const editFigureContainer = (
          citatID: string,
          dispatchSubject: Subject<any>,
          elementsViewsToDisplay: any[],
          edView: EditorView
        ) => {
          const renderedViewsMetaData = [];
          let renderedViews = 0;
          elementsViewsToDisplay.forEach((displayedView, i) => {
            const elType = displayedView.type;
            const elId = displayedView.elId;
            const elementData = elementObjs[elType][elId];
            const elementTemplate = elementsTemplates[elType][elId];
            const typeMapping = citationElementMap[ElementsTypeToCitationMap[elType]];
            typeMapping.setSectionData(elementData, sectionId, citatID);

            let serializedElementToFormIOsubmission;
            let elementFormGroup;
            if (elType == 'table') {
              serializedElementToFormIOsubmission =
                this.deletedTablesForRerender[elId] ||
                typeMapping.getElFormIOSubmission(elementData, citatID, this.serviceShare);
              serializedElementToFormIOsubmission.tableNumber = elementData.tableNumber;
              serializedElementToFormIOsubmission.viewed_by_citat = citatID;
              elementFormGroup = typeMapping.buildElementFormGroup(
                serializedElementToFormIOsubmission
              );
            } else {
              serializedElementToFormIOsubmission = typeMapping.getElFormIOSubmission(
                elementData,
                citatID
              );
              elementFormGroup = typeMapping.buildElementFormGroup(
                serializedElementToFormIOsubmission
              );
            }

            if (!elementTemplate?.html) return;
            this.serviceShare.ProsemirrorEditorsService.editMode = true;
            this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(
              elementTemplate.html,
              serializedElementToFormIOsubmission,
              elementFormGroup
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ).then((data: any) => {
              let oldVersionAction: string;

              if (elType == 'figure') {
                if (elementData.canvasData?.nOfColumns) {
                  data = data.replaceAll(
                    'alt="image"',
                    `alt="image" numberOfColumns="${elementData.canvasData.nOfColumns}"`
                  );
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                elementData.components.forEach((component: any) => {
                  if (
                    component.url.includes(this.config.CDNService) &&
                    component.componentType == 'image'
                  ) {
                    const imageId = component.url.split('m/')[1];
                    data = data.replace(
                      'img-placeholder _ng',
                      `img-placeholder class="image-placeholder" numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" src imageId="${imageId}" imageSrc="${this.config.CDNService}" isFromCDN="true" _ng`
                    );
                    data = data.replace(
                      'src=""',
                      `src imageId="${imageId}" class="figure-image" imageSrc="${this.config.CDNService}" isFromCDN="true"`
                    );
                    data = data.replace(
                      'class="figure-zoom"',
                      `class="figure-zoom " src="${this.config.CDNService}/${imageId}"`
                    );
                    data = data.replace(
                      'class="figure-download"',
                      `class="figure-download " src="${this.config.CDNService}/${imageId}"`
                    );
                  } else if (
                    component.componentType == 'image' &&
                    !component.url.includes(this.config.CDNService)
                  ) {
                    data = data.replace(
                      'img-placeholder _ng',
                      `img-placeholder numberOfColumns="${serializedElementToFormIOsubmission.nOfColumns}" class="image-placeholder" imageSrc="${component.url}" isFromCDN="false" _ng`
                    );
                    data = data.replace(
                      'src=""',
                      `src="${component.url}" class="figure-image" isFromCDN="false"`
                    );
                    data = data.replace(
                      'class="figure-zoom"',
                      `class="figure-zoom " src="${component.url}"`
                    );
                    data = data.replace(
                      'class="figure-download"',
                      `class="figure-download " src="${component.url}"`
                    );
                  }
                });
              }

              const templ = document.createElement('div');
              templ.innerHTML = data;
              const DOMParserFormCurrSection = DOMParser.fromSchema(view.state.schema);
              const Slice = DOMParserFormCurrSection.parse(templ.firstChild!);

              renderedViews++;

              if (olderVersion) {
                if (olderVersion[elType][elId]?.added) {
                  const userColor = this.serviceShare.ProsemirrorEditorsService.permanentUserData
                    .getUserByClientId(serializedElementToFormIOsubmission.clientID)
                    .split('&')[1];
                  oldVersionAction = `border: 2px solid ${userColor}; padding: 3px; border-radius: 3px; display: block;`;
                  //@ts-expect-error 'attrs' may not have a defined index signature for 'styling'
                  Slice.content.firstChild.attrs['styling'] = oldVersionAction;
                  //@ts-expect-error 'attrs' may not have a defined index signature for 'class'
                  Slice.content.firstChild.attrs['class'] = 'insertion';
                } else if (olderVersion[elType][elId]?.removed) {
                  oldVersionAction = 'citable-element-removed deletion';
                  //@ts-expect-error 'attrs' may not have a defined index signature for 'class'
                  Slice.content.firstChild.attrs['class'] = oldVersionAction;
                }
              }

              renderedViewsMetaData[i] = {
                renderedData: Slice.content.firstChild,
                elementData,
                elType,
              };
              if (renderedViews == elementsViewsToDisplay.length) {
                dispatchSubject.next({
                  renderedViewsMetaData: renderedViewsMetaData.sort(
                    (a, b) =>
                      a.elementData[typeMapping.elementNumberProp] -
                      b.elementData[typeMapping.elementNumberProp]
                  ),
                  citationType: ElementsTypeToCitationMap[citat.citationType],
                  citatID,
                  edView,
                });
              }
            });
          });
        };
        if (citat.displayedViewsHere.length > 0) {
          editFigureContainer(citatID, doneEditing, citat.displayedViewsHere, view);
        }
      });
    });
    let rendered = 0;
    const checkRendered = (nOnRenderedItems: number): void => {
      rendered += nOnRenderedItems;

      if (rendered == numberOfElsObj) {
        this.countRenderedViews();
        this.subscription?.unsubscribe();
        this.subscription = undefined;
      }
    };
    this.subscription = doneEditing.subscribe((data) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let citatNewPosition: any;
        const wrappingNodes = [
          'paragraph',
          'heading',
          'table',
          'code_block',
          'ordered_list',
          'bullet_list',
          'math_inline',
          'math_display',
        ];
        let resolvedPositionOfCitat: ResolvedPos;
        let posAtParentBorder: number;
        let resolvedPositionATparentNodeBorder: ResolvedPos;
        let contAfter: Node;
        const updateMetaInfo = () => {
          const docSize = data.edView.state.doc.nodeSize;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.edView.state.doc.nodesBetween(0, docSize - 2, (node: any, pos: any) => {
            const marks = node.marks.filter((mark: Mark) => {
              return mark.type.name == data.citationType;
            });
            if (marks.length > 0 && marks[0].attrs.citateid == data.citatID) {
              citatNewPosition = pos;
            }
          });
          if (!citatNewPosition) {
            return;
          }
          resolvedPositionOfCitat = data.edView.state.doc.resolve(citatNewPosition);
          //@ts-expect-error: path may not exist on markResolvePos based on its type definition
          const resolvedCitationPath: Array<Node | number> = resolvedPositionOfCitat.path;
          let offsetOfwrappingParent: number;
          let wrappingParent;

          if (!wrappingParent) {
            for (let i = resolvedCitationPath.length - 1; i > -1; i--) {
              const el = resolvedCitationPath[i];
              if (el instanceof Node) {
                if (wrappingNodes.includes(el.type.name)) {
                  offsetOfwrappingParent = resolvedCitationPath[i - 1] as number;
                  wrappingParent = el;
                }
              }
            }
          }

          posAtParentBorder = offsetOfwrappingParent! + wrappingParent?.nodeSize;
          resolvedPositionATparentNodeBorder = data.edView.state.doc.resolve(posAtParentBorder);
          contAfter = data.edView.state.doc.nodeAt(posAtParentBorder)!;

          contAfter = resolvedPositionATparentNodeBorder.nodeAfter;
          while (
            contAfter &&
            [...elementsContainersPMNodesNames, 'example'].includes(contAfter.type.name)
          ) {
            posAtParentBorder = posAtParentBorder + contAfter.nodeSize;
            resolvedPositionATparentNodeBorder = data.edView.state.doc.resolve(posAtParentBorder);
            contAfter = resolvedPositionATparentNodeBorder.nodeAfter;
          }
        };

        updateMetaInfo();
        if (!resolvedPositionATparentNodeBorder) {
          return;
        }
        const viesNodes: Node[] = [];
        data.renderedViewsMetaData.forEach((renderedViewData) => {
          const type = renderedViewData.elType;
          const node = renderedViewData.renderedData;
          const containerNodeName =
            citationElementMap[ElementsTypeToCitationMap[type]].containerNodeName;
          if (containerNodeName == 'tables_nodes_container') {
            const node1 = data.edView.state.schema.nodes[containerNodeName].create({}, node);
            const xmlFragment = this.serviceShare.YdocService.ydoc.getXmlFragment(
              node.attrs.table_id
            );
            updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());

            const containerNode = data.edView.state.schema.nodes['example'].create({
              id: node.attrs.table_id,
            });
            viesNodes.push(containerNode);
          } else {
            const containerNode = data.edView.state.schema.nodes[containerNodeName].create(
              {},
              node
            );
            viesNodes.push(containerNode);
          }
        });
        const viesNodesFragment = Fragment.from(viesNodes);
        data.edView.dispatch(
          data.edView.state.tr
            .insert(resolvedPositionATparentNodeBorder.pos, viesNodesFragment)
            .setMeta('citable-elements-rerender', true)
        );
        setTimeout(() => {
          checkRendered(data.renderedViewsMetaData.length);
        }, 0);
      } catch (e) {
        console.error(e);
      }
    });
  }

  updateElementsAndElementsCitations(olderVersionElements?: OlderVersionCitableElements): void {
    let olderElements = {};
    this.updatingElementsAndElementsCitations = true;
    const citations = this.helperCitableElementsService.getElementsCitations(
      this.serviceShare.ProsemirrorEditorsService.editorContainers
    );
    if (!olderVersionElements) {
      this.updateCitatsText(citations);
    }
    const elsDisplayedInObj = {};
    const elementsObj = this.getElementsObj();
    if (olderVersionElements) {
      Object.keys(olderVersionElements).forEach((elType: string) => {
        olderElements[elType] = {};
        elsDisplayedInObj[elType] = {};

        Object.keys(olderVersionElements[elType]).forEach((elId: string) => {
          if (!elementsObj[elType][elId]) {
            elementsObj[elType][elId] = olderVersionElements[elType][elId];
            olderElements[elType][elId] = { removed: true };
          }
          if (elType !== ElementsTypes.endNote && elType !== ElementsTypes.supplementaryFile) {
            elsDisplayedInObj[elType][elId] = 'endEditor';
          }
        });
        Object.keys(elementsObj[elType]).forEach((elId: string) => {
          if (
            !olderVersionElements[elType][elId] ||
            elementsObj[elType][elId].editedInVersion ==
              this.serviceShare.YdocService.lastSelectedVersion ||
            (olderVersionElements[elType][elId].viewed_by_citat !=
              elementsObj[elType][elId].viewed_by_citat &&
              !olderElements[elType][elId]?.removed)
          ) {
            olderElements[elType][elId] = { added: true };
          }
          if (elType !== ElementsTypes.endNote && elType !== ElementsTypes.supplementaryFile) {
            elsDisplayedInObj[elType][elId] = 'endEditor';
          }
        });
      });
    } else {
      Object.keys(elementsObj).forEach((elType: string) => {
        elsDisplayedInObj[elType] = {};
        Object.keys(elementsObj[elType]).forEach((elId: string) => {
          if (elType !== ElementsTypes.endNote && elType !== ElementsTypes.supplementaryFile) {
            elsDisplayedInObj[elType][elId] = 'endEditor';
          }
        });
      });
      olderElements = undefined;
    }
    const newCitatsObj = this.markElementsCitatsViews(citations, elsDisplayedInObj, olderElements);
    if (
      !olderElements &&
      this.serviceShare.compareObjects(
        this.serviceShare.YdocService.citableElementsMap.get('elementsCitations'),
        newCitatsObj
      )
    ) {
      this.serviceShare.YdocService.citableElementsMap.set('elementsCitations', newCitatsObj);
    }
    this.displayElements(newCitatsObj, elsDisplayedInObj, olderElements);
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 2000);
  }

  updateOnlyElementsViews(): void {
    this.updatingOnlyElementsView = true;
    const citations = this.helperCitableElementsService.getElementsCitations(
      this.serviceShare.ProsemirrorEditorsService.editorContainers
    );
    const elsDisplayedInObj = {};
    const elementsObj = this.getElementsObj();
    Object.keys(elementsObj).forEach((elType) => {
      elsDisplayedInObj[elType] = {};
      Object.keys(elementsObj[elType]).forEach((elId) => {
        if (elType !== ElementsTypes.endNote && elType !== ElementsTypes.supplementaryFile) {
          elsDisplayedInObj[elType][elId] = 'endEditor';
        }
      });
    });
    const newCitatsObj = this.markElementsCitatsViews(citations, elsDisplayedInObj);
    if (
      this.serviceShare.compareObjects(
        this.serviceShare.YdocService.citableElementsMap.get('elementsCitations'),
        newCitatsObj
      )
    ) {
      this.serviceShare.YdocService.citableElementsMap.set('elementsCitations', newCitatsObj);
    }
    this.displayElements(newCitatsObj, elsDisplayedInObj);
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 2000);
  }
}
