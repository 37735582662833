import { CustomErrorDetails } from '../models';
import { HttpErrorResponse } from '@angular/common/http';
export enum CustomErrorType {
  http = 'HTTP',
  proseMirror = 'ProseMirror',
  ui = 'UI',
  runtime = 'Runtime',
  zone = 'Zone.js',
  unknown = 'Unknown',
  network = 'Network',
}

export interface CustomError {
  customErrorType: CustomErrorType;
  timestamp: number;
  message: string;
  details: CustomErrorDetails;
}

export interface HttpErrorDetails {
  url: string;
  method: string;
  userAgent: string;
  payload?: unknown;
  userMessage: string;
  status: string | number;
  originalError: HttpErrorResponse;
}

export interface ProsemirrorErrorDetails {
  documentState: unknown;
  pluginName: string;
}

export interface UiErrorDetails {
  componentName: string;
  message: string;
  otherDetails?: string;
}

export interface RuntimeErrorDetails {
  originalError: Error;
  context: string;
  message: string;
  userMessage: string;
  stack: string;
}

export interface UnknownErrorDetails {
  error: unknown;
  userMessage: string;
}

export interface ZoneErrorDetails {
  originalError: Error;
  zoneName: string;
  userMessage: string;
}

export interface ErrorHandlerConfig {
  loggingConfig: {
    enabled: boolean;
    excludedTypes?: CustomErrorType[];
  };
  notificationConfig: {
    enabled: boolean;
    includedTypes: CustomErrorType[];
  };
  backendPublishConfig: {
    enabled: boolean;
    excludedTypes: CustomErrorType[];
  };
}

export interface NetworkErrorDetails {
  url?: string;
  method?: string;
  headers?: Record<string, string>;
  body?: unknown;
}
