import { Node } from 'prosemirror-model';
import { CollaboratorAnonymizationService } from '@app/editor/services/collaborator-anonymization/collaborator-anonymization.service';

// Get the service instance from the insertion mark
import { setCollaboratorAnonymizationService } from './insertionMark';
export { setCollaboratorAnonymizationService };

// Reference to the service
let collaboratorAnonymizationService: CollaboratorAnonymizationService;

// This function will be called to set the service instance
export function setDeletionAnonymizationService(service: CollaboratorAnonymizationService): void {
  collaboratorAnonymizationService = service;
}

const deletion = {
  attrs: {
    class: { default: 'deletion' },
    id: { default: '' },
    user: { default: 0 },
    username: { default: '' },
    userColor: { default: '' },
    userContrastColor: { default: '' },
    date: { default: 0 },
    group: { default: '' },
    viewid: { default: '' },
    style: { default: null },
    connectedTo: { default: '' },
  },
  inclusive: false,
  group: 'track',
  parseDOM: [
    {
      tag: 'span.deletion',
      getAttrs(dom: HTMLElement) {
        return {
          class: dom.getAttribute('class'),
          style: dom.getAttribute('style'),
          id: dom.dataset.id,
          userColor: dom.getAttribute('usercolor'),
          userContrastColor: dom.getAttribute('usercontrastcolor'),
          user: dom.getAttribute('user'),
          connectedTo: dom.getAttribute('connectedto'),
          username: dom.dataset.username,
          date: parseInt(dom.dataset.date),
          group: dom.dataset.group,
          viewid: dom.dataset.viewid,
        };
      },
    },
  ],
  toDOM(node: Node) {
    // Check if the track change should be hidden
    const userId = node.attrs.user;
    const shouldHide =
      collaboratorAnonymizationService &&
      userId &&
      collaboratorAnonymizationService.shouldHideChangePropositions(userId);

    if (shouldHide) {
      // For hidden deletion propositions, render as normal text
      // We keep the node but remove all track change styling
      return ['span', {}];
    }

    // For visible deletion propositions, proceed with normal rendering
    return [
      'span',
      {
        class: node.attrs.class + ` history-color color-${node.attrs.userColor}`,
        'data-id': node.attrs.id,
        user: node.attrs.user,
        connectedto: node.attrs.connectedTo,
        usercolor: node.attrs.userColor,
        usercontrastcolor: node.attrs.userContrastColor,
        'data-color': node.attrs.color,
        'data-username': node.attrs.username,
        'data-date': node.attrs.date,
        'data-group': node.attrs.group,
        'data-viewid': node.attrs.viewid,
        style:
          node.attrs.style +
          ';color: ' +
          node.attrs.userContrastColor +
          ';background: ' +
          node.attrs.userColor,
      },
    ];
  },
};

export default deletion;
