import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  map,
  mergeMap,
  withLatestFrom,
  tap,
  take,
  takeUntil,
  filter,
  distinctUntilChanged,
  shareReplay,
} from 'rxjs/operators';
import { combineLatest, Subject, Observable } from 'rxjs';
import * as EditModeActions from './edit-mode.actions';
import * as EditModeSelectors from './edit-mode.selectors';
import { EditMode } from '@app/editor/services/edit-mode/models';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';
import { Action } from '@ngrx/store';

@Injectable()
export class EditModeEffects implements OnDestroy {
  mode$: Observable<EditMode>;
  isEditMode$: Observable<boolean>;
  isSuggestMode$: Observable<boolean>;
  isPreviewMode$: Observable<boolean>;

  // Effects
  initializeEditMode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditModeActions.initializeEditMode),
      mergeMap((action) => {
        return combineLatest([
          this.ydocService.docReadyForUse$,
          this.store.select(EditModeSelectors.selectEditModePermissions),
        ]).pipe(
          take(1),
          map(([, permissions]) => ({
            userSettings: action.userSettings,
            permissions,
          }))
        );
      }),
      mergeMap(({ userSettings, permissions }) => {
        const actions = [];

        // Get stored preference from YDoc user settings
        const userId = this.ydocService?.userInfo?.data?.id;
        const collaboratorsData = this.ydocService?.collaborators?.get('collaborators');
        const currentUser = collaboratorsData?.collaborators?.find((c) => c.id === userId);
        const storedEditMode = currentUser?.settings?.editMode;

        // Select mode based on permissions and user preference
        let selectedMode: EditMode;
        if ((!permissions.canEdit && !permissions.canSuggest) || storedEditMode === 'preview') {
          // If no permission for edit or suggest, use preview mode
          selectedMode = EditMode.preview;
        } else if (storedEditMode === 'suggest' && permissions.canSuggest) {
          // Use stored preference if available and permitted
          selectedMode = EditMode.suggest;
        } else if (storedEditMode === 'edit' && permissions.canEdit) {
          // Use stored edit mode if permitted
          selectedMode = EditMode.edit;
        } else if (userSettings?.editMode === 'suggest' && permissions.canSuggest) {
          // Fall back to userSettings from params if available
          selectedMode = EditMode.suggest;
        } else if (permissions.canEdit) {
          // Default to edit mode if allowed
          selectedMode = EditMode.edit;
        } else if (permissions.canSuggest) {
          // Default to suggest mode if allowed
          selectedMode = EditMode.suggest;
        } else {
          // Fallback to preview mode
          selectedMode = EditMode.preview;
        }

        // Always add the setEditMode action
        actions.push(EditModeActions.setEditMode({ mode: selectedMode }));

        // If suggest mode is selected, we ensure track changes is properly activated
        if (selectedMode === EditMode.suggest) {
          // Add a delay to ensure all editors are initialized before
          // activating track changes explicitly
          setTimeout(() => {
            console.log('Initializing track changes for suggest mode');
            this.trackChangesService.changeInEditors();
          }, 500);
        }

        return actions;
      })
    );
  });

  validateCurrentMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditModeActions.updatePermissions),
      withLatestFrom(
        this.store.select(EditModeSelectors.selectCurrentMode),
        this.store.select(EditModeSelectors.selectEditModePermissions)
      ),
      map(([{ permissions }, currentMode]) => {
        if (!permissions.canEdit && !permissions.canSuggest) {
          return EditModeActions.setEditMode({ mode: EditMode.preview });
        }

        if (currentMode === EditMode.edit && !permissions.canEdit) {
          return EditModeActions.setEditMode({
            mode: permissions.canSuggest ? EditMode.suggest : EditMode.preview,
          });
        }

        if (currentMode === EditMode.suggest && !permissions.canSuggest) {
          return EditModeActions.setEditMode({
            mode: permissions.canEdit ? EditMode.edit : EditMode.preview,
          });
        }

        return { type: '[Edit Mode] No Mode Change Required' };
      })
    )
  );

  handleModeChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditModeActions.setEditMode),
        tap(({ mode }) => {
          // Update preview mode
          this.prosemirrorEditorsService.previewArticleMode.mode = mode === EditMode.preview;

          // Update track changes state - store subscription handles the flag
          // Just need to explicitly call changeInEditors to scan for changes
          if (mode === EditMode.suggest) {
            this.trackChangesService.changeInEditors();
          }

          // Update editor focus
          this.detectFocusService.setSelectionDecorationOnLastSelectedEditor();
          this.persistUserModePreference(mode);
        })
      ),
    { dispatch: false }
  );

  persistModePreference$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditModeActions.setEditMode),
        withLatestFrom(this.store.select(EditModeSelectors.selectEditModePermissions)),
        filter(([{ mode }, permissions]) => this.shouldPersistMode(mode, permissions)),
        tap(([{ mode }]) => this.persistUserModePreference(mode))
      ),
    { dispatch: false }
  );
  private destroy$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly actions$: Actions<Action>,
    private readonly store: Store,
    private readonly prosemirrorEditorsService: ProsemirrorEditorsService,
    private readonly ydocService: YdocService,
    private readonly detectFocusService: DetectFocusService,
    private readonly trackChangesService: TrackChangesService
  ) {
    // Initialize observables
    this.mode$ = this.store
      .select(EditModeSelectors.selectCurrentMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    this.isEditMode$ = this.store
      .select(EditModeSelectors.selectIsEditMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    this.isSuggestMode$ = this.store
      .select(EditModeSelectors.selectIsSuggestMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));

    this.isPreviewMode$ = this.store
      .select(EditModeSelectors.selectIsPreviewMode)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), shareReplay(1));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Public methods
  setMode(mode: EditMode | undefined): void {
    if (mode === undefined) {
      this.toggleEditMode();
      return;
    }

    if (mode === EditMode.edit) {
      this.setEditModeWithPermissionCheck();
    } else if (mode === EditMode.suggest) {
      this.setSuggestModeWithPermissionCheck();
    } else if (mode === EditMode.preview) {
      // Preview mode is always allowed
      this.store.dispatch(EditModeActions.setEditMode({ mode: EditMode.preview }));
    }
  }

  // Private methods
  /**
   * Determines if a mode should be persisted based on permissions.
   * @private
   */
  private shouldPersistMode(
    mode: EditMode,
    permissions: { canEdit: boolean; canSuggest: boolean }
  ): boolean {
    // Only persist edit and suggest modes when user has permission
    return (
      mode === EditMode.preview ||
      (mode === EditMode.edit && permissions.canEdit) ||
      (mode === EditMode.suggest && permissions.canSuggest)
    );
  }

  /**
   * Persists user mode preference to settings.
   * @private
   */
  private persistUserModePreference(mode: EditMode): void {
    const userId = this.ydocService?.userInfo?.data?.id;

    // Skip if we can't identify the user
    if (!userId || !this.ydocService?.collaborators) {
      console.warn('Cannot persist mode preference: user ID or collaborators map missing');
      return;
    }

    try {
      // Get collaborators data
      const collaboratorsData = this.ydocService.collaborators.get('collaborators');

      // Update user settings
      this.updateUserSettings(mode);

      // Update collaborator settings
      const updatedCollaborators = collaboratorsData.collaborators.map((c) =>
        c.id === userId ? { ...c, settings: { ...c.settings, editMode: mode } } : c
      );
      this.ydocService.collaborators.set('collaborators', {
        collaborators: updatedCollaborators,
      });
    } catch (error) {
      console.error('Error updating edit mode preference:', error);
    }
  }

  /**
   * Updates user settings with mode preference.
   * @private
   */
  private updateUserSettings(modeString: string): void {
    if (!this.ydocService?.userInfo?.data?.id) {
      console.warn('Cannot update user settings: user ID missing');
      return;
    }

    // Get the current user from the collaborators map
    const userId = this.ydocService.userInfo.data.id;
    const collaboratorsData = this.ydocService.collaborators.get('collaborators');
    const currentUser = collaboratorsData?.collaborators?.find((c) => c.id === userId);

    if (!currentUser) {
      console.warn('Cannot update user settings: user not found in collaborators');
      return;
    }

    // Clone the user data
    const updatedUserData = JSON.parse(JSON.stringify(currentUser));

    // Ensure settings path exists
    if (!updatedUserData.settings) {
      updatedUserData.settings = {};
    }

    if (!updatedUserData.settings.init) {
      updatedUserData.settings.init = {};
    }

    // Set the edit mode
    updatedUserData.settings.init.editMode = modeString;

    // Update the collaborators map
    const updatedCollaborators = collaboratorsData.collaborators.map((c) =>
      c.id === userId ? updatedUserData : c
    );
    this.ydocService.collaborators.set('collaborators', {
      collaborators: updatedCollaborators,
    });
  }

  /**
   * Attempts to set the edit mode if permissions allow it.
   * @private
   */
  private setEditModeWithPermissionCheck(): void {
    this.store
      .select(EditModeSelectors.selectCanEdit)
      .pipe(take(1))
      .subscribe((canEdit) => {
        if (canEdit) {
          this.store.dispatch(EditModeActions.setEditMode({ mode: EditMode.edit }));
          return;
        }

        this.fallbackFromEditMode();
      });
  }

  /**
   * Finds the best fallback mode when edit mode isn't permitted.
   * @private
   */
  private fallbackFromEditMode(): void {
    this.store
      .select(EditModeSelectors.selectCanSuggest)
      .pipe(take(1))
      .subscribe((canSuggest) => {
        const fallbackMode = canSuggest ? EditMode.suggest : EditMode.preview;
        this.store.dispatch(EditModeActions.setEditMode({ mode: fallbackMode }));
      });
  }

  /**
   * Attempts to set the suggest mode if permissions allow it.
   * @private
   */
  private setSuggestModeWithPermissionCheck(): void {
    this.store
      .select(EditModeSelectors.selectCanSuggest)
      .pipe(take(1))
      .subscribe((canSuggest) => {
        if (canSuggest) {
          this.store.dispatch(EditModeActions.setEditMode({ mode: EditMode.suggest }));
          return;
        }

        this.fallbackFromSuggestMode();
      });
  }

  /**
   * Finds the best fallback mode when suggest mode isn't permitted.
   * @private
   */
  private fallbackFromSuggestMode(): void {
    this.store
      .select(EditModeSelectors.selectCanEdit)
      .pipe(take(1))
      .subscribe((canEdit) => {
        const fallbackMode = canEdit ? EditMode.edit : EditMode.preview;
        this.store.dispatch(EditModeActions.setEditMode({ mode: fallbackMode }));
      });
  }

  /**
   * Toggles between edit and suggest modes based on current mode and user permissions.
   * @private
   */
  private toggleEditMode(): void {
    combineLatest([
      this.mode$.pipe(take(1)),
      this.store.select(EditModeSelectors.selectCanEdit).pipe(take(1)),
      this.store.select(EditModeSelectors.selectCanSuggest).pipe(take(1)),
    ]).subscribe(([currentMode, canEdit, canSuggest]) => {
      this.handleModeToggle(currentMode, canEdit, canSuggest);
    });
  }

  /**
   * Processes the mode toggle based on current state and permissions.
   * @private
   */
  private handleModeToggle(currentMode: EditMode, canEdit: boolean, canSuggest: boolean): void {
    const isInSuggestMode = currentMode === EditMode.suggest;
    const isInEditMode = currentMode === EditMode.edit;

    if (isInSuggestMode && canEdit) {
      this.store.dispatch(EditModeActions.setEditMode({ mode: EditMode.edit }));
      return;
    }

    if (isInEditMode && canSuggest) {
      this.store.dispatch(EditModeActions.setEditMode({ mode: EditMode.suggest }));
      return;
    }

    this.setFallbackMode(canEdit, canSuggest);
  }

  /**
   * Sets the best available mode based on permissions.
   * @private
   */
  private setFallbackMode(canEdit: boolean, canSuggest: boolean): void {
    let bestAvailableMode = EditMode.preview;

    if (canEdit) {
      bestAvailableMode = EditMode.edit;
    } else if (canSuggest) {
      bestAvailableMode = EditMode.suggest;
    }

    this.store.dispatch(EditModeActions.setEditMode({ mode: bestAvailableMode }));
  }
}
