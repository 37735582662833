import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { mapExternalRefs } from '@app/editor/utils/references/refsFunctions';

@Injectable()
export class ExternalRefsInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (
            event.url?.includes('http://localhost:4200/find') ||
            event.url?.includes(this.config.externalRefsApi)
          ) {
            return event.clone({ body: mapExternalRefs(event.body) });
          }
        }
        return event;
      })
    );
  }
}
