import { CustomErrorType } from './models';
import { BaseCustomError } from './base-custom.error';
import { RuntimeErrorDetails } from './models';

export class RuntimeError extends BaseCustomError {
  public customErrorType: CustomErrorType.runtime;
  public details: RuntimeErrorDetails;

  constructor(
    public originalError: Error,
    public context: string,
    public userMessage: string = 'A runtime error occurred.'
  ) {
    const timestamp = Date.now();
    super(
      `Unexpected error occurred! You can use the feedback form to report it.
      If so, please include the following details:
      - Screenshot of the error
      - Description of the steps to reproduce the error
      - Error timestamp: ${timestamp}`,
      timestamp
    );
    Error.captureStackTrace(this, RuntimeError);
    this.customErrorType = CustomErrorType.runtime;
    this.details = {
      originalError,
      context,
      userMessage,
      message: originalError.message,
      stack: originalError.stack,
    };
  }
}
