import { ElementRef, Injectable, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TextSelection } from 'prosemirror-state';

import { CommentsActions, CommentsSelectors } from '@app/store/comments';
import { CommentsService } from '@app/editor/utils/commentsService/comments.service';
import { Comment, YdocCommentThread } from '../../comment.models';
import { CommentsStateService } from '../shared-comments/comments-state.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { CommentsRenderingService } from '../comments-rendering/comments-rendering.service';

@Injectable()
export class CommentsNavigationService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private initialized = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private commentsService: CommentsService,
    private commentsStateService: CommentsStateService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private commentsRenderingService: CommentsRenderingService
  ) {}

  get navigationCounter(): string {
    if (this.commentsStateService.filteredComments.length === 0) {
      return '0/0';
    }

    const totalResults = this.commentsStateService.filteredComments.length;
    return `${this.selectedCommentNumber}/${totalResults}`;
  }

  get isPreviousDisabled(): boolean {
    return (
      this.selectedCommentNumber <= 1 || this.commentsStateService.filteredComments.length === 0
    );
  }

  get isNextDisabled(): boolean {
    return this.selectedCommentNumber >= this.commentsStateService.filteredComments.length;
  }

  get selectedCommentNumber(): number {
    if (
      !this.commentsStateService.lastSelectedComment?.commentId ||
      this.commentsStateService.filteredComments.length === 0
    ) {
      return 0;
    }

    const index = this.commentsStateService.filteredComments.findIndex(
      (result) =>
        result.pmmark.commentAttrs.id === this.commentsStateService.lastSelectedComment.commentId
    );

    return index >= 0 ? index + 1 : 0;
  }

  selectComment(comment: Comment): void {
    if (!comment?.commentAttrs?.id) {
      return;
    }

    const actualMark = this.commentsService.commentsObj[comment.commentAttrs.id];
    if (!actualMark?.section) {
      return;
    }

    const editorContainer = this.prosemirrorEditorsService.editorContainers[actualMark.section];
    if (!editorContainer?.editorView) {
      return;
    }

    const edView = editorContainer.editorView;
    const st = edView.state;
    const doc = st.doc;
    const tr = st.tr;
    const textSelection = new TextSelection(
      doc.resolve(actualMark.pmDocStartPos),
      doc.resolve(actualMark.pmDocStartPos)
    );
    edView.dispatch(tr.setSelection(textSelection));

    const articleElement = document.getElementsByClassName(
      'main-editor-container'
    )[0] as HTMLDivElement;
    if (articleElement && typeof actualMark.domTop === 'number') {
      articleElement.scroll({
        top: actualMark.domTop - 300,
        left: 0,
        behavior: 'smooth',
      });
    }
    edView.focus();
  }

  selectPreviousComment(): void {
    if (!this.isPreviousDisabled) {
      const com = this.commentsStateService.filteredComments[this.selectedCommentNumber - 2];
      if (com?.pmmark) {
        this.selectComment(com.pmmark);
      }
    }
  }

  selectNextComment(): void {
    if (!this.isNextDisabled) {
      const com = this.commentsStateService.filteredComments[this.selectedCommentNumber];
      if (com?.pmmark) {
        this.selectComment(com.pmmark);
      }
    }
  }

  endSearch(): void {
    this.commentsStateService.isSearching = false;
    this.commentsStateService.searchForm.setValue('');
    this.deselectAllComments();
  }

  deselectAllComments(): void {
    const commentElements = document.getElementsByClassName('comment-container');
    Array.from(commentElements).forEach((element) => {
      element.classList.remove('selected-comment');
    });
    this.store.dispatch(CommentsActions.deselectAllComments());
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
    this.initialized = false;
  }

  watchCommentsChanges(commentsSearchInput: ElementRef): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this.commentsStateService.subscription$.add(
      this.store
        .select(CommentsSelectors.selectFilteredComments)
        .pipe(debounce(() => interval(200)))
        .subscribe((filteredComments) => {
          const comments = this.getCommentElements();
          const editorContainer = this.getEditorContainer();

          if (editorContainer) {
            this.updateResolvedCommentsVisibility(editorContainer);
          }

          this.updateCommentsVisibility(comments, filteredComments);
          this.updateSelectedState(filteredComments, commentsSearchInput);

          this.finalizeUpdate();
        })
    );
  }

  private getCommentElements(): HTMLDivElement[] {
    return Array.from(document.getElementsByClassName('comment-container')) as HTMLDivElement[];
  }

  private getEditorContainer(): HTMLDivElement | null {
    return (document.getElementsByClassName('editor-container')[0] as HTMLDivElement) || null;
  }

  private updateResolvedCommentsVisibility(container: HTMLDivElement): void {
    const { showResolved } = this.commentsStateService;
    if (showResolved) {
      container.classList.add('show-resolved');
    } else {
      container.classList.remove('show-resolved');
    }
  }

  private updateCommentsVisibility(
    comments: HTMLDivElement[],
    filteredComments: { inydoc: YdocCommentThread; pmmark: Comment }[]
  ): void {
    comments.forEach((com) => {
      const commentData = filteredComments.find((c) =>
        com.classList.contains(c.pmmark.commentAttrs.id)
      );

      if (!commentData) {
        this.commentsRenderingService.hideComment(com);
        return;
      }

      const isResolved = com.getAttribute('resolved');

      if (
        !this.commentsRenderingService.shouldHideResolvedComment(isResolved, commentData.pmmark)
      ) {
        this.commentsRenderingService.showComment(com);
      } else {
        this.commentsRenderingService.hideComment(com);
      }
    });
  }

  private updateSelectedState(
    filteredComments: { inydoc: YdocCommentThread; pmmark: Comment }[],
    commentsSearchInput: ElementRef
  ): void {
    if (filteredComments.length === 0) {
      return;
    }

    const shouldSelectExisting = this.commentsStateService.lastSelectedComment?.commentId;
    const commentToSelect = shouldSelectExisting
      ? filteredComments.find(
          (c) =>
            c.pmmark.commentAttrs.id === this.commentsStateService.lastSelectedComment.commentId
        )
      : null;

    if (commentToSelect?.pmmark) {
      this.selectComment(commentToSelect.pmmark);
    }

    if (!this.commentsRenderingService.showAddCommentBox) {
      setTimeout(() => commentsSearchInput?.nativeElement?.focus(), 10);
    }
  }

  private finalizeUpdate(): void {
    this.commentsService.ydocCommentsChangeSubject.next(false);
    this.changeDetectorRef.detectChanges();
  }
}
