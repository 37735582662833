import { Injectable } from '@angular/core';
import LogRocket from 'logrocket';
import { User } from '@app/core/models/article.models';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService {
  constructor() {}

  identify(user: User): void {
    LogRocket.identify(user.id, {
      name: user.name || '',
      email: user.email || '',
      role: user.role || '',
    });
  }

  log(eventName: string, data: Record<string, unknown>): void {
    LogRocket.log(eventName, data);
  }

  captureException(error: Error): void {
    LogRocket.captureException(error);
  }
}
