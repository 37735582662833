import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BroadcasterService } from '../services/broadcaster.service';
import { CONSTANTS } from '../services/constants';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private broadcaster: BroadcasterService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.broadcaster.broadcast(CONSTANTS.SHOW_LOADER, true);

    return next.handle(request).pipe(
      finalize(() => {
        this.broadcaster.broadcast(CONSTANTS.SHOW_LOADER, false);
      })
    );
  }
}
