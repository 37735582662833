import { NgModule, APP_INITIALIZER, Optional, SkipSelf } from '@angular/core';
import { MetaReducer, Action, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import LogRocket from 'logrocket';
import LogRocketNgRx from 'logrocket-ngrx';
import { LOGROCKET_CONFIG } from './models/log-rocket.model';
import { LogRocketService } from './services/log-rocket.service';
import { AppState } from '@app/store';

export function initializeLogRocket() {
  return () => {
    LogRocket.init(LOGROCKET_CONFIG.projectId, LOGROCKET_CONFIG.options);
    console.log('LogRocket initialized successfully');
  };
}

export const logRocketMiddleware = LogRocketNgRx(LogRocket);

export const metaReducers: MetaReducer<AppState, Action>[] = [logRocketMiddleware];

export function getMetaReducers(): MetaReducer<AppState>[] {
  return metaReducers.concat([logRocketMiddleware]);
}

@NgModule({
  providers: [
    LogRocketService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLogRocket,
      multi: true,
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
  ],
})
export class SessionMonitoringModule {
  constructor(@Optional() @SkipSelf() parentModule: SessionMonitoringModule) {
    if (parentModule) {
      throw new Error(
        'SessionMonitoringModule is already loaded. Import it in the AppModule only.'
      );
    }
  }
}
