// HTTP Status Code Enums for better readability
export enum HttpClientErrorCodes {
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  methodNotAllowed = 405,
  conflict = 409,
  gone = 410,
  payloadTooLarge = 413,
  uriTooLong = 414,
  unsupportedMediaType = 415,
  unprocessableEntity = 422,
}

export enum HttpServerErrorCodes {
  internalServerError = 500,
  notImplemented = 501,
  badGateway = 502,
  serviceUnavailable = 503,
  gatewayTimeout = 504,
}

export enum HttpSpecialErrorCodes {
  requestTimeout = 408,
  tooManyRequests = 429,
}

export interface RetryConfig {
  /** Maximum number of retry attempts */
  maxRetries: number;
  /** Delay between retries in milliseconds */
  delayMs: number;
  /** Whether to use exponential backoff for delays */
  useExponentialBackoff: boolean;
  /** HTTP methods that are safe to retry */
  idempotentMethods: string[];
}

export const DEFAULT_RETRY_CONFIG: RetryConfig = {
  maxRetries: 2,
  delayMs: 1000,
  useExponentialBackoff: true,
  idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
};

export interface RetryStatusConfig extends RetryConfig {
  /** HTTP status codes this config applies to */
  statusCodes: number[];
}

export interface RetryEndpointConfig extends RetryConfig {
  /** URL pattern to match (string or RegExp) */
  pattern: string | RegExp;
  /** Status code specific overrides */
  statusOverrides?: RetryStatusConfig[];
}

export interface GlobalRetryConfig {
  /** Default configuration */
  default: RetryConfig;
  /** Status code specific configurations */
  statusConfigs?: RetryStatusConfig[];
  /** Endpoint specific configurations */
  endpointConfigs?: RetryEndpointConfig[];
}

export const DEFAULT_GLOBAL_RETRY_CONFIG: GlobalRetryConfig = {
  default: DEFAULT_RETRY_CONFIG,
  statusConfigs: [
    // Status codes that should be retried with special handling
    {
      statusCodes: [
        HttpSpecialErrorCodes.requestTimeout,
        HttpSpecialErrorCodes.tooManyRequests,
        HttpServerErrorCodes.badGateway,
        HttpServerErrorCodes.serviceUnavailable,
        HttpServerErrorCodes.gatewayTimeout,
      ],
      maxRetries: 3,
      delayMs: 1000,
      useExponentialBackoff: true,
      idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
    },
    // Status codes that should NOT be retried
    {
      statusCodes: [
        // 4xx Client Errors
        HttpClientErrorCodes.badRequest,
        HttpClientErrorCodes.unauthorized,
        HttpClientErrorCodes.forbidden,
        HttpClientErrorCodes.notFound,
        HttpClientErrorCodes.methodNotAllowed,
        HttpClientErrorCodes.conflict,
        HttpClientErrorCodes.gone,
        HttpClientErrorCodes.payloadTooLarge,
        HttpClientErrorCodes.uriTooLong,
        HttpClientErrorCodes.unsupportedMediaType,
        HttpClientErrorCodes.unprocessableEntity,
        // 5xx Server Errors that shouldn't be retried
        HttpServerErrorCodes.notImplemented,
      ],
      maxRetries: 0,
      delayMs: 1000,
      useExponentialBackoff: false,
      idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
    },
  ],
  endpointConfigs: [
    // Demo scenario for 500 errors
    {
      pattern: /\/articles\/items\/uuid\/error500/,
      maxRetries: 2,
      delayMs: 1500,
      useExponentialBackoff: false,
      idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
    },
    // Demo scenario for retry pattern with increasing backoff
    {
      pattern: /\/articles\/items\/uuid\/retry/,
      maxRetries: 3,
      delayMs: 1000,
      useExponentialBackoff: true,
      idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
    },
    // Demo scenario for timeout errors with special handling
    {
      pattern: /\/articles\/items\/uuid\/timeout/,
      maxRetries: 4,
      delayMs: 2000,
      useExponentialBackoff: true,
      idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
      statusOverrides: [
        {
          statusCodes: [HttpServerErrorCodes.gatewayTimeout],
          maxRetries: 5,
          delayMs: 1000,
          useExponentialBackoff: false,
          idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
        },
      ],
    },
    // Demo scenario for 404 errors (typically not retried)
    {
      pattern: /\/articles\/items\/uuid\/error404/,
      maxRetries: 0, // Don't retry 404s
      delayMs: 1000,
      useExponentialBackoff: false,
      idempotentMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE'],
    },
  ],
};
