import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { CommentsService } from '@app/editor/utils/commentsService/comments.service';
import { EditorView } from 'prosemirror-view';
import { CitableElementsService } from '@app/editor/services/citable-elements.service';
import { AddTableDialogComponent } from '../add-table-dialog/add-table-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import {
  citationElementMap,
  ElementsTypeToCitationMap,
  Table,
} from '@app/editor/services/citable-elements.models';

@Component({
  selector: 'app-insert-table',
  templateUrl: './insert-table.component.html',
  styleUrls: ['./insert-table.component.scss'],
})
export class InsertTableComponent implements AfterViewInit {
  error: boolean = false;
  tablesData?: string[];
  tables: { [key: string]: Table };
  selectedTables: boolean[] = [];
  selected = [];
  citats: any;

  constructor(
    private ydocService: YdocService,
    private dialogRef: MatDialogRef<InsertTableComponent>,
    private commentsPlugin: CommentsService,
    public dialog: MatDialog,
    private citableElementsService: CitableElementsService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private serviceShare: ServiceShare,
    @Inject(MAT_DIALOG_DATA) public data: { view: EditorView; citatData: any; sectionID: string }
  ) {
    this.prosemirrorEditorsService.editMode = true;
    this.tablesData = this.ydocService.tablesMap?.get('ArticleTablesNumbers');
    this.tables = this.ydocService.tablesMap?.get('ArticleTables');
    this.citats = this.ydocService.citableElementsMap?.get('elementsCitations');
    Object.keys(this.tables).forEach((tableID, i) => {
      this.selectedTables[i] = false;
    });
  }

  addTable() {
    this.dialog
      .open(AddTableDialogComponent, {
        width: '1000px',
        data: { fig: undefined, updateOnSave: false, index: this.tablesData?.length },
        disableClose: !this.citableElementsService.closeOnClickOutsideTables,
      })
      .afterClosed()
      .subscribe((result: { table: Table; tableNode: Node }) => {
        if (result && result.table) {
          const editMode = result.table.editMode;
          this.tablesData?.push(result.table.tableID);
          Object.keys(this.tables).forEach((key) => {
            this.citableElementsService.deletedTablesForRerender[key] = citationElementMap[
              ElementsTypeToCitationMap.table
            ].getElFormIOSubmission(this.tables[key], undefined, this.serviceShare);
          });
          this.tables![result.table.tableID] = result.table;
          this.selectedTables[this.tablesData?.length - 1] = true;
          this.selected.push(result.table.tableID);
          this.citableElementsService.writeElementDataGlobal(
            this.tables as { [key: string]: Table },
            this.tablesData!,
            ElementsTypeToCitationMap.table,
            editMode
          );
        } else {
          this.prosemirrorEditorsService.editMode = false;
        }
      });
  }

  getCharValue(i: number) {
    return String.fromCharCode(97 + i);
  }

  setSelection(checked: boolean, tableId: string, tableIndex: number) {
    if (checked) {
      this.selected.push(tableId);
    } else {
      this.selected = this.selected.filter((id) => id !== tableId);
    }
    this.selectedTables[tableIndex] = checked;
  }

  ngAfterViewInit(): void {
    try {
      if (this.data.citatData) {
        //@ts-ignore
        let sectionID;
        if (this.data.view) {
          sectionID =
            this.commentsPlugin.commentPluginKey.getState(this.data.view.state)?.sectionName ||
            undefined;
        } else if (this.data.sectionID) {
          sectionID = this.data.sectionID;
        }
        //let sectionID = pluginData.sectionName
        let citat = this.citats[sectionID][this.data.citatData.citateid];
        (citat.citedElementsIDs as string[]).forEach((table) => {
          let tblId = table;
          let index = this.tablesData?.indexOf(tblId);
          this.selectedTables[index!] = true;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  citateTables() {
    try {
      if (this.selectedTables.length == 0) {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 3000);
      } else {
        let sectionID: string;
        if (this.data.view) {
          sectionID =
            this.commentsPlugin.commentPluginKey.getState(this.data.view.state)?.sectionName ||
            undefined;
        } else if (this.data.sectionID) {
          sectionID = this.data.sectionID;
        }
        this.citableElementsService.citateTables(
          this.selectedTables,
          sectionID,
          this.data.citatData,
          this.data.view
        );
        this.dialogRef.close();
      }
    } catch (e) {
      console.error(e);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
