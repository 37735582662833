import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { FakeRequestHandler } from './models';
import { ReferencesHandler } from './handlers/references.handler';
import { ReferenceTypesHandler } from './handlers/reference-types.handler';
import { ReferenceStylesHandler } from './handlers/reference-styles.handler';
import { CustomSectionsHandler } from './handlers/custom-sections.handler';
import { AuthHandler } from './handlers/auth.handler';
import { ArticleItemsHandler } from './handlers/article-items.handler';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  private handlers: FakeRequestHandler[];

  constructor(private authService: AuthService) {
    this.handlers = [
      // new ReferencesHandler(),
      // new ReferenceTypesHandler(),
      // new ReferenceStylesHandler(),
      // new CustomSectionsHandler(),
      new ArticleItemsHandler(),
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    for (const handler of this.handlers) {
      if (handler.canHandle(req, token)) {
        return handler.handle(req);
      }
    }

    return next.handle(req);
  }
}
