import * as Y from 'yjs';

export enum VersionChange {
  returnToNewest = 'returnToNewest',
  versionChange = 'versionChange',
  reRender = 'rerender',
  reconnect = 'reconnect',
}

export interface Snapshot {
  stateVector: Uint8Array;
  encodedState: Uint8Array[];
}

export interface Version {
  date: Date;
  snapshot: Snapshot;
  index: number;
  users: VersionUserInfo[];
  restored?: Date;
  currentVersion?: boolean;
}

export interface VersionData {
  snapshot: Y.Snapshot;
  prevSnapshot: Y.Snapshot;
  userData: VersionUserInfo;
}

export interface VersionUserInfo {
  name: string;
  email: string;
  id: string;
  userColor: string;
}
