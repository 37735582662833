import { ArticleModeService } from '@app/editor/state-info/article-mode-service/article-mode-service';
import { Injectable } from '@angular/core';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { Colors, UserDataInComment, YdocComment, YdocCommentThread } from '../comment.models';
import { commentConfig } from './comment.config';
import { Map } from 'yjs';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ArticleUserMode } from '@app/editor/state-info/article-role/article-role.models';
import { CommentsActions } from '@app/store/comments';
import { Store } from '@ngrx/store';
import { HandleConstructorErrors } from '@core/error-handling/decorators/constructor-error-handler.decorator';

@HandleConstructorErrors()
@Injectable()
export class CommentService {
  private readonly commentRoles = ['author_commenter', 'reviewer'];

  private commentsMap: Map<YdocCommentThread>;

  private _mobileVersion: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private sharedService: ServiceShare,
    private ydocService: YdocService,
    private prosemirrorEditorService: ProsemirrorEditorsService,
    private articleModeService: ArticleModeService,
    private store: Store
  ) {
    this._mobileVersion = this.prosemirrorEditorService.mobileVersion;

    if (this.ydocService.editorIsBuild) {
      this.commentsMap = this.ydocService.getCommentsMap();
    }
    this.ydocService.ydocStateObservable.subscribe(({ event }) => {
      if (event == 'docIsBuild') {
        this.commentsMap = this.ydocService.getCommentsMap();
      }
    });

    this.prosemirrorEditorService.mobileVersionSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this._mobileVersion = data;
      });
  }

  get mobileVersion(): boolean {
    return this._mobileVersion;
  }

  get previewMode(): boolean {
    return this.prosemirrorEditorService.previewArticleMode.mode;
  }

  get isNewVersion(): boolean {
    return !this.sharedService.isArticleVersionPreview;
  }

  get hasCommentRole(): boolean {
    return this.articleModeService.mode !== ArticleUserMode.readOnly;
  }

  get isNewPreviewMode(): boolean {
    return this.isNewVersion && this.previewMode;
  }

  get canActOnThread(): boolean {
    return !this.mobileVersion && (!this.isNewPreviewMode || this.hasCommentRole);
  }

  get canUserInteractInPreviewMode(): boolean {
    return this.hasCommentRole;
  }

  get canResolveComments(): boolean {
    return this.canUserInteractInPreviewMode && this.hasCommentRole;
  }

  get canShowHeaderActions$(): Observable<boolean> {
    return this.articleModeService.mode$.pipe(
      map(
        (mode) =>
          !this.sharedService.isArticleVersionPreview &&
          this.ydocService.curUserAccess &&
          mode !== ArticleUserMode.readOnly &&
          this.sharedService.canAddComments
      )
    );
  }

  isResolveRecord(record: YdocComment): boolean {
    return record.type === 'action';
  }

  getCommentThread(id: string): YdocCommentThread {
    return this.commentsMap?.get(id);
  }

  deleteCommentThread(id: string): void {
    this.commentsMap?.delete(id);
    this.sharedService.CommentsService.selectedThreadComment = undefined;
    this.sharedService.CommentsService.updateAllComments();
    this.store.dispatch(CommentsActions.deselectAllComments());
  }

  setCommentThread(id: string, data: YdocCommentThread): void {
    this.commentsMap?.set(id, data);
  }

  getUserColor(colorKey: keyof Colors, userData: UserDataInComment): string {
    const { colors } = commentConfig;
    const userColorKey = colorKey === 'background' ? 'userColor' : 'userContrastColor';
    return this.ydocService.currUser?.id === userData.id
      ? userData[userColorKey]
      : colors[colorKey];
  }

  getResolveButtonText(isResolved: boolean): string {
    const { resolveButtonText } = commentConfig;
    return isResolved ? resolveButtonText['unresolved'] : resolveButtonText['resolved'];
  }

  destroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
