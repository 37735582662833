import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NetworkError } from '../custom-errors/network-error';
import { GlobalErrorHandler } from '../global-error-handler/global-error-handler';
import { ErrorStorageService } from '../services/error-storage.service';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(private readonly errorHandler: GlobalErrorHandler) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!navigator.onLine) {
      const networkError = new NetworkError(request);
      ErrorStorageService.storeError(networkError);
      this.errorHandler.handleNetworkError(networkError);
      return throwError(() => networkError);
    }

    return next.handle(request);
  }
}
