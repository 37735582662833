import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArphaButtonComponent } from '@app/layout/widgets/arpha-button/arpha-button.component';
import { RenderHtmlPipe } from '@app/shared/pipes/render-html/render-html.pipe';

@NgModule({
  declarations: [ArphaButtonComponent, RenderHtmlPipe],
  imports: [CommonModule],
  exports: [ArphaButtonComponent, RenderHtmlPipe],
})
export class SharedModule {}
