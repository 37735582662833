import { Injector } from '@angular/core';
import { GlobalErrorHandler } from '../global-error-handler/global-error-handler';
import { ErrorStorageService } from '../services/error-storage.service';
import { ErrorFactoryService } from '../error-factory/error-factory.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function HandleConstructorErrors() {
  return function <T extends { new (...args: any[]): any }>(constructor: T) {
    const originalConstructor = constructor;

    const newConstructor: any = function (...args: any[]) {
      try {
        return new originalConstructor(...args);
      } catch (error) {
        const injector = (window as unknown as { awtInjector: Injector }).awtInjector;

        if (injector) {
          try {
            const errorHandler = injector.get(GlobalErrorHandler);
            errorHandler.handleError(error);
          } catch (e) {
            console.error('Failed to get GlobalErrorHandler:', e);
            handleFallback(error, constructor.name);
          }
        } else {
          handleFallback(error, constructor.name);
        }

        throw error;
      }
    };

    Object.setPrototypeOf(newConstructor, originalConstructor);
    newConstructor.prototype = originalConstructor.prototype;
    return newConstructor;
  };
}

function handleFallback(error: Error, serviceName: string): void {
  try {
    const errorFactory = new ErrorFactoryService();

    if (errorFactory) {
      const errorToStore = errorFactory.createConstructorError(error, serviceName);
      ErrorStorageService.storeError(errorToStore);
    } else {
      console.error(
        'ErrorStorageService or ErrorFactoryService not available, cannot store error.'
      );
    }
  } catch (storageError) {
    console.error('Failed to store constructor error:', storageError);
  }
}
